import React, { useRef, useState } from 'react'
import * as _ from 'lodash'
import * as yup from 'yup'
import { View, Text } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'
import { RouteName } from '../../navigation/types'
import { Title } from 'react-native-paper'
import { forgotPwdStyles as styles } from './styles'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import recaptchaCreds from '../../utils/recaptcha'
import ReCAPTCHA from 'react-google-recaptcha'
import { CLEAR_VERIFY_EMAIL, verifyEmail } from '../../reducers/ForgotPassword/verifyEmail'
import { CLEAR_FORGOT_PWD_OTP_REQ, forgotPwdOtpReq } from '../../reducers/ForgotPassword/otpRequest'
import AlertMessage from './component/AlertMessage'
import { responseCode, responseMsg } from '../../utils/response'
import ArtForm from '../../ReusableComponents/Form/ArtForm'
import ArtField, { ArtFieldsType } from '../../ReusableComponents/Form/ArtFields'
import { MyButton } from '../../ReusableComponents/ui-components'

const emailFormat =
    '^[a-zA-Z0-9]+([a-zA-Z0-9_.]+)*@[a-zA-Z0-9]+([a-zA-Z0-9_.\\$&-]+)*\\.([a-zA-Z0-9]{2,4})$'

const FormSchema = yup.object({
    email: yup
        .string()
        .required('Email is required')
        .test('email', 'Invalid email format', (value: any) => !!value.match(emailFormat))
        .min(16, 'Email must be between 16 and 40 characters long')
        .max(40, 'Email must be between 16 and 40 characters long'),
    verifyEmail: yup
        .string()
        .when('email', (_email, schema) => {
            return schema.test({
                test: () => Boolean(_email),
                message: 'Email field must have a value to verify'
            })
        })
        .oneOf([yup.ref('email'), null], "Email don't match")
        .required('Confirm Email is required'),
    recaptcha: yup.string().required('Recaptcha is required')
})

export interface ComponentProps {
    navigation: StackNavigationProp<any>
    isRefreshing: boolean
    intl: any
}

export interface StateProps {
    verifyEmailResp: any
    isLoadingOTPReq: boolean
    otpReqStatus: string
}

export interface DispatchProps {
    verifyEmail: (email: string) => any
    forgotPwdOtpReq: (data: any) => any
    onClearVerifyEmail: () => any
    onClearForgotPwdOtpReq: () => any
}

export interface VerifyEmailPageState {
    isSubmit: boolean
    isLoading: boolean
    isVerified: boolean
    isUserFound: boolean
    isVerifyError: boolean
    isLockedAcc: boolean
    isAlertMessage: boolean
    errMsg: string
    resetData: any
    email: string
    reCaptchatchaResponse: any
}

export type VerifyEmailPageProps = ComponentProps & StateProps & DispatchProps


const VerifyEmailPage = (props: VerifyEmailPageProps) => {
    const _onSubmit = (data: any) => props.verifyEmail(data.email)
    const { Form, onSubmit, control } = ArtForm({ formSchema: FormSchema, onSubmit: _onSubmit, otherOptions: { mode: 'onSubmit', criteriaMode: 'all' } })
    const [isVerified, setIsVerified] = useState(false)
    const [reCaptchatchaResponse, setReCaptchatchaResponse] = useState(undefined)
    const onChangeRecaptcha = (result: any, onChange?: (value: any) => any) => {
        setIsVerified(true)
        setReCaptchatchaResponse(result)

        if (onChange) {
            onChange(result)
        }
    }
    const recaptchaRef: any = useRef(null)
    return (
        <div id='VerifyEmailComponent' className='verifyEmail'>
            <img src='Logo/artshare-big-logo-v2.png' className='verifyEmail__column verifyEmail__img' />
            <div className='verifyEmail__column mx-5 mt-2'>
                <div className='d-flex flex-row gap-3'>
                    <div className='verifyEmail__column--line align-self-center' />
                    <img src='Logo/artshare-logo.png' />
                </div>
                <Form onSubmit={onSubmit} id='VerifyEmailForm' className='verifyEmail__form' formSchema={FormSchema}>
                    <ArtField showError className='verifyEmail__form--input mt-3' control={control} fieldstype={ArtFieldsType.ArtInput} id='email' name='email' placeholder={'Email'} />
                    <ArtField showError className='verifyEmail__form--input mt-3' control={control} fieldstype={ArtFieldsType.ArtInput} id='verifyEmail' name='verifyEmail' placeholder={'Confirm Email'} />
                    <ArtField fieldstype={ArtFieldsType.CustomField} name='recaptcha' id='recaptcha' control={control} renderField={(onChange: (...event: any[]) => any, value: any, error: string) => {
                        return (
                            <div className='d-flex flex-column mt-4'>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={recaptchaCreds.siteKey}
                                    onChange={(val) =>
                                        onChangeRecaptcha(val, onChange)
                                    }
                                    onExpired={() => {
                                        recaptchaRef.current?.reset?.()
                                    }}
                                />
                                {!!error && <span className='verifyEmail__form--errorMessage text-danger'>{error}</span>}
                            </div>
                        )
                    }} />
                    <MyButton isLoading={false} className='verifyEmail__form--button mt-4' buttonType='primary' onClick={onSubmit}>Send Verification Code</MyButton>
                </Form>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    const _otpReq = state.api?.forgotPwd?.forgotPwdOtpReq
    return {
        verifyEmailResp: state.api?.forgotPwd?.verifyEmail?.response,
        isLoadingOTPReq: state.api?.forgotPwd?.forgotPwdOtpReq?.loading,
        otpReqStatus: _otpReq?.statusText
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    verifyEmail: (email: string) => verifyEmail(dispatch, { type: 'email', email }),
    forgotPwdOtpReq: (data: any) => forgotPwdOtpReq(dispatch, data),
    onClearVerifyEmail: () => dispatch({ type: CLEAR_VERIFY_EMAIL }),
    onClearForgotPwdOtpReq: () => dispatch({ type: CLEAR_FORGOT_PWD_OTP_REQ })
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(VerifyEmailPage)) as any
