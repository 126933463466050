import Screen from './Screen'
import Desktop, { isDesktop } from "./Desktop"
import Tablet, { isTablet } from './Tablet'
import Mobile, { isMobile } from './Mobile'

const CpebScreen = Object.assign(
    Screen,
    {
        Desktop,
        isDesktop,
        Tablet,
        isTablet,
        Mobile,
        isMobile
    }
)

export default CpebScreen