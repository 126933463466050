import React from 'react'
import { StyleProp, Text, TextStyle, ViewStyle } from 'react-native'
import { Button } from 'react-native-paper'
import { IconSource } from 'react-native-paper/lib/typescript/components/Icon'

export interface ButtonProps {
  mode?: 'text' | 'outlined' | 'contained'
  dark?: boolean
  compact?: boolean
  color?: string
  loading?: boolean
  icon?: IconSource
  disabled?: boolean
  uppercase?: boolean
  accessibilityLabel?: string
  onPress?: (data?: any) => void
  onLongPress?: () => void
  contentStyle?: StyleProp<ViewStyle>
  style?: StyleProp<ViewStyle>
  labelStyle?: StyleProp<TextStyle>
  testID?: string
  title: string
  optionalFunc?: any
}


const Submit = (props: ButtonProps) => <Button {...props} ><Text>{props.title}</Text></Button>

export default Submit