import { StyleSheet } from 'react-native'


export const typographyStyle = StyleSheet.create({
    title: {
        fontFamily: 'Roboto',
        fontSize: 20
    },
    text: {
        fontFamily: 'Roboto',
        fontSize: 16
    },
    bold: {
        fontWeight: '700'
    },
    mid: {
        fontWeight: '600'
    },
    normal: {
        fontWeight: '400'
    }
})

export default typographyStyle
