import React from 'react'
import amplify, { Hub } from '@aws-amplify/core'
import config from '../config'
import Auth from '@aws-amplify/auth'
import { CLEAR_USER, errorFederatedLogin, fbLoginUser, googleLoginUser, loginUser, retrieveUser, setRefreshToken, setToken } from '../reducers/User/user'
import { Route, Routes } from 'react-router-dom'
import ValidateRoutes from './ValidateRoutes'
import Login from '../components/Login/Login.web'
import Register from '../components/SignUp/Register.web'
import { ReduxAppState } from '../utils/types'
import { CLEAR_MODAL } from '../reducers/Modal/showModal'
import { clearUserRegister } from '../reducers/User/register'
import { verifyEmail } from '../reducers/ForgotPassword/verifyEmail'
import { CLEAR_FORGOT_PWD_OTP_REQ, forgotPwdOtpReq } from '../reducers/ForgotPassword/otpRequest'
import { Dispatch, compose } from 'redux'
import { connect } from 'react-redux'
import VerifyEmail from '../components/ForgotPassword/VerifyEmail'
import Dashboard from '../components/Dashboard/Dashboard'
import List from '../components/Listing/List'
import Wallet from '../components/Wallet/Wallet'
import Settings from '../components/Settings/Settings'
import ItemDetails from '../components/ItemDetails/ItemDetails'
import AddListing from '../components/AddListing/AddListing'
import { MyButton, MyModal } from '../ReusableComponents/ui-components'
import Welcome from '../components/Welcome/Welcome'
export interface RoutesProps {
}

export interface StateProps {
    isSignedIn: boolean
    isGoogleLogin: boolean
    isFbLogin: boolean
    isFederatedSignIn: boolean
    isFederatedError: boolean
    isGlobalLoading: boolean
    isGlobalError: boolean
    isOpenLoginMessage: boolean
}

export interface DispatchProps {
    getUserInfo: () => any
    logOutUser: () => any
    errorFederatedLogin: (message: string) => any
    onClearUser: () => any
    onHideGlobalError: () => any
    setGuestUser: (value: string) => any
    setGlobalLoading: (isOpen: boolean) => void
    onGetAllSystemParams: () => any
    setGlobalLoginMessage: (value: boolean) => void
}

export interface AppRoutesState {
}

export type AppRoutesProps = RoutesProps & StateProps & DispatchProps

class AppRoutes extends React.Component<AppRoutesProps, AppRoutesState> {
    constructor(props: AppRoutesProps) {
        super(props)

        amplify.configure({
            Auth: {
                mandatorySignIn: true,
                region: config.cognito.REGION,
                userPoolId: config.cognito.USER_POOL_ID,
                identityPoolId: config.cognito.IDENTITY_POOL_ID,
                userPoolWebClientId: config.cognito.APP_CLIENT_ID,
                oauth: {
                    domain: config.oauth.DOMAIN,
                    scope: config.oauth.SCOPE,
                    redirectSignIn: config.oauth.REDIRECT_SIGNIN,
                    redirectSignOut: config.oauth.REDIRECT_SIGNOUT,
                    responseType: config.oauth.RESPONSE_TYPE
                }
            }
        })

        Hub.listen('auth', async ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                    await Auth.currentAuthenticatedUser().then((res) => {
                        const userSession = res.signInUserSession
                        setToken(userSession.idToken.jwtToken)
                        setRefreshToken(userSession.refreshToken.token)
                        this.props.setGlobalLoading(true)
                        this.props.getUserInfo()
                            .finally(() => this.props.setGlobalLoading(false))
                        if (this.props.isFederatedSignIn) {
                            this.props.setGuestUser('')
                            // Socket.init(userSession.idToken.payload.sub)
                        }
                    })
                    break
                case 'oAuthSignOut':
                    this.props.logOutUser()
                    // Socket.closeConnection()
                    break
                case 'customOAuthState':
                    break
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                    this.props.errorFederatedLogin(data)
                    break
            }
        })
    }

    componentDidMount(): void {
        this.props.setGlobalLoginMessage(false)
    }

    renderHeader = () => <h1 className='appRoutes__loginMessage--header'>Log into Artshare</h1>

    renderFooter = () => (
        <div>
            <MyButton onClick={() => window?.location?.replace('/login')}>Login</MyButton>
        </div>
    )

    render() {
        const { isSignedIn } = this.props
        return <div id='AppRoutes' className='appRoutes'>
            <MyModal className='appRoutes__loginMessage' footerClassName='appRoutes__loginMessage--buttonContainer' headerClassName='appRoutes__loginMessage--headerContainer' isVisible={this.props.isOpenLoginMessage} headerContent={this.renderHeader()} footerContent={this.renderFooter()}>
                <h1 className='appRoutes__loginMessage--body'>Log in to navigate other feature of ArtShare</h1>
            </MyModal>
            <Routes>
                <Route element={<ValidateRoutes isPublic={true} isAuth={isSignedIn} />}>
                    <Route path={`/`} element={<Welcome />} />
                    <Route path={`/login`} element={<Login />} />
                    <Route path={`/signup`} element={<Register />} />
                    <Route path={`/verify`} element={<VerifyEmail />} />
                </Route>
                <Route element={<ValidateRoutes isPublic={false} isAuth={isSignedIn} />}>
                    <Route path={`/listing/add`} element={<AddListing />} />
                    <Route path={`/portfolio`} element={<Dashboard />} />
                    <Route path={`/wallet`} element={<Wallet />} />
                    <Route path={`/settings`} element={<Settings />} />
                </Route>
                <Route path={`/listing`} element={<List />} />
                <Route path={`/listing/:itemId`} element={<ItemDetails />} />
            </Routes>
        </div>
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    return {
        user: state.api?.user?.authUser,
        errorMsg: state.api?.user?.authUser?.error,
        isSignedIn: state.api?.user?.authUser?.isSignedIn,
        isRegisterSuccess: state.api?.user?.registerUser?.statusText === 'success',
        isLoading: state.api?.user.authUser.loading,
        isError: state.api?.user.authUser.statusText === 'error',
        isFederatedError: state.api?.user.authUser.statusText === 'relogin',
        otpStatus: state.api?.forgotPwd?.forgotPwdOtpReq?.statusText,
        isOtpModalOpen: state?.custom?.otpModal,
        isOpenLoginMessage: state?.custom?.isOpenLoginMessage,
        systemFields: state.api?.systemParam?.getAllSysParams?.response ?? []
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loginUser: (username: string, password: string) => loginUser(dispatch, { username, password }),
    fbLoginUser: () => fbLoginUser(dispatch),
    googleLoginUser: () => googleLoginUser(dispatch),
    onClearUser: () => dispatch({ type: CLEAR_USER }),
    onClearModal: () => dispatch({ type: CLEAR_MODAL }),
    onClearRegister: () => dispatch(clearUserRegister()),
    onVerifyEmail: (data: any) => verifyEmail(dispatch, data),
    requestOTP: (data: any) => forgotPwdOtpReq(dispatch, data),
    onClearOTP: () => dispatch({ type: CLEAR_FORGOT_PWD_OTP_REQ }),
    onOpenOTPModal: (value: boolean) => dispatch({ type: 'custom.otpModal', value }),
    setGlobalLoading: (value: boolean) => dispatch({ type: 'custom.isGlobalLoading', value }),
    setGlobalLoginMessage: (value: boolean) => dispatch({ type: 'custom.isOpenLoginMessage', value }),
    errorFederatedLogin: (message: string) => dispatch(errorFederatedLogin(message)),
    getUserInfo: () => retrieveUser(dispatch),
})


export default connect(mapStateToProps, mapDispatchToProps)(compose(AppRoutes as any)) as any