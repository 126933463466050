import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Platform } from 'react-native'
import { Dispatch } from 'redux'
import sessionStorage from 'redux-persist/es/storage/session'
import { User } from '.'
import awsInstance, { methods } from '../../core/axios.config'
import Socket from '../../websocket'

export const BEGIN_USER_LOGIN = 'API/LOGIN_USER/BEGIN_USER_LOGIN'
export const BEGIN_FEDERATED_USER_LOGIN = 'API/LOGIN_USER/BEGIN_FEDERATED_USER_LOGIN'
export const BEGIN_FB_LOGIN = 'API/LOGIN_USER/BEGIN_FB_LOGIN'
export const BEGIN_GOOGLE_LOGIN = 'API/LOGIN_USER/BEGIN_GOOGLE_LOGIN'
export const SUCCESS_USER_LOGIN = 'API/LOGIN_USER/SUCCESS_USER_LOGIN'
export const ERROR_USER_LOGIN = 'API/LOGIN_USER/ERROR_USER_LOGIN'
export const SUCCESS_RETRIEVE_USER = 'API/LOGIN_USER/SUCCESS_RETRIEVE_USER'
export const USER_LOGOUT = 'API/LOGIN_USER/USER_LOGOUT'
export const ERROR_USER_LOGOUT = 'API/LOGIN_USER/ERROR_USER_LOGOUT'
export const ERROR_FEDERATED_LOGIN = 'API/LOGIN_USER/ERROR_FEDERATED_LOGIN'
export const CLEAR_USER = 'API/LOGIN_USER/CLEAR_USER'

export interface AuthUserInfoState {
    response?: User
    status?: number,
    statusText?: string,
    loading: boolean,
    error?: any,
    isSignedIn?: boolean,
    isFederatedSignIn?: boolean
    isFbLogin?: boolean
    isGoogleLogin?: boolean
}

export interface AuthUserActions {
    type: string
    [item: string]: any
}

export const authUserInitialState = {
    response: undefined,
    loading: false,
    isFederatedSignIn: false,
    isFbLogin: false,
    isGoogleLogin: false,
    statusText: ''
}

export const reducer = (newState: AuthUserInfoState = authUserInitialState, action: AuthUserActions) => {
    switch (action.type) {
        case BEGIN_USER_LOGIN:
            return Object.assign({}, newState, {
                loading: true,
                isSignedIn: false
            })

        case BEGIN_FEDERATED_USER_LOGIN:
            return Object.assign({}, newState, {
                loading: true,
                isSignedIn: false,
                isFederatedSignIn: true
            })

        case BEGIN_FB_LOGIN:
            return Object.assign({}, newState, {
                isGoogleLogin: false,
                isFbLogin: true
            })

        case BEGIN_GOOGLE_LOGIN:
            return Object.assign({}, newState, {
                isGoogleLogin: true,
                isFbLogin: false
            })

        case SUCCESS_USER_LOGIN:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: 'success',
                status: action.status,
                isSignedIn: true,
                error: ''
            })

        case ERROR_USER_LOGIN:
            return Object.assign({}, newState, {
                statusText: 'error',
                error: action.message,
                loading: false,
                isSignedIn: false,
                isFederatedSignIn: false
            })

        case ERROR_FEDERATED_LOGIN:
            return Object.assign({}, newState, {
                statusText: 'relogin',
                error: action.message,
                loading: false,
                isSignedIn: false,
                isFederatedSignIn: false,
                isFbLogin: false,
                isGoogleLogin: false
            })

        case SUCCESS_RETRIEVE_USER:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: 'success',
                status: action.status,
                isSignedIn: true,
                error: ''
            })
        case CLEAR_USER:
            return authUserInitialState
        case USER_LOGOUT:
            return Object.assign({}, newState, {
                loading: false,
                isSignedIn: false,
                response: {}
            })

        case ERROR_USER_LOGOUT:
            return Object.assign({}, newState, {
                statusText: 'error',
                error: action.message,
                loading: false,
                isSignedIn: false
            })
        default:
            return newState
    }
}

export const beginUserLogin = () => ({
    type: BEGIN_USER_LOGIN,
})

export const beginFederatedUserLogin = () => ({
    type: BEGIN_FEDERATED_USER_LOGIN,
})

export const beginFbLogin = () => ({
    type: BEGIN_FB_LOGIN,
})

export const beginGoogleLogin = () => ({
    type: BEGIN_GOOGLE_LOGIN,
})

export const successUserLogin = (response: any) => ({
    type: SUCCESS_USER_LOGIN,
    data: response,
    status: response.status
})

export const errorUserLogin = (error: any) => ({
    type: ERROR_USER_LOGIN,
    message: error.message
})

export const errorFederatedLogin = (error: any) => ({
    type: ERROR_FEDERATED_LOGIN,
    message: error.message
})

export const errorUserLogout = (error: any) => ({
    type: ERROR_USER_LOGOUT,
    message: error.message
})

export const setToken = async (token: string) => {
    Platform.OS === 'web' ? await sessionStorage.setItem('token', token) : await AsyncStorage.setItem('token', token)
}

export const setRefreshToken = async (refreshToken: string) => {
    Platform.OS === 'web' ? await sessionStorage.setItem('refresh_token', refreshToken) : await AsyncStorage.setItem('refresh_token', refreshToken)
}

export const setGuestToken = async (guestToken: string) => {
    Platform.OS === 'web' ? await sessionStorage.setItem('guest_token', guestToken) : await AsyncStorage.setItem('guest_token', guestToken)
}

export const removeTokens = async () => {
    const keysToRemove = ['token', 'refresh_token', 'guest_token', 'persist:root']
    keysToRemove.forEach(key => sessionStorage.removeItem(key))
    await AsyncStorage.getAllKeys().then(keys => AsyncStorage.multiRemove(keys));
}

export const removeGuestToken = async () => {
    const keysToRemove = ['guest_token']
    keysToRemove.forEach(key => sessionStorage.removeItem(key))
    await AsyncStorage.getAllKeys().then(keys => AsyncStorage.multiRemove(keys));
}

export const successRetrieveUser = (response: any) => ({
    type: SUCCESS_RETRIEVE_USER,
    data: response
})

export const userLogout = () => ({
    type: USER_LOGOUT
})

export const retrieveUser = async (dispatch: Dispatch) => {
    await Auth.currentAuthenticatedUser({ bypassCache: true })
        .then(async user => {
            const config: AxiosRequestConfig = {
                method: methods.GET,
                url: `/user/${user.attributes.sub}`
            }
            try {
                const response = await awsInstance(config)
                dispatch(successRetrieveUser({
                    id: user.username,
                    ...user.attributes,
                    ...response.data.data,
                    status: 200
                }))
                return response
            } catch (error) {
                dispatch(errorUserLogin(error))
                return await new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
            }
        })
        .catch(err => {
            dispatch(errorUserLogin(err))
            return new Promise<any>((resolve, reject) => { reject(err) })
        })
}

export const logOutUser = async (dispatch: Dispatch) => {
    await Auth.signOut()
        .then(response => {
            dispatch(userLogout())
            removeTokens()
            return response
        }).catch(err => {
            dispatch(errorUserLogout(err))
            return new Promise<any>((resolve, reject) => { reject(err) })
        })
}

export const loginUser = (dispatch: Dispatch, data: any) => {
    dispatch(beginUserLogin())
    return Auth.signIn({
        username: data.username.toLowerCase(),
        password: data.password
    }).then((response) => {
        const userSession = response.signInUserSession
        Socket.init(userSession.idToken.payload.sub)
        setToken(userSession.idToken.jwtToken)
        setRefreshToken(userSession.refreshToken.token)
        retrieveUser(dispatch)
        return response
    }).catch(err => {
        dispatch(errorUserLogin(err))
        return new Promise<any>((resolve, reject) => { reject(err) })
    })
}

export const fbLoginUser = async (dispatch: Dispatch) => {
    try {
        dispatch(beginFederatedUserLogin())
        dispatch(beginFbLogin())
        await Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Facebook,
            customState: new Date().getTime().toString()
        })
    } catch (err) {
        dispatch(errorFederatedLogin(err))
        return new Promise<any>((resolve, reject) => { reject(err) })
    }
}

export const googleLoginUser = async (dispatch: Dispatch) => {
    try {
        dispatch(beginFederatedUserLogin())
        dispatch(beginGoogleLogin())
        await Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Google,
            customState: new Date().getTime().toString()
        })
    } catch (err) {
        dispatch(errorFederatedLogin(err))
        return new Promise<any>((resolve, reject) => { reject(err) })
    }
}

export default reducer
