import classNames from 'classnames'
import React, { ReactElement, ReactNode } from 'react'
import { Accordion, AccordionProps } from 'react-bootstrap'

export interface OwnProps extends AccordionProps {
    header: string | ReactElement
    containerClassName?: string
    bodyClassName?: string
    headerClassName?: string
    children: ReactElement | string
}

export class MyAccordion extends React.Component<OwnProps, {}> {

    render() {
        return (
            <Accordion id='MyAccordionComponent' className={classNames('myAccordion', this.props.containerClassName)}>
                <Accordion.Item eventKey='0'>
                    <Accordion.Header className={classNames('myAccordion__header', this.props.headerClassName)}>{this.props.header}</Accordion.Header>
                    <Accordion.Body className={classNames('myAccordion__body', this.props.bodyClassName)}>{this.props.children}</Accordion.Body>
                </Accordion.Item>
            </Accordion>
        )
    }
}