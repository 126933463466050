import { StyleSheet } from 'react-native'
import { Colors } from 'react-native-paper'
import cpebColors from '../../../utils/colors'

export default StyleSheet.create({
    dataTable: {
        marginTop: .5,
        flexDirection: 'row'
    },
    tableField: {
        backgroundColor: cpebColors.labelColor,
        flex: 0.5,
        maxWidth: '100%',
        marginRight: .5
    },
    fieldText: {
        color: 'white',
        fontWeight: 'bold',
        marginVertical: 20,
        marginLeft: 10
    },
    tableValue: {
        backgroundColor: '#DBDBDB',
        alignItems: 'flex-start',
        flex: 0.5
    },
    valueText: {
        color: 'black',
        marginLeft: 10,
        marginVertical: 20,
        textAlignVertical: 'center'
    },
    table: {
        marginHorizontal: 20
    },
    header: {
        marginTop: 20,
        flexDirection: 'row',
        width: '100%'
    },
    headerCell: {
        padding: 8,
        flex: 1,
        borderColor: 'black'
    },
    rowCellContainer: {
        flexDirection: 'row',
    },
    rowCell: {
        height: 50,
        flex: 1,
        borderBottomWidth: 1,
        borderBottomColor: '#A0A09E',
        backgroundColor: '#F6F6F6',
    },
    border: {
        borderRightWidth: 1,
        borderColor: '#898989',
    },
    tableHeader: {
        backgroundColor: cpebColors.labelColor,
        flex: 1,
        overflow: 'scroll',
        maxWidth: '100%',
        marginTop: .5
    },
    valueCellText: {
        textAlign: 'center',
        marginTop: 10,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 12
    },
    tdText: {
        color: Colors.black,
        textAlign: 'center',
        marginTop: 10,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 12
    }
})