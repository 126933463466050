import React from 'react'
import classNames from 'classnames'
import { MyLabel } from '../'

export interface MyInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    label?: string | JSX.Element
    containerClassName?: string | undefined
    control?: any
    isError?: boolean
    errorMessage?: string
    showError?: boolean
    inputDesign?: 'normal' | 'transparent'
    isSearch?: boolean
    onSearch?: (...params: any[] ) => any
    isRequired?: boolean
}

export interface MyInputState {
    isEyeIconOpen: boolean | undefined
}

class MyInput extends React.Component<MyInputProps, MyInputState> {
    constructor(props: MyInputProps) {
        super(props)
        this.state = {
            isEyeIconOpen: false
        }
    }

    onChangeEyeIcon = (value: boolean) => this.setState({ isEyeIconOpen: value })

    render() {
        const isPasswordField = this.props?.type && this.props.type === 'password'
        return (
            <div id='MyInputComponent' className={classNames('myInput', { ['search']: this.props?.inputDesign === 'transparent' }, this.props.containerClassName)}>
                <div className={classNames('myInput__container', { ['myInput__container--required']: this.props.isRequired })}>
                    {this.props?.label ? React.isValidElement(this.props.label) ? this.props.label : <MyLabel text={this.props.label} className='myInput__label' /> : null}
                    {/* {this.props.isRequired && <span className='myInput__required'>*</span>} */}
                    <input alt='wew' {...this.props} type={isPasswordField ? this.state.isEyeIconOpen ? 'text' : 'password' : this.props.type} defaultValue={this.props.defaultValue} className={classNames('myInput__field', { ['myInput__field--transparent']: this.props?.inputDesign === 'transparent', ['myInput__field--danger']: this.props.isError, ['myInput__field--required']: this.props.isRequired  }, this.props.className)} onChange={this.props.onChange}></input>
                    {this.props.isSearch && <img onClick={this.props.onSearch} src='assets/Icons/search.png' className='myInput__search' />}
                    {isPasswordField && <img onClick={() => this.onChangeEyeIcon(!this.state.isEyeIconOpen)} src={`assets/Icons/eye-${this.state.isEyeIconOpen ? 'open' : 'hide'}.png`} className='myInput__eyeIcon my-cursor' />}
                </div>
                {this.props.isError && this.props.showError && <p className={classNames('text-danger myInput__error')}>{this.props.errorMessage}</p>}
            </div>
        )
    }
}

export default MyInput