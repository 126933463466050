import classNames from 'classnames'
import React, { ReactNode } from 'react'
import Button from 'react-bootstrap/Button'
import Modal, { ModalProps } from 'react-bootstrap/Modal'

export interface OwnProps extends ModalProps {
  footerContent?: ReactNode
  headerContent?: ReactNode
  children: ReactNode
  isVisible: boolean
  className?: string | undefined
  headerClassName?: string | undefined
  bodyClassName?: string | undefined
  footerClassName?: string | undefined
  // onClose
}

const MyModal = (props: OwnProps) => {
  return props.isVisible ? (
    <div id='MyModalComponent' className='position-relative'>
      <div
        className={classNames('modal show my-portal')}
      />
      <Modal.Dialog className={classNames('myModal', props.className)}>
        {props.headerContent && (
          <Modal.Header className={classNames('myModal__header', props.headerClassName)}>
            <Modal.Title>{props.headerContent}</Modal.Title>
          </Modal.Header>
        )}

        <Modal.Body className={classNames('myModal__body', props.bodyClassName)}>
          {props.children}
          {/* <h1>My Body</h1> */}
        </Modal.Body>

        {props.footerContent && <Modal.Footer className={classNames('myModal__footer', props.footerClassName)}>
          {props.footerContent}
        </Modal.Footer>
        }
      </Modal.Dialog>
    </div>
  ) : <div />
}

export default MyModal as any