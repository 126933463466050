import React, { useState } from 'react'
import {
    View,
    StyleSheet,
    Text,
    TextInput as Input,
    Pressable,
    Image,
    TouchableOpacity,
    Platform
} from 'react-native'
import {
    ActivityIndicator,
    HelperText,
    IconButton,
    Paragraph,
    TextInput,
    Title
} from 'react-native-paper'
import { modalCustomStyles, termStyles as styles } from './styles'
import Modal from 'react-modal'
import { Form, ResponsiveView, Screen } from '../../ReusableComponents'
import { ReduxAppState } from '../../utils/types'
import { compose, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { NavigationStackProp } from 'react-navigation-stack'
import * as _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import { ScrollView } from 'react-native-gesture-handler'

export interface ComponentProps {
    navigation: NavigationStackProp<any>
    showModal: boolean
    closeModal: () => any
}

export interface StateProps {}

export interface DispatchProps {}

export interface TermsModalState {}

export type TermsModalProps = ComponentProps & StateProps & DispatchProps

// Modal.setAppElement('#react-root')

class TermsModalComponent extends React.Component<TermsModalProps, TermsModalState> {
    constructor(props: TermsModalProps) {
        super(props)
    }

    onClose = () => {
        this.props.closeModal()
    }

    renderTermOfUse = () => {
        return (
            <ScrollView style={{ marginBottom: 10 }}>
                <View style={styles.paragraphContainer}>
                    <Title
                        style={{
                            textAlign: 'center',
                            fontSize: 24,
                            fontWeight: 'bold',
                            margin: 10,
                            fontFamily: "'Montserrat', sans-serif",
                            textTransform: 'uppercase'
                        }}>
                        <FormattedMessage id="Terms.msg.term.title" />
                    </Title>
                    <Paragraph style={styles.paragraphText}>
                        <FormattedMessage id="Terms.msg.term.prgrph1" />
                    </Paragraph>
                    <Paragraph style={styles.paragraphText}>
                        <FormattedMessage id="Terms.msg.term.prgrph2" />
                    </Paragraph>
                    <Paragraph style={styles.paragraphText}>
                        <FormattedMessage id="Terms.msg.term.prgrph3" />
                    </Paragraph>
                    <Paragraph style={styles.paragraphText}>
                        <FormattedMessage id="Terms.msg.term.prgrph4" />
                    </Paragraph>
                </View>
                <View style={styles.paragraphContainer}>
                    <Title style={styles.paragraphTitle}>
                        <FormattedMessage id="Terms.msg.termI.title" />
                    </Title>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termI.a" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termI.b" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termI.c" />
                    </Paragraph>
                </View>
                <View style={styles.paragraphContainer}>
                    <Title style={styles.paragraphTitle}>
                        <FormattedMessage id="Terms.msg.termII.title" />
                    </Title>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termII.a" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termII.b" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termII.c" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termII.d" />
                    </Paragraph>
                </View>
                <View style={styles.paragraphContainer}>
                    <Title style={styles.paragraphTitle}>
                        <FormattedMessage id="Terms.msg.termIII.title" />
                    </Title>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termIII.a" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termIII.b" />
                    </Paragraph>
                </View>
                <View style={styles.paragraphContainer}>
                    <Title style={styles.paragraphTitle}>
                        <FormattedMessage id="Terms.msg.termIV.title" />
                    </Title>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termIV.a" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termIV.b" />
                    </Paragraph>
                </View>
                <View style={styles.paragraphContainer}>
                    <Title style={styles.paragraphTitle}>
                        <FormattedMessage id="Terms.msg.termV.title" />
                    </Title>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termV.a" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termV.b" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termV.c" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termV.d" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termV.e" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termV.f" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termV.g" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termV.h" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termV.i" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termV.j" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termV.k" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termV.l" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termV.m" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termV.n" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termV.o" />
                    </Paragraph>
                </View>
                <View style={styles.paragraphContainer}>
                    <Title style={styles.paragraphTitle}>
                        <FormattedMessage id="Terms.msg.termVI.title" />
                    </Title>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termVI.a" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termVI.b" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termVI.c" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termVI.d" />
                    </Paragraph>
                </View>
                <View style={styles.paragraphContainer}>
                    <Title style={styles.paragraphTitle}>
                        <FormattedMessage id="Terms.msg.termVII.title" />
                    </Title>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termVII.a" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termVII.b" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termVII.c" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termVII.d" />
                    </Paragraph>
                </View>
                <View style={styles.paragraphContainer}>
                    <Title style={styles.paragraphTitle}>
                        <FormattedMessage id="Terms.msg.termVIII.title" />
                    </Title>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termVIII.a" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termVIII.b" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termVIII.c" />
                    </Paragraph>
                </View>
                <View style={styles.paragraphContainer}>
                    <Title style={styles.paragraphTitle}>
                        <FormattedMessage id="Terms.msg.termIX.title" />
                    </Title>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termIX.a" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termIX.b" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termIX.c" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termIX.d" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termIX.e" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termIX.f" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termIX.g" />
                    </Paragraph>
                </View>
                <View style={styles.paragraphContainer}>
                    <Title style={styles.paragraphTitle}>
                        <FormattedMessage id="Terms.msg.termX.title" />
                    </Title>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termX.a" />
                    </Paragraph>
                </View>
                <View style={styles.paragraphContainer}>
                    <Title style={styles.paragraphTitle}>
                        <FormattedMessage id="Terms.msg.termXI.title" />
                    </Title>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termXI.a" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termXI.b" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termXI.c" />
                    </Paragraph>
                </View>
                <View style={styles.paragraphContainer}>
                    <Title style={styles.paragraphTitle}>
                        <FormattedMessage id="Terms.msg.termXII.title" />
                    </Title>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termXII.a" />
                    </Paragraph>
                </View>
                <View style={styles.paragraphContainer}>
                    <Title style={styles.paragraphTitle}>
                        <FormattedMessage id="Terms.msg.termXIII.title" />
                    </Title>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termXIII.a" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termXIII.b" />
                    </Paragraph>
                </View>
                <View style={styles.paragraphContainer}>
                    <Title style={styles.paragraphTitle}>
                        <FormattedMessage id="Terms.msg.termXIV.title" />
                    </Title>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termXIV.a" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termXIV.b" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termXIV.c" />
                    </Paragraph>
                </View>
                <View style={styles.paragraphContainer}>
                    <Title style={styles.paragraphTitle}>
                        <FormattedMessage id="Terms.msg.termXV.title" />
                    </Title>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termXV.a" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termXV.b" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termXV.c" />
                    </Paragraph>
                </View>
                <View style={styles.paragraphContainer}>
                    <Title style={styles.paragraphTitle}>
                        <FormattedMessage id="Terms.msg.termXVI.title" />
                    </Title>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termXVI.a" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termXVI.b" />
                    </Paragraph>
                </View>
                <View style={styles.paragraphContainer}>
                    <Title style={styles.paragraphTitle}>
                        <FormattedMessage id="Terms.msg.termXVII.title" />
                    </Title>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termXVII.a" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termXVII.b" />
                    </Paragraph>
                    <Paragraph style={styles.text}>
                        <FormattedMessage id="Terms.msg.termXVII.c" />
                    </Paragraph>
                </View>
            </ScrollView>
        )
    }

    render() {
        const { showModal } = this.props
        return (
            <Modal isOpen={showModal} onRequestClose={this.onClose} style={modalCustomStyles}>
                <View style={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}>
                    <IconButton icon="close" size={25} onPress={this.onClose} />
                </View>
                {/* Terms of Use */}
                <Screen minWidth={450}>
                    <ResponsiveView height={'90%'} width={'55%'}>
                        <View
                            style={{
                                flexDirection: 'row',
                                alignSelf: 'center',
                                marginTop: 10
                            }}>
                            <Image
                                style={{ width: 200, height: 100, marginHorizontal: 10 }}
                                source={require('../../assets/artshare-new-logo-v2.png')}
                                resizeMode="contain"
                            />
                        </View>
                        {this.renderTermOfUse()}
                    </ResponsiveView>
                </Screen>
                <Screen maxWidth={449}>
                    <ResponsiveView height={'95%'} width={'95%'}>
                        <View
                            style={{
                                flexDirection: 'row',
                                alignSelf: 'center',
                                marginVertical: 20
                            }}>
                            <Image
                                style={{ width: 200, height: 100, marginHorizontal: 10 }}
                                source={require('../../assets/artshare-new-logo-v2.png')}
                                resizeMode="contain"
                            />
                        </View>
                        {this.renderTermOfUse()}
                    </ResponsiveView>
                </Screen>
            </Modal>
        )
    }
}

export default compose(TermsModalComponent as any)
