import React, { ReactNode } from 'react'
import classNames from 'classnames'
import { Alert, AlertProps } from 'react-bootstrap'
import MyLabel from '../MyLabel/MyLabel'
import MyButton from '../MyButton/MyButton'

export interface MyButtonProps extends AlertProps {
    containerClassName?: string | undefined
    onClose?: (...param: any) => void
    isVisible: boolean
    children: any
}

class MyAlert extends React.Component<MyButtonProps, {}> {

    render() {
        return this.props.isVisible && (
            <Alert variant={this.props.variant} id='MyAlert' className={classNames('myAlert', this.props.containerClassName)}>
                <MyLabel text={this.props.children} containerClassName='myAlert__labelContainer' className={classNames('myAlert__label', this.props.className)} />
                {this.props.onClose && <MyButton onClick={this.props.onClose} className={classNames(`myAlert__button myAlert__button--${this.props.variant ?? 'primary'}`)}>X</MyButton>}
            </Alert>
        )
    }
}

export default MyAlert