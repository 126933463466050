import React, { LegacyRef } from 'react'

import {
    View,
    ScrollView,
    Text,
    TouchableOpacity,
    Dimensions,
    Image,
    Platform,
    Touchable,
    Linking
} from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'

import { welcomeStyles as styles } from './styles'
import { Button, IconButton, Paragraph, Title } from 'react-native-paper'

import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { ReduxAppState } from '../../utils/types'
import { RouteName } from '../../navigation/types'
import { ResponsiveView, Screen } from '../../ReusableComponents'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import options from '../../utils/scrollview'
import { useMediaQuery } from 'react-responsive'
import navigation from '../../navigation'
import Terms from '../Terms/Terms'
import { CLEAR_USER_CHANGE_PASSWORD } from '../../reducers/ForgotPassword/changePassword'
import { CLEAR_VERIFY_OTP } from '../../reducers/ForgotPassword/verifyOTP'
import { CLEAR_FORGOT_PWD_OTP_REQ } from '../../reducers/ForgotPassword/otpRequest'
import HoverableText from '../../ReusableComponents/HoverableText/HoverableText'
import HomeComponent from './Home/Home'
import AboutComponent from './About/About'
import HowItWorksComponent from './HowItWorks/HowItWorks'
import { withRouter } from '../../navigation/withRouter'

const window = Dimensions.get('window')
const screen = Dimensions.get('screen')

export interface ComponentProps {
    navigation: DrawerNavigationProp<any>
    isRefreshing: boolean
}

export interface DispatchProps {
    onClearChangePwd: () => any
    onClearForgotPwdOtpReq: () => any
    onClearVerifyOTP: () => any
}

export interface StateProps {}

export interface WelcomePageState {
    isModal: boolean
    dimensions: Dimension
    activeTab: string
}

export interface Dimension {
    window: any
    screen: any
}
export type WelcomePageProps = ComponentProps & DispatchProps & StateProps

const Header = (props: any) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 991px)' })
    const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 990px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
    return (
        <View
            style={{
                position: 'absolute',
                top: 0,
                alignSelf: 'center',
                backgroundColor: '#FFFFFF',
                width: '100%'
            }}>
            {/* Header */}
            <Screen maxWidth={991}>
                <View
                    style={{
                        flexDirection: 'row',
                        marginHorizontal: isMobile ? '5%' : '15%',
                        alignItems: 'center'
                    }}>
                    <View style={[styles.headerView, { alignSelf: 'flex-start' }]}>
                        <Image
                            style={[
                                styles.headerImg,
                                { width: 150, height: 120, marginLeft: '10%' }
                            ]}
                            source={require('../../assets/art-share-icon2.png')}
                            resizeMode="contain"
                        />
                    </View>
                    <View style={{ position: 'absolute', right: 0 }}>
                        <HoverableText
                            style={[
                                styles.mobileTabTitle,
                                styles.buttonNav,
                                { marginHorizontal: 20, marginVertical: isDesktop ? 20 : 5 }
                            ]}
                            onHover={{ cursor: 'pointer', color: '#ff9933' }}
                            onPress={() => props.router?.navigate(`/signup`)}>
                            <FormattedMessage id="Welcome.msg.signup" />
                        </HoverableText>

                        <HoverableText
                            style={[
                                styles.mobileTabTitle,
                                styles.buttonNav,
                                { marginHorizontal: 20, marginVertical: isDesktop ? 20 : 5 }
                            ]}
                            onHover={{ cursor: 'pointer', color: '#ff9933' }}
                            onPress={() => props.router?.navigate(`/login`)}>
                            <FormattedMessage id="Welcome.msg.login" />
                        </HoverableText>
                    </View>
                </View>
            </Screen>
            <Screen.Desktop>
                <View style={[styles.headerView]}>
                    <Image
                        style={styles.headerImg}
                        source={require('../../assets/artshare-new-logo-v2.png')}
                        resizeMode="contain"
                    />
                </View>
            </Screen.Desktop>

            {/* Tabs */}
            <View
                style={[styles.headerView, { marginTop: -10, paddingBottom: isDesktop ? 0 : 15 }]}>
                <HoverableText
                    style={[
                        isDesktop ? styles.tabTitle : styles.mobileTabTitle,
                        {
                            padding: isMobile ? 0 : 25,
                            width: isMobile ? 80 : isDesktop ? 160 : 120,
                            textAlign: isMobile ? 'center' : 'auto',
                            color: props.activeTab === 'Home' ? '#ff9933' : '#000000'
                        }
                    ]}
                    onHover={{ cursor: 'pointer', color: '#ff9933' }}
                    onPress={() => props.onChange('Home')}>
                    <FormattedMessage id="Welcome.msg.home" />
                </HoverableText>

                <HoverableText
                    style={[
                        isDesktop ? styles.tabTitle : styles.mobileTabTitle,
                        {
                            padding: isMobile ? 0 : 25,
                            width: isMobile ? 80 : isDesktop ? 160 : 150,
                            textAlign: isMobile ? 'center' : 'auto',
                            color: props.activeTab === 'About' ? '#ff9933' : '#000000'
                        }
                    ]}
                    onHover={{ cursor: 'pointer', color: '#ff9933' }}
                    onPress={() => props.onChange('About')}>
                    <FormattedMessage id="Welcome.msg.about" />
                </HoverableText>

                <HoverableText
                    style={[
                        isDesktop ? styles.tabTitle : styles.mobileTabTitle,
                        {
                            padding: isMobile ? 0 : 25,
                            width: isMobile ? 100 : isDesktop ? 200 : 190,
                            textAlign: isMobile ? 'center' : 'auto',
                            color: props.activeTab === 'HowItWorks' ? '#ff9933' : '#000000'
                        }
                    ]}
                    onHover={{ cursor: 'pointer', color: '#ff9933' }}
                    onPress={() => props.onChange('HowItWorks')}>
                    <FormattedMessage id="Welcome.msg.work" />
                </HoverableText>

                <HoverableText
                    style={[
                        isDesktop ? styles.tabTitle : styles.mobileTabTitle,
                        {
                            padding: isMobile ? 0 : 25,
                            width: isMobile ? 100 : isDesktop ? 180 : 160,
                            textAlign: isMobile ? 'center' : 'auto',
                            color: props.activeTab === 'Contact' ? '#ff9933' : '#000000'
                        }
                    ]}
                    onHover={{ cursor: 'pointer', color: '#ff9933' }}
                    onPress={() => props.onChange('Contact')}>
                    <FormattedMessage id="Welcome.msg.contact" />
                </HoverableText>

                <Screen.Desktop>
                    <View style={{ flexDirection: 'row' }}>
                        <HoverableText
                            style={[
                                styles.tabTitle,
                                styles.buttonNav,
                                { marginHorizontal: 20, marginVertical: isDesktop ? 20 : 10 }
                            ]}
                            onHover={{ cursor: 'pointer', color: '#ff9933' }}
                            onPress={() => props.router?.navigate(`/signup`)}>
                            <FormattedMessage id="Welcome.msg.signup" />
                        </HoverableText>

                        <HoverableText
                            style={[
                                styles.tabTitle,
                                styles.buttonNav,
                                { marginHorizontal: 20, marginVertical: isDesktop ? 20 : 10 }
                            ]}
                            onHover={{ cursor: 'pointer', color: '#ff9933' }}
                            onPress={() => props.router?.navigate(`/login`)}>
                            <FormattedMessage id="Welcome.msg.login" />
                        </HoverableText>
                    </View>
                </Screen.Desktop>
            </View>
        </View>
    )
}

const Footer = (props: any) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 991px)' })
    const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 990px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
    return (
        <View>
            <View
                style={{
                    marginVertical: '2%',
                    marginHorizontal: '3%',
                    backgroundColor: '#F2F2F2'
                }}>
                <View
                    style={{
                        flexDirection: 'row',
                        marginTop: '2%',
                        marginHorizontal: isDesktop ? '3.5%' : '2%'
                    }}>
                    <View style={[styles.headerView, { marginTop: 10 }]}>
                        <Image
                            style={[
                                styles.headerImg,
                                { width: isMobile ? 150 : 200, height: isMobile ? 90 : 110 }
                            ]}
                            source={require('../../assets/artshare-new-logo.png')}
                            resizeMode="contain"
                        />
                    </View>
                </View>
                <View
                    style={{
                        marginLeft: isMobile ? '-3%' : '-5%',
                        backgroundColor: '#000000',
                        width: '80%',
                        padding: isDesktop ? 10 : 5
                    }}>
                    <View style={{ marginHorizontal: '13%' }}>
                        <Text
                            style={{
                                fontSize: isDesktop ? 35 : 18,
                                color: '#FFFFFF',
                                fontFamily: "'Montserrat', sans-serif"
                            }}>
                            Join the
                            <Text style={{ color: '#FF9933' }}> ArtShare </Text>Community!
                        </Text>
                    </View>
                </View>
                <View style={{ marginTop: 25, marginHorizontal: '6%' }}>
                    <Paragraph style={[styles.mediumText, { color: '#4D4D4D', lineHeight: 30 }]}>
                        Sign up with ArtShare to gain access to our curated collection of top
                        Philippine and Asian art pieces. Our crowdfunding platform makes it easy and
                        affordable to acquire iconic Philippine and Asian art masterpieces. With
                        just a few clicks, you&#39;ll have access to exclusive co-ownership
                        opportunities and the chance to diversify your portfolio.
                    </Paragraph>
                </View>

                <View style={{ marginTop: 50, marginHorizontal: '6%' }}>
                    <Paragraph style={[styles.mediumText, { color: '#4D4D4D', lineHeight: 30 }]}>
                        Start your art-collecting journey!
                    </Paragraph>
                </View>
                {/* Sign Up Button */}
                <View
                    style={{
                        flexDirection: 'row',
                        marginHorizontal: '6%',
                        marginBottom: 50,
                        marginTop: 30
                    }}>
                    <HoverableText
                        style={[
                            styles.tabTitle,
                            styles.buttonNav,
                            { width: 135, marginVertical: 0 }
                        ]}
                        onHover={{ cursor: 'pointer', color: '#ff9933' }}
                        onPress={() => props.router?.navigate(`/signup`)}>
                        <FormattedMessage id="Welcome.msg.signup" />
                    </HoverableText>
                </View>
            </View>
        </View>
    )
}

const ContactSection = (props: any) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 991px)' })
    const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 990px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
    return (
        <View
            style={{
                backgroundColor: '#FFFFFF',
                marginHorizontal: isDesktop ? '25%' : 0,
                marginTop: isDesktop ? '4%' : '40%'
            }}>
            <View style={{ flexDirection: 'row', height: '100%', marginBottom: '2%' }}>
                <View style={{ width: '100%', backgroundColor: '#FFFFFF' }}>
                    <Title
                        style={[
                            {
                                fontSize: 40,
                                fontWeight: '100',
                                textTransform: 'uppercase',
                                fontFamily: "'Montserrat', sans-serif",
                                color: '#000000',
                                textAlign: 'center'
                            }
                        ]}>
                        CONTACT US
                    </Title>

                    <View style={{ marginTop: 50, marginHorizontal: '15%' }}>
                        <Paragraph
                            style={[
                                styles.mediumText,
                                { color: '#4D4D4D', lineHeight: 30, textAlign: 'center' }
                            ]}>
                            We&#39;d love to hear from you! Whether you&#39;re interested in
                            co-owning a piece of art or just want to say hello, don&#39;t hesitate
                            to get in touch.
                        </Paragraph>
                    </View>
                    <View style={{ marginTop: 25, marginHorizontal: '15%' }}>
                        <Paragraph
                            style={[
                                styles.mediumText,
                                { color: '#4D4D4D', lineHeight: 30, textAlign: 'center' }
                            ]}>
                            If you have any inquiries or suggestions, feel free to reach out to us
                            using any of the following contact methods:
                        </Paragraph>
                    </View>

                    <View style={{ marginTop: 50, marginHorizontal: isDesktop ? '25%' : '20%' }}>
                        <TouchableOpacity
                            onPress={() => Linking.openURL('mailto:support@artshare.gallery')}>
                            <Image
                                style={{
                                    width: 40,
                                    height: 30,
                                    marginRight: 20,
                                    marginBottom: 20,
                                    tintColor: '#000000',
                                    alignSelf: 'center'
                                }}
                                source={require('../../assets/email.png')}
                                resizeMode="stretch"
                            />
                        </TouchableOpacity>
                        <Text
                            style={[
                                styles.mediumText,
                                {
                                    color: '#4D4D4D',
                                    lineHeight: 30,
                                    textAlign: 'center',
                                    flexShrink: 1
                                }
                            ]}>
                            {`Send us an email at `}
                            <Text style={[{ fontWeight: 'bold', color: '#FF9933' }]}>
                                support@artshare.gallery
                            </Text>
                            <Text>{` and we will get back to you as soon as possible.`}</Text>
                        </Text>
                    </View>

                    <View style={{ marginTop: 50, marginHorizontal: '20%' }}>
                        <View
                            style={{ flexDirection: 'row', marginBottom: 20, alignSelf: 'center' }}>
                            <IconButton
                                onPress={() =>
                                    Linking.openURL('https://www.facebook.com/artshare.gallery')
                                }
                                icon={'facebook'}
                                size={35}
                                style={styles.contactIcon}
                            />
                            <IconButton
                                onPress={() =>
                                    Linking.openURL('https://www.instagram.com/artshare.gallery/')
                                }
                                icon={'instagram'}
                                size={35}
                                style={styles.contactIcon}
                            />
                        </View>
                        <Text
                            style={[
                                styles.mediumText,
                                {
                                    color: '#4D4D4D',
                                    lineHeight: 30,
                                    flexShrink: 1,
                                    textAlign: 'center'
                                }
                            ]}>
                            {`Follow us on Facebook and Instagram to stay up-to-date on the latest news and updates about our platform.`}
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    )
}

class WelcomePage extends React.Component<WelcomePageProps, WelcomePageState> {
    scrollRef: any
    constructor(props: WelcomePageProps) {
        super(props)
        this.state = {
            isModal: false,
            dimensions: {
                window,
                screen
            },
            activeTab: 'Home'
        }
    }

    // tslint:disable-next-line:no-shadowed-variable
    onChange = ({ window, screen }: { window: any; screen: any }) => {
        this.setState({ dimensions: { window, screen } })
    }

    onChangeActiveTab = (tab: string) => {
        this.setState({ activeTab: tab })
        this.refs._scrollView.scrollTo({ x: 0, y: 0, animated: true })
    }

    componentDidMount() {
        this.props.onClearChangePwd()
        this.props.onClearVerifyOTP()
        this.props.onClearForgotPwdOtpReq()
    }

    onClickModal = () => {
        this.setState({ isModal: !this.state.isModal })
    }

    render() {
        return (
            <ResponsiveView height={'100%'} width={'100%'}>
                <ScrollView stickyHeaderIndices={[0]} ref="_scrollView">
                    {/* HEADER */}
                    <Header
                        navigation={this.props.navigation}
                        onChange={this.onChangeActiveTab}
                        activeTab={this.state.activeTab}
                        router={this.props?.router}
                    />
                    {/* CONTENTS */}
                    <View style={{ marginTop: '10%' }}>
                        {/* HOME */}
                        {this.state.activeTab === 'Home' && <HomeComponent />}
                        {this.state.activeTab === 'About' && <AboutComponent />}
                        {this.state.activeTab === 'HowItWorks' && (
                            <HowItWorksComponent navigation={this.props.navigation} />
                        )}

                        {/* HOW IT WORKS */}
                        {this.state.activeTab === '' && (
                            <View style={{ backgroundColor: '#4D4D4D', marginTop: 40 }}>
                                <View style={{ flexDirection: 'row', height: '100%' }}>
                                    <View style={{ width: '95%', backgroundColor: '#4D4D4D' }}>
                                        <View style={{ alignSelf: 'center' }}>
                                            <Screen.Desktop>
                                                <Image
                                                    style={{
                                                        width: window.width * 9,
                                                        height: window.height * 0.85
                                                    }}
                                                    source={require('../../assets/banner-artshare.png')}
                                                    resizeMode="contain"
                                                />
                                            </Screen.Desktop>
                                            <Screen.Tablet>
                                                <Image
                                                    style={{
                                                        width: window.width * 6,
                                                        height: window.height * 0.6
                                                    }}
                                                    source={require('../../assets/banner-artshare.png')}
                                                    resizeMode="contain"
                                                />
                                            </Screen.Tablet>
                                            <Screen.Mobile>
                                                <Image
                                                    style={{
                                                        width: window.width * 3,
                                                        height: window.height * 0.29
                                                    }}
                                                    source={require('../../assets/banner-artshare.png')}
                                                    resizeMode="contain"
                                                />
                                            </Screen.Mobile>
                                        </View>
                                        <View
                                            style={{
                                                marginTop: 50,
                                                marginHorizontal: '15%',
                                                borderColor: '#ff9933',
                                                borderWidth: 2,
                                                borderRadius: 3,
                                                padding: 15
                                            }}>
                                            <Paragraph
                                                style={[
                                                    styles.mediumText,
                                                    { color: '#FFFFFF', lineHeight: 30 }
                                                ]}>
                                                Our approach is simple and accessible to everyone.
                                                Our in-house art experts and connoisseurs have
                                                carefully curated the selection and conducted
                                                extensive due diligence on the artworks to offer you
                                                iconic pieces of art. Once they locate a masterpiece
                                                and come into agreement with owners to purchase such
                                                pieces, crowdfunding campaigns are initiated to
                                                acquire these artworks.
                                            </Paragraph>
                                        </View>
                                        <View style={{ marginTop: 50, marginHorizontal: '15%' }}>
                                            <Paragraph
                                                style={[
                                                    styles.mediumText,
                                                    { color: '#FFFFFF', textAlign: 'justify' }
                                                ]}>
                                                Here’s how it works for you:
                                            </Paragraph>
                                        </View>
                                        <View
                                            style={{
                                                marginTop: 50,
                                                flexDirection: 'row',
                                                marginHorizontal: '15%'
                                            }}>
                                            <Image
                                                style={{ width: 40, height: 40, marginRight: 20 }}
                                                source={require('../../assets/paint.png')}
                                                resizeMode="stretch"
                                            />
                                            <Paragraph
                                                style={[
                                                    styles.mediumText,
                                                    { color: '#FFFFFF', lineHeight: 30 }
                                                ]}>
                                                <Paragraph
                                                    style={[
                                                        styles.mediumText,
                                                        { color: '#FF9933' }
                                                    ]}>
                                                    Explore our Collection:{' '}
                                                </Paragraph>
                                                Browse through our expert-curated collection of
                                                Philippine and Asian artists.
                                            </Paragraph>
                                        </View>
                                        <View
                                            style={{
                                                marginTop: 20,
                                                flexDirection: 'row',
                                                marginHorizontal: '15%'
                                            }}>
                                            <Image
                                                style={{ width: 40, height: 40, marginRight: 20 }}
                                                source={require('../../assets/magnify.png')}
                                                resizeMode="stretch"
                                            />
                                            <Paragraph
                                                style={[
                                                    styles.mediumText,
                                                    { color: '#FFFFFF', lineHeight: 30 }
                                                ]}>
                                                <Paragraph
                                                    style={[
                                                        styles.mediumText,
                                                        { color: '#FF9933' }
                                                    ]}>
                                                    Choose Your Art Piece:{' '}
                                                </Paragraph>
                                                Once you&#39;ve determined your interest in
                                                participating in a specific art piece and the
                                                percentage of ownership one wishes to take on, you
                                                can own a fraction of the art work in this platform.
                                            </Paragraph>
                                        </View>
                                        <View
                                            style={{
                                                marginTop: 20,
                                                flexDirection: 'row',
                                                marginHorizontal: '15%'
                                            }}>
                                            <Image
                                                style={{ width: 40, height: 40, marginRight: 20 }}
                                                source={require('../../assets/coin.png')}
                                                resizeMode="stretch"
                                            />
                                            <Paragraph
                                                style={[
                                                    styles.mediumText,
                                                    { color: '#FFFFFF', lineHeight: 30 }
                                                ]}>
                                                <Paragraph
                                                    style={[
                                                        styles.mediumText,
                                                        { color: '#FF9933' }
                                                    ]}>
                                                    Purchase Art Fraction:{' '}
                                                </Paragraph>
                                                Once the artwork is fully funded, we acquire the
                                                piece on behalf of the co-owners.
                                            </Paragraph>
                                        </View>
                                        <View
                                            style={{
                                                marginTop: 20,
                                                flexDirection: 'row',
                                                marginHorizontal: '18%'
                                            }}>
                                            <Paragraph
                                                style={[
                                                    styles.mediumText,
                                                    { color: '#FFFFFF', lineHeight: 30 }
                                                ]}>
                                                At ArtShare, we offer a simple and straightforward
                                                fee structure:
                                            </Paragraph>
                                        </View>
                                        <View
                                            style={{
                                                marginTop: 20,
                                                flexDirection: 'row',
                                                marginHorizontal: '18%'
                                            }}>
                                            <Paragraph
                                                style={[
                                                    styles.mediumText,
                                                    { color: '#FFFFFF', lineHeight: 30 }
                                                ]}>
                                                • 1.5% fee per year, paid in the form of equity in
                                                the artwork: This covers management fees, secure
                                                storage, insurance costs, marketing and
                                                administrative costs, professional and appraisal
                                                fees. As a co-owner, you should not have to worry
                                                about these costs on your own and we are committed
                                                to making the co-ownership experience as hassle-free
                                                as possible.
                                            </Paragraph>
                                        </View>
                                        <View
                                            style={{
                                                marginTop: 20,
                                                flexDirection: 'row',
                                                marginHorizontal: '18%'
                                            }}>
                                            <Paragraph
                                                style={[
                                                    styles.mediumText,
                                                    { color: '#FFFFFF', lineHeight: 30 }
                                                ]}>
                                                • 20% of future profit: This aligns your interests
                                                with ArtShare in seeking the most attractive price
                                                for the art co-ownership. We believe that when our
                                                partners profit, we profit as well, and we are fully
                                                dedicated to maximizing the value of our art
                                                masterpieces.
                                            </Paragraph>
                                        </View>
                                        <View
                                            style={{
                                                marginTop: 20,
                                                flexDirection: 'row',
                                                marginHorizontal: '15%'
                                            }}>
                                            <Image
                                                style={{ width: 40, height: 40, marginRight: 20 }}
                                                source={require('../../assets/mona-lisa.png')}
                                                resizeMode="stretch"
                                            />
                                            <Paragraph
                                                style={[
                                                    styles.mediumText,
                                                    { color: '#FFFFFF', lineHeight: 30 }
                                                ]}>
                                                <Paragraph
                                                    style={[
                                                        styles.mediumText,
                                                        { color: '#FF9933' }
                                                    ]}>
                                                    Ownership:{' '}
                                                </Paragraph>
                                                Once artwork is acquired, each of the co-owner
                                                receives a fraction of the masterpiece represented
                                                by an ownership certificate.
                                            </Paragraph>
                                        </View>
                                        <View
                                            style={{
                                                marginTop: 20,
                                                flexDirection: 'row',
                                                marginHorizontal: '15%'
                                            }}>
                                            <Image
                                                style={{ width: 40, height: 40, marginRight: 20 }}
                                                source={require('../../assets/secure.png')}
                                                resizeMode="stretch"
                                            />
                                            <Paragraph
                                                style={[
                                                    styles.mediumText,
                                                    { color: '#FFFFFF', lineHeight: 30 }
                                                ]}>
                                                <Paragraph
                                                    style={[
                                                        styles.mediumText,
                                                        { color: '#FF9933' }
                                                    ]}>
                                                    Hold:{' '}
                                                </Paragraph>
                                                ArtShare will hold the artwork for a period of 3 to
                                                7 years, during which time the value of the piece is
                                                expected to increase. At the end of the holding
                                                period, we may sell the piece through auction or
                                                private sale and a pro-rata share in the net
                                                proceeds after fees is distributed to the co-owners
                                                based on their proportional share in ownership.
                                            </Paragraph>
                                        </View>
                                        <View
                                            style={{
                                                marginTop: 20,
                                                flexDirection: 'row',
                                                marginHorizontal: '15%'
                                            }}>
                                            <Image
                                                style={{ width: 40, height: 40, marginRight: 20 }}
                                                source={require('../../assets/professional.png')}
                                                resizeMode="stretch"
                                            />
                                            <Paragraph
                                                style={[
                                                    styles.mediumText,
                                                    { color: '#FFFFFF', lineHeight: 30 }
                                                ]}>
                                                <Paragraph
                                                    style={[
                                                        styles.mediumText,
                                                        { color: '#FF9933' }
                                                    ]}>
                                                    Flexibility:{' '}
                                                </Paragraph>
                                                If a co-owner wishes to sell their share before the
                                                holding period ends, they may do so after the hold
                                                out period of at least 120 days from the end of the
                                                primary offer period, through ArtShare&#39;s
                                                secondary market
                                                <Paragraph
                                                    style={[
                                                        styles.mediumText,
                                                        {
                                                            color: '#FFFFFF',
                                                            lineHeight: 30,
                                                            fontStyle: 'italic'
                                                        }
                                                    ]}>
                                                    {' '}
                                                    (This will become available soon.)
                                                </Paragraph>
                                            </Paragraph>
                                        </View>
                                        <View style={{ marginTop: 50, marginHorizontal: '15%' }}>
                                            <Paragraph
                                                style={[
                                                    styles.mediumText,
                                                    { color: '#FFFFFF', lineHeight: 30 }
                                                ]}>
                                                Ready to start your art collection journey? Sign up
                                                or login to our platform today.
                                            </Paragraph>
                                        </View>
                                        {/* Sign Up Button */}
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                marginHorizontal: '15%',
                                                marginBottom: 50
                                            }}>
                                            <Button
                                                onPress={() => props.router?.navigate(`/signup`)}
                                                style={styles.button}
                                                labelStyle={styles.textWhite}>
                                                <FormattedMessage id="Welcome.msg.signup" />
                                            </Button>
                                        </View>
                                    </View>
                                    <View
                                        style={{
                                            width: '5%',
                                            backgroundColor: '#ff9933',
                                            height: '90%'
                                        }}
                                    />
                                </View>
                            </View>
                        )}

                        {/* CONTACT US */}
                        {this.state.activeTab === 'Contact' && <ContactSection router={this.props?.router} />}
                        {/* FOOTER */}
                        <Footer router={this.props?.router} navigation={this.props.navigation} />
                    </View>
                </ScrollView>
                <Terms showModal={this.state.isModal} closeModal={this.onClickModal} />
            </ResponsiveView>
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    return {}
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onClearChangePwd: () => dispatch({ type: CLEAR_USER_CHANGE_PASSWORD }),
    onClearForgotPwdOtpReq: () => dispatch({ type: CLEAR_FORGOT_PWD_OTP_REQ }),
    onClearVerifyOTP: () => dispatch({ type: CLEAR_VERIFY_OTP })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(compose(WelcomePage as any)) as any)
