import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import ArtForm from '../../ReusableComponents/Form/ArtForm'
import ArtField, { ArtFieldsType } from '../../ReusableComponents/Form/ArtFields'
import { MyAlert, MyButton } from '../../ReusableComponents/ui-components'
import { ReduxAppState } from '../../utils/types'
import { Dispatch, compose } from 'redux'
import { CLEAR_USER, fbLoginUser, googleLoginUser, loginUser } from '../../reducers/User/user'
import { CLEAR_MODAL } from '../../reducers/Modal/showModal'
import { clearUserRegister } from '../../reducers/User/register'
import { verifyEmail } from '../../reducers/ForgotPassword/verifyEmail'
import { CLEAR_FORGOT_PWD_OTP_REQ, forgotPwdOtpReq } from '../../reducers/ForgotPassword/otpRequest'
import { connect } from 'react-redux'
import { SystemProperties } from '../../reducers/SystemProperty'
import { StackNavigationProp } from '@react-navigation/stack'
import { FormattedMessage } from 'react-intl'
import { RouteName } from '../../navigation/types'
import { withRouter } from '../../navigation/withRouter'
import { OtpProvider } from '../../ReusableComponents'
import { OtpProperties } from '../../ReusableComponents/OtpProvider/OtpProvider'

export interface OwnProps {
    navigation: StackNavigationProp<any>
    router: any
}
export interface StateProps {
    isLoading: boolean
    isRegistered: boolean
    user: any
    isRegisterSuccess: boolean
    isError: boolean
    isSignedIn: boolean
    isFederatedError: boolean
    errorMsg: string
    otpStatus: string
    isOtpModalOpen: boolean
    systemFields: SystemProperties[]
 }
export interface DispatchProps {
    loginUser: (username: string, password: string) => any
    fbLoginUser: () => any
    googleLoginUser: () => any
    onClearRegister: () => any
    onClearUser: () => any
    onClearModal: () => any
    onVerifyEmail: (data: any) => any
    requestOTP: (data: any) => any
    onClearOTP: () => void
    onOpenOTPModal: (value: boolean) => void
    onSetGlobalLoading: (value: boolean) => void
 }
export type LoginProps = OwnProps & StateProps & DispatchProps

const FormSchema = yup.object({
    username: yup.string().required('Username is required'),
    password: yup.string().required('Password is required'),
})

const Login = (props: LoginProps) => {

    const _onSubmit = (data: any) => props.loginUser(data.username, data.password)
    // const _onSubmit = (data: any) => {
    //     setCredential(data)
    //     otpProperties?.onRequestOtp?.(data)
    // }
    const { Form, onSubmit, control } = ArtForm({ formSchema: FormSchema, onSubmit: _onSubmit, otherOptions: { mode: 'onSubmit', criteriaMode: 'all' } })

    useEffect(() => { return props.onClearUser() }, [])

    const onForgotPassword = () => props.router?.navigate('/verify')

    const [credential, setCredential]: any = useState({})
    const [otpProperties, setOtpProperties]: any = useState(undefined)
    return (
        // <OtpProvider exposedProperties={(_otpProperties: OtpProperties) =>
        //     (setOtpProperties(_otpProperties))
        // } flow='Login' onProceedFlow={async () =>
        //     await props.loginUser(
        //         credential?.username,
        //         credential?.password
        //     )
        // }>
            <div id='LoginComponent' className='loginComponent'>
                <img src='Logo/artshare-big-logo-v2.png' className='loginComponent__column loginComponent__img' />
                <div className='loginComponent__column mx-5 mt-2'>
                    <div className='d-flex flex-row gap-3'>
                        <div className='loginComponent__column--line align-self-center' />
                        <img src='Logo/artshare-logo.png' />
                    </div>
                    {/* <MyAlert containerClassName='mb-3' variant='danger' isVisible={false} onClose={() => {}}>{'registerError'}</MyAlert> */}
                    <MyAlert containerClassName='mb-3' variant='success' isVisible={props.isRegisterSuccess}><FormattedMessage id='Login.msg.successRegister' /></MyAlert>
                    <MyAlert containerClassName='mb-3' variant='danger' isVisible={props.isError} onClose={props.onClearUser}>{props.errorMsg}</MyAlert>
                    <Form onSubmit={onSubmit} id='LoginForm' className='loginComponent__form' formSchema={FormSchema}>
                        <ArtField showError className='loginComponent__form--input' control={control} fieldstype={ArtFieldsType.ArtInput} id='username' name='username' placeholder={'Username'} />
                        <ArtField showError className='loginComponent__form--input' containerClassName='mt-2' type='password' control={control} fieldstype={ArtFieldsType.ArtInput} id='password' name='password' placeholder={'Password'} />
                        <MyButton isLoading={props.isLoading} className='loginComponent__form--button mt-4' buttonType='primary' onClick={onSubmit}>Login</MyButton>
                        <MyButton type='button' className='loginComponent__form--link mt-3' buttonType='link' onClick={onForgotPassword}>Forgot Password?</MyButton>
                        <p>Use your email or another service to continue with ArtShare (it's free)!</p>

                        <MyButton isLoading={props.isLoading} type='button' className='loginComponent__form--sso mt-3' buttonType='secondary' onClick={props.googleLoginUser} ><img src='Icons/google.png'/>Continue with Google</MyButton>
                        <MyButton isLoading={props.isLoading} type='button' className='loginComponent__form--sso mt-3' buttonType='secondary' onClick={props.fbLoginUser}><img src='Icons/fb.png' />Continue with Facebook</MyButton>
                    </Form>
                </div>
            </div>
        // </OtpProvider>
    )
}

const mapStateToProps = (state: ReduxAppState) => {
    return {
        user: state.api?.user?.authUser,
        errorMsg: state.api?.user?.authUser?.error,
        isSignedIn: state.api?.user?.authUser?.isSignedIn,
        isRegisterSuccess: state.api?.user?.registerUser?.statusText === 'success',
        isLoading: state.api?.user.authUser.loading,
        isError: state.api?.user.authUser.statusText === 'error',
        isFederatedError: state.api?.user.authUser.statusText === 'relogin',
        otpStatus: state.api?.forgotPwd?.forgotPwdOtpReq?.statusText,
        isOtpModalOpen: state?.custom?.otpModal,
        systemFields: state.api?.systemParam?.getAllSysParams?.response ?? []
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loginUser: (username: string, password: string) => loginUser(dispatch, { username, password }),
    fbLoginUser: () => fbLoginUser(dispatch),
    googleLoginUser: () => googleLoginUser(dispatch),
    onClearUser: () => dispatch({ type: CLEAR_USER }),
    onClearModal: () => dispatch({ type: CLEAR_MODAL }),
    onClearRegister: () => dispatch(clearUserRegister()),
    onVerifyEmail: (data: any) => verifyEmail(dispatch, data),
    requestOTP: (data: any) => forgotPwdOtpReq(dispatch, data),
    onClearOTP: () => dispatch({ type: CLEAR_FORGOT_PWD_OTP_REQ }),
    onOpenOTPModal: (value: boolean) => dispatch({ type: 'custom.otpModal', value }),
    onSetGlobalLoading: (value: boolean) => dispatch({ type: 'custom.isGlobalLoading', value })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(compose(Login as any))) as any