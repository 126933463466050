import React, { useState } from 'react'
import { ConfigValue, ReduxAppState } from '../../utils/types'
import { Dispatch } from 'redux'
import { clearUserRegister, registerUser } from '../../reducers/User/register'
import { createWallet } from '../../reducers/Wallet/createWallet'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import * as yup from 'yup'
import ArtForm from '../../ReusableComponents/Form/ArtForm'
import ArtField, { ArtFieldsType } from '../../ReusableComponents/Form/ArtFields'
import { MyAlert, MyButton, MyCheckbox, MyDropdown, MyLabel } from '../../ReusableComponents/ui-components'
import classNames from 'classnames'
import { RouteName } from '../../navigation/types'
import { StackNavigationProp } from '@react-navigation/stack'
import { AxiosError } from 'axios'
import { withRouter } from '../../navigation/withRouter'

export interface OwnProps {
    navigation: StackNavigationProp<any>
    router: any
}
export interface DispatchProps {
    onRegister: (data: any) => any
    onClearRegister: () => void
}
export interface StateProps {
    config: ConfigValue
}
export type RegisterProps = OwnProps & DispatchProps & StateProps

const emailFormat =
    '^[a-zA-Z0-9]+([a-zA-Z0-9_.]+)*@[a-zA-Z0-9]+([a-zA-Z0-9_.\\$&-]+)*\\.([a-zA-Z0-9]{2,4})$'
const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const FormSchema = yup.object().shape({
    username: yup.string()
        .required('Username is required')
        .matches(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u, 'Special characters is not allowed')
        .min(2, 'Minimun of 2 characters')
        .max(32, 'Maximum of 32 characters'),
    firstName: yup.string().required('First Name is required').matches(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u, 'Special characters is not allowed'),
    lastName: yup.string().required('Last Name is required').matches(/^'?\p{L}+(?:[' ]\p{L}+)*'?$/u, 'Special characters is not allowed'),
    password: yup.string()
        .required('Password is required')
        .matches(/[A-Z]/, 'Password should have atleast 1 uppercase')
        .matches(/[a-z]/, 'Password should have atleast 1 lowercase')
        .matches(/[0-9]/, 'Password should have atleast 1 number')
        .min(8, 'Minimum length should be 8 character')
        .max(32, 'Maximum length should be 32 character')
        .oneOf([yup.ref('confirmPassword'), null], 'The password do not match. Try again.'),
    confirmPassword: yup
        .string()
        .when('password', (_password, schema) => {
            return schema.test({
                test: () => Boolean(_password),
                message: 'Password field must have a value to verify'
            })
        })
        .oneOf([yup.ref('password'), null], 'The password do not match. Try again.')
        .required('Confirm Password is required'),
    email: yup
        .string()
        .required('Email Address is required')
        .test('email', 'Invalid email format', (value: any) => !!value.match(emailFormat))
        .min(16, 'Email Address must be between 16 and 40 characters long')
        .max(40, 'Email Address must be between 16 and 40 characters long'),
    confirmEmail: yup
        .string()
        .when('email', (_email, schema) => {
            return schema.test({
                test: () => Boolean(_email),
                message: 'Email Address field must have a value to verify'
            })
        })
        .oneOf([yup.ref('email'), null], "Emails don't match")
        .required('Confirm Email Address is required'),
    country: yup.string().required('Country is required'),
    city: yup.string().required('City is required'),
    terms: yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'),
    privacy: yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'),
})


const Register = (props: RegisterProps) => {
    const _onSubmit = (data: any) => {
        setIsRegisterLoading(true)
        props.onRegister(data)
            .then(() => {
                setTimeout(() => props.onClearRegister(), 10000)
                props.router?.navigate('/')
                reset()
            })
            .catch((err: AxiosError) => setRegisterError(err?.message ?? 'Something went wrong.'))
            .finally(() => setIsRegisterLoading(false))
    }
    const [registerError, setRegisterError] = useState('')
    const [isRegisterLoading, setIsRegisterLoading] = useState(false)
    const [country, setCountry] = useState('Philippines')
    const countryList: any = props.config?.country.map((_country: any) => ({ label: _country.name, value: _country.name }))
    const { Form, onSubmit, control, reset } = ArtForm({ formSchema: FormSchema, onSubmit: _onSubmit, otherOptions: { mode: 'onSubmit', criteriaMode: 'all' } })
    return (
        <div id='RegisterComponent' className='registerComponent'>
            <img src='Logo/artshare-big-logo-v2.png' className='registerComponent__column registerComponent__img' />
            <div className='registerComponent__column mx-5 mt-2'>
                <div className='d-flex flex-row gap-3'>
                    <div className='registerComponent__column--line align-self-center' />
                    <img src='Logo/artshare-logo.png' />
                </div>
                <MyAlert variant='danger' isVisible={!!registerError} onClose={() => setRegisterError('')}>{registerError}</MyAlert>
                <Form id='RegisterForm' className='registerComponent__form' onSubmit={onSubmit} formSchema={FormSchema}>
                    <div className='registerComponent__form--column mb-4'>
                        <ArtField showError containerClassName='registerComponent__form--inputContainer w-100' className='registerComponent__form--input' control={control} fieldstype={ArtFieldsType.ArtInput} id='username' name='username' label={'Username'} />
                    </div>
                    <div className='registerComponent__form--column mb-4'>
                        <ArtField showError type='password' containerClassName='registerComponent__form--inputContainer' className='registerComponent__form--input' control={control} fieldstype={ArtFieldsType.ArtInput} id='password' name='password' label={'Password'} />
                        <ArtField showError type='password' containerClassName='registerComponent__form--inputContainer' className='registerComponent__form--input' control={control} fieldstype={ArtFieldsType.ArtInput} id='confirmPassword' name='confirmPassword' label={'Confirm Password'} />
                    </div>
                    <div className='registerComponent__form--column mb-4'>
                        <ArtField showError containerClassName='registerComponent__form--inputContainer' className='registerComponent__form--input' control={control} fieldstype={ArtFieldsType.ArtInput} id='firstName' name='firstName' label={'First Name'} />
                        <ArtField showError containerClassName='registerComponent__form--inputContainer' className='registerComponent__form--input' control={control} fieldstype={ArtFieldsType.ArtInput} id='lastName' name='lastName' label={'Last Name'} />
                    </div>
                    <div className='registerComponent__form--column mb-4'>
                    <ArtField defaultValue='Philippines' fieldstype={ArtFieldsType.CustomField} name='country' id='country' control={control} renderField={(onChange: (...event: any[]) => any, value2: any, error: string) => {
                            return (
                                <MyDropdown label='Country' errorMessage={error} showError isError={!!error} value={country} onSelect={(value: string) => {
                                    onChange(value)
                                    setCountry(value)
                                }} itemList={countryList} placeholder={'Country'} containerClassName='registerComponent__form--ddLContainer' className='registerComponent__form--input' />
                            )
                        }} />
                        <ArtField showError containerClassName='registerComponent__form--inputContainer' className='registerComponent__form--input' control={control} fieldstype={ArtFieldsType.ArtInput} id='city' name='city' label={'City'} />
                    </div>
                    <div className='registerComponent__form--column mb-4'>
                        <ArtField showError containerClassName='registerComponent__form--inputContainer' className='registerComponent__form--input' control={control} fieldstype={ArtFieldsType.ArtInput} id='email' name='email' label={'Email Address'} />
                        <ArtField showError containerClassName='registerComponent__form--inputContainer' className='registerComponent__form--input' control={control} fieldstype={ArtFieldsType.ArtInput} id='confirmEmail' name='confirmEmail' label={'Confirm Email Address'} />
                    </div>
                    <div className='registerComponent__form--column justify-content-start mb-4'>
                        <ArtField fieldstype={ArtFieldsType.CustomField} name='terms' id='terms' control={control} renderField={(onChange: (...event: any[]) => any, value: any, error: string) => {
                                return (
                                    <div className='d-flex flex-row'>
                                        <MyCheckbox checked={value} onChange={onChange} />
                                        <MyLabel className={classNames('registerComponent__form--label', { ['my-text-error']: !!error })} text={<span>I agree to the <span className='registerComponent__form--label my-link my-cursor'>Terms and Conditions</span></span>} />
                                    </div>
                                )
                            }} />
                    </div>
                    <div className='registerComponent__form--column justify-content-start mb-4'>
                        <ArtField fieldstype={ArtFieldsType.CustomField} name='privacy' id='privacy' control={control} renderField={(onChange: (...event: any[]) => any, value: any, error: string) => {
                                return (
                                    <div className='d-flex flex-row'>
                                        <MyCheckbox checked={value} onChange={onChange} />
                                        <MyLabel className={classNames('registerComponent__form--label', { ['my-text-error']: !!error })} text={<span>I understand the <span className='registerComponent__form--label my-link my-cursor'>Privacy Policy</span></span>} />
                                    </div>
                                )
                            }} />
                    </div>
                    <div className='registerComponent__form--column mb-4'>
                        <MyButton disabled={isRegisterLoading} isLoading={isRegisterLoading} containerClassName='w-50' className='registerComponent__form--button mt-4' buttonType='primary' onClick={onSubmit}>Create Account</MyButton>
                    </div>
                </Form>
            </div>
        </div>
    )
}

const mapStateToProps = (state: ReduxAppState) => {
    return {
        config: state.config,
        isLoading: state.api.user.registerUser.loading,
        isError: state.api?.user?.registerUser?.statusText === 'error',
        errorMessage: state.api?.user?.registerUser?.error,
        systemFields: state.api?.systemParam?.getAllSysParams?.response ?? []
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onRegister: (data: any) => registerUser(dispatch, data),
    onClearRegister: () => dispatch(clearUserRegister()),
    onCreateWallet: (data: any) => createWallet(dispatch, data)
})

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Register as any))) as any