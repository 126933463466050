import React, { ReactNode } from 'react'
import classNames from 'classnames'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { Spinner, SpinnerProps } from 'react-bootstrap'

export interface MyLoaderProps extends SpinnerProps {
    text?: string | JSX.Element | JSX.Element[] | ReactNode
    containerClassName?: string | undefined
}

class MyLoader extends React.Component<MyLoaderProps, {}> {

    isValidElement = () => React.isValidElement(this.props.text) && (this.props.text?.type !== FormattedMessage && this.props.text?.type !== FormattedNumber)

    render() {
        return (
            <div id='MyLoaderComponent' className={classNames('myLoader', this.props.containerClassName)}>
                <Spinner {...this.props} className={classNames('myLoader__spinner', this.props.className)} variant={this.props.variant} size={this.props.size} />
            </div>
        )
    }
}

export default MyLoader