import Auth from '@aws-amplify/auth'
import { Dispatch } from 'redux'

export const BEGIN_RESET_USER_PASSWORD = 'API/USER/BEGIN_RESET_USER_PASSWORD '
export const SUCCESS_RESET_USER_PASSWORD = 'API/USER/SUCCESS_RESET_USER_PASSWORD'
export const ERROR_RESET_USER_PASSWORD = 'API/USER/ERROR_RESET_USER_PASSWORD'
export const CLEAR_USER_RESET_PASSWORD = 'API/USER/CLEAR_USER_RESET_PASSWORD'

export const resetPasswordInitialState = {
    response: [],
    loading: false
}

export interface ResetPasswordState {
    response: any
    status?: number
    statusText?: string
    loading: boolean
    error?: any
}

export interface ResetPasswordActions {
    type: string
    [item: string]: any
}

export const reducer = (newState: ResetPasswordState = resetPasswordInitialState, action: ResetPasswordActions) => {
    switch (action.type) {
        case BEGIN_RESET_USER_PASSWORD:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_RESET_USER_PASSWORD:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: 'success',
                status: action.status,
                error: ''
            })

        case ERROR_RESET_USER_PASSWORD:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status,
                error: action.message
            })
        case CLEAR_USER_RESET_PASSWORD:
            return resetPasswordInitialState
        default:
            return newState
    }
}

export const beginResetUserPassword = () => ({
    type: BEGIN_RESET_USER_PASSWORD,
})

export const successResetUserPassword = (response: any) => ({
    type: SUCCESS_RESET_USER_PASSWORD,
    data: response,
    status: response.status
})

export const errorResetUserPassword = (error: any) => ({
    type: ERROR_RESET_USER_PASSWORD,
    message: error.code
})

export const clearUserResetPassword = () => ({
    type: CLEAR_USER_RESET_PASSWORD
})

export const resetUserPassword = async (dispatch: Dispatch, data: any) => {
    dispatch(beginResetUserPassword())
    const currentUser = await Auth.currentAuthenticatedUser()
    return await Auth.changePassword(
        currentUser,
        data.oldPassword,
        data.password
    ).then(async (response) => {
        if (response === 'SUCCESS') {
            await Auth.updateUserAttributes(currentUser, {
                'custom:force_change_pwd': '0'
            }).then(async () => {
                await Auth.currentAuthenticatedUser({ bypassCache: true })
            })
            dispatch(successResetUserPassword({
                status: 200
            }))
        }
    }).catch(err => {
        dispatch(errorResetUserPassword(err))
        return new Promise<any>((resolve, reject) => { reject(err) })
    })
}

export default reducer