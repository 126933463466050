import awsInstance, { methods } from '../../core/axios.config'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'
import { Wallet } from '.'

export const BEGIN_UPDATE_WALLET = 'API/ITEM/BEGIN_UPDATE_WALLET'
export const SUCCESS_UPDATE_WALLET = 'API/ITEM/SUCCESS_UPDATE_WALLET'
export const ERROR_UPDATE_WALLET = 'API/ITEM/ERROR_UPDATE_WALLET'
export const CLEAR_UPDATE_WALLET = 'API/ITEM/CLEAR_UPDATE_WALLET'

export const updateWalletInitialState = {
    response: undefined,
    loading: false
}

export interface UpdateWalletState {
    response: Wallet | undefined
    status?: number
    statusText?: string
    loading: boolean
    error?: any
}

export interface UpdateWalletAction {
    type: string
    [item: string]: any
}

export const reducer = (newState: UpdateWalletState = updateWalletInitialState, action: UpdateWalletAction) => {
    switch (action.type) {
        case BEGIN_UPDATE_WALLET:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_UPDATE_WALLET:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: 'success',
                status: action.status
            })

        case ERROR_UPDATE_WALLET:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status
            })
        case CLEAR_UPDATE_WALLET:
            return updateWalletInitialState
        default:
            return newState
    }
}

export const beginUpdateWallet = () => ({
    type: BEGIN_UPDATE_WALLET,
})

export const successUpdateWallet = (response: AxiosResponse) => ({
    type: SUCCESS_UPDATE_WALLET,
    data: response.data.payload,
    status: response.status
})

export const errorUpdateWallet = (error: AxiosError) => ({
    type: ERROR_UPDATE_WALLET,
    status: error.response?.status,
})

export const updateWallet = (dispatch: Dispatch, id: string, data: any) => {
    const config: AxiosRequestConfig = {
        method: methods.PUT,
        url: `/wallet/${id}/funds`,
        data
    }

    dispatch(beginUpdateWallet())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            dispatch(successUpdateWallet(response))
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorUpdateWallet(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })

}

export default reducer