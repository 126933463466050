import React from 'react'
import classNames from 'classnames'
import { MyLabel } from '../'

export interface MyCheckboxProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    label?: string | JSX.Element
    containerClassName?: string | undefined
    control?: any
    isError?: boolean
    errorMessage?: string
}

class MyCheckbox extends React.Component<MyCheckboxProps, {}> {
    checkbox: any
    constructor(props: MyCheckboxProps) {
        super(props)
        this.checkbox = React.createRef()
    }

    onChangeValue = (e: any) => this.props?.onChange?.(e.target?.checked)

    render() {
        return (
            <>
                <div id='MyCheckboxComponent' className={classNames('myCheckbox', this.props.containerClassName)} onClick={() => this.checkbox?.current?.click()}>
                    {this.props?.label ? React.isValidElement(this.props.label) ? this.props.label : <MyLabel text='Name' className='myCheckbox__label' /> : null}
                    <div className='myCheckbox__container'>
                        <input {...this.props} ref={this.checkbox} type={'checkbox'} checked={this.props.checked} defaultValue={this.props.defaultValue} className={classNames('myCheckbox__container--field', { ['myCheckbox__field--danger']: this.props.isError }, this.props.className)} onChange={this.onChangeValue} />
                        <span className='myCheckbox__container--checkmark' />
                    </div>
                </div>
                {this.props.isError && <span className='text-danger'>{this.props.errorMessage}</span>}
            </>
        )
    }
}

export default MyCheckbox