import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup"

export interface ArtFormProps {
    onSubmit: (values: any, instance: any) => any
    formOptions?: any
    // renderFormChild: (formInstance: UseFormInstanceMeta<any>) => any
}

// const ArtForm = (props: ArtFormProps) => {
//     const {
//         Form,
//         meta,
//     } = useForm({
//         onSubmit: async (values, instance) => props.onSubmit(values, instance),
//         ...props.formOptions
//     })
//     // return (
//     //     <Form>
//     //         {props.renderFormChild(meta)}
//     //     </Form>
//     // )
//     return { Form, instance: meta }
// }

export const ArtForm = (props: any) => {
    const _artFormProps = useForm<any>({ resolver: yupResolver(props.formSchema), ...props?.otherOptions })
    const { handleSubmit, formState: { isSubmitted, isDirty } } = _artFormProps
    return {
        onSubmit: handleSubmit(props.onSubmit),
        Form,
        isSubmitted,
        isDirty,
        ..._artFormProps
    }
}

export const Form = (props: any) => (
    <form {...props} className={props?.className} onSubmit={props.onSubmit}>{props.children}</form>
)

export default ArtForm