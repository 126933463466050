import { StackNavigationProp } from '@react-navigation/stack'
import { ActivityIndicator, Button, Headline, IconButton, Paragraph, Subheading, Title } from 'react-native-paper'
import { ReduxAppState } from '../../utils/types'
import { compose, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { getItemList, GetItemListState } from '../../reducers/Items/getItems'
import { getItemInfo } from '../../reducers/Items/getItemInfo'
import { Items } from '../../reducers/Items'
import { AxiosPromise, AxiosResponse } from 'axios'
import React from 'react'
import { FormattedMessage, injectIntl, FormattedNumber } from 'react-intl'
import { Image, Text, View, TouchableOpacity, Linking } from 'react-native'
import { RouteName } from '../../navigation/types'
import { generateRandomId } from '../../utils'
import { deleteItem, DeleteItemState } from '../../reducers/Items/deleteItem'
import Buy from '../Buy/Buy'
import { activeList as styles, modalCustomStyles } from './styles'
import * as _ from 'lodash'
import { ArtText, ResponsiveView, Screen } from '../../ReusableComponents'
import { useMediaQuery } from 'react-responsive'
import Modal from 'react-modal'
import { validateReservedItem } from '../../reducers/Items/validateReservedItem'
import ArtworkSoldModal from '../Modal/ArtworkSoldModal'
import { withRouter } from '../../navigation/withRouter'
import { MyLoader } from '../../ReusableComponents/ui-components'
import { getItemById } from '../../reducers/Items/getItemById'
import { getPublicItemList } from '../../reducers/Items/public/getPublicItems'

export interface ComponentProps {
    router: any
    isSignedIn?: boolean
}

export interface DispatchProps {
    getItemList: (isPosted: number, isSignedIn: boolean) => any
    getItemInfo: (id: string) => AxiosPromise
    deleteItem: (id: string) => AxiosPromise
    onValidateReservedItem: () => any
}

export interface StateProps {
    isAddItemSuccess: boolean
    userType: string | number
    activeList: GetItemListState
    deleteItemResponse: DeleteItemState
    validateLoading: boolean
}

export interface ActiveListPageState {
    items: any
    isLoading: boolean
    isBuy: boolean
    isOpenReserveModal: boolean
    selectedItemId: string
    isOpenSoldModal: boolean
}

export type ActiveListPageProps = ComponentProps & DispatchProps & StateProps

const TableView = (props: any) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 987px)' })
    return (
        <ResponsiveView width={isDesktop ? '75%' : '100%'}>
            {props.children}
        </ResponsiveView>
    )
}
class ActiveListComponent extends React.Component<ActiveListPageProps, ActiveListPageState>{
    constructor(props: ActiveListPageProps) {
        super(props)
        this.state = {
            items: [],
            isLoading: false,
            isBuy: false,
            isOpenReserveModal: false,
            selectedItemId: '',
            isOpenSoldModal: false
        }
    }

    componentDidMount() {
        this.props.onValidateReservedItem()
            .then(() => this.props.getItemList(1, !!this.props.isSignedIn))
            .catch(() => this.props.getItemList(1, !!this.props.isSignedIn))
    }

    componentDidUpdate(prevProps: ActiveListPageProps) {
        if ((this.props.isAddItemSuccess !== prevProps.isAddItemSuccess && this.props.isAddItemSuccess) ||
            (!_.isEqual(this.props.deleteItemResponse?.response, prevProps.deleteItemResponse?.response))) {
            this.props.onValidateReservedItem()
                .then(() => this.props.getItemList(1, !!this.props.isSignedIn))
                .catch(() => this.props.getItemList(1, !!this.props.isSignedIn))
        }
    }

    onBuyItem = (item: string) => {
        if (this.props.userType === '0') {
            // this.props.deleteItem(item);
            this.setState({ selectedItemId: item })
        } else {
            this.props.getItemInfo(item);
            this.setState({ isBuy: true });
        }
    }

    onDeleteItem = () => {
        this.props.deleteItem(this.state.selectedItemId)
            .then(() => {
                this.setState({ selectedItemId: '' })
            })
    }

    closeModal = () => {
        this.setState({ isBuy: false })
    }

    navigateScreen = (itemId: any) => {
        // this.props.navigation.navigate(RouteName.ItemDetailPage, { isSoldOut })
        this.props.router?.navigate(`/listing/${itemId}`)
    }

    onSetSoldModal = (isOpen: boolean) => this.setState({ isOpenSoldModal: isOpen })

    getItemInfo = (item: Items) => {
        // this.props.getItemInfo(item.id)
        this.navigateScreen(item.id)
        // this.closeModal()

    }

    getImage = (id: string) => {
        this.props.getItemInfo(id)
    }

    getInitial = (name: string) => {
        if (!name) {
            return
        }
        const nameSplit = name.trim().split(' ')
        return nameSplit.map(a => a[0]).reduce((prev, curr) => prev += curr).toUpperCase()
    }

    onToggleReservedModal = (isOpen: boolean) => {
        this.setState({ isOpenReserveModal: isOpen })
    }

    convertUTCToDate = (date: Date) => {
        const convertedDate = new Date(date)
        return (convertedDate.getMonth() + 1) + '/' + convertedDate.getDate() + '/' + convertedDate.getFullYear()
    }

    render() {
        const { isBuy } = this.state
        const { activeList } = this.props
        return (
            <div id='ActiveListComponent' className='activeList'>
                {activeList?.loading ? <MyLoader animation='border' /> :
                    activeList?.response?.data?.map((item: any) => {
                        return (
                            <div className='activeList__card my-cursor' onClick={() => this.getItemInfo(item)}>
                                <img width={280} height={260} src={item?.thumbnail} alt='error' />
                                <div className='activeList__card--content'>
                                    <div className='activeList__card--header'>
                                        <p>{item?.title}</p>
                                        <p>{item?.artist}, {item?.year}</p>
                                    </div>
                                    <div className='activeList__card--body'>
                                        <p>Listing Value</p>
                                        <p>{item?.price}</p>
                                        <p>Sale Date</p>
                                        <p>09/02/2022</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}


const mapStateToProps = (state: ReduxAppState, ownProps: ComponentProps) => {
    return ({
        activeList: ownProps.isSignedIn ? state.api?.items?.getItemList : state?.api?.items?.getPublicItemList,
        deleteItemResponse: state.api?.items?.deleteItem,
        isAddItemSuccess: state.api?.items?.addItem?.statusText === 'success',
        userType: state.api?.user?.authUser?.response?.hasOwnProperty('custom:user_type') ? state.api?.user?.authUser?.response['custom:user_type'] : '1',
        validateLoading: state.api?.items.validateReservedItem?.loading
    })
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: ComponentProps) => ({
    getItemList: ((isPosted: number, isSignedIn: boolean) => isSignedIn ? getItemList(dispatch, { isPosted, type: 'ALL' }) : getPublicItemList(dispatch, { isPosted, type: 'ALL' })),
    getItemInfo: ((id: string) => getItemById(dispatch, id)),
    deleteItem: ((id: string) => deleteItem(dispatch, id)),
    onValidateReservedItem: (() => validateReservedItem(dispatch))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(compose(injectIntl(ActiveListComponent as any))))
