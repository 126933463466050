import React from 'react'
import { Pressable, Text, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import styles from './styles'
import { Colors } from 'react-native-paper'
import { generateRandomId } from '../../../utils'
import { FormattedNumber } from 'react-intl'
import { isArray } from 'lodash'

export interface TableProps {
    headerData: string[]
    tableData: any
    tableStyle?: any
    headerContainerStyle?: any
    rowContainerStyle?: any
    headerCellStyle?: any
    headerCellTextStyle?: any
    rowCellStyle?: any,
    userType?: any
}

const TableComponent = (props: TableProps) => {
    const { headerData, tableData } = props

    return (
        <SafeAreaView style={props.tableStyle}>
            <View style={[styles.header, props.headerContainerStyle]}>
                {headerData.map((data: string, i: number) =>
                    <View key={generateRandomId()} style={i === 0 ? [styles.headerCell, props.headerCellStyle, { backgroundColor: '#0D1921', borderLeftWidth: 1 }] : [styles.headerCell, props.headerCellStyle, { backgroundColor: '#0D1921' }]}>
                        <Text style={[props.headerCellTextStyle, { color: '#FFFFFF' }]}>{data}</Text>
                    </View>)}
            </View>
            {tableData?.length > 0 && tableData.map((_table: any) => {
                let table = _table
                let onClick
                if (!isArray(table) && table?.rowData) {
                    table = _table?.rowData
                    onClick = _table?.onClick
                }
                const statusColor = table[8];
                // remove last 2 elements
                table.splice(8, 8)
                if (props.userType !== '0') {
                    delete table[3]
                }

                return (
                    <View style={[styles.rowCellContainer]} key={generateRandomId()}>
                        {table.map((value: string | JSX.Element, i: number) => (
                            <Pressable onPress={onClick} key={generateRandomId()} style={i === 0 ? [styles.rowCell, { borderLeftWidth: 1, borderRightWidth: 1, borderColor: '#898989', borderLeftColor: '#F6F6F6' }] : [styles.rowCell, styles.border, { borderRightColor: i === (table.length - 1) ? '#F6F6F6' : '#A0A09E'}]}>
                                {(typeof value === 'string') && i !== (table.length - 1) ? <Text style={{ color: Colors.black, textAlign: 'center', marginTop: 10, fontFamily: "'Montserrat', sans-serif", fontSize: 12 }}>{value}</Text>
                                    : (typeof value === 'number') && i !== (table.length - 1) ? <Text style={{ color: Colors.black, textAlign: 'center', marginTop: 10, fontFamily: "'Montserrat', sans-serif", fontSize: 12 }}><FormattedNumber format='USD' value={value} /></Text>
                                        : (i === table.length - 1) ? <Text style={[styles.valueCellText, { color: statusColor }]}>{value}</Text>
                                            : value}
                            </Pressable>))}
                    </View>
                )
            })}
        </SafeAreaView >
    )
}

export default TableComponent