import awsInstance, { methods } from '../../core/axios.config'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'
import { Items } from '.'


export const BEGIN_GET_ITEM_INFO = 'API/ITEM/BEGIN_GET_ITEM_INFO'
export const SUCCESS_GET_ITEM_INFO = 'API/ITEM/SUCCESS_GET_ITEM_INFO'
export const ERROR_GET_ITEM_INFO = 'API/ITEM/ERROR_GET_ITEM_INFO'

export const getItemInfoInitialState = {
    response: undefined,
    loading: false,
}

export interface GetItemInfoState {
    response: Items | undefined
    status?: number
    statusText?: string
    loading: boolean
    error?: any,
    imageUrl?: string,
    authenticationDetailsFileId?: string,
    conditionReportId?: string,
    factSheetFileId?: string,
    otherImages?: any
}

export interface GetItemInfoActions {
    type: string
    [item: string]: any
}

export const reducer = (newState: GetItemInfoState = getItemInfoInitialState, action: GetItemInfoActions) => {
    switch (action.type) {
        case BEGIN_GET_ITEM_INFO:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_GET_ITEM_INFO:
            const _data = {...action.data}
            delete _data.result
            return Object.assign({}, newState, {
                response: {...action.data.result, ..._data},
                imageUrl: action.data.imageUrl,
                otherImages: action.data.otherImages,
                authenticationDetailsFileId: action.data.authenticationDetailsFileId,
                conditionReportId: action.data.conditionReportId,
                factSheetFileId: action.data.factSheetFileId,
                loading: false,
                statusText: 'success',
                status: action.status
            })

        case ERROR_GET_ITEM_INFO:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status
            })
        default:
            return newState
    }
}

export const beginGetItemInfo = () => ({
    type: BEGIN_GET_ITEM_INFO,
})

export const successGetItemInfo = (response: AxiosResponse) => ({
    type: SUCCESS_GET_ITEM_INFO,
    data: JSON.parse(response.data.message),
    status: response.status
})

export const errorGetItemInfo = (error: AxiosError) => ({
    type: ERROR_GET_ITEM_INFO,
    status: error.response?.status,
})

export const getItemInfo = (dispatch: Dispatch, id: string) => {
    const config: AxiosRequestConfig = {
        method: methods.GET,
        url: `/get/item?id=${id}`,
    }
    dispatch(beginGetItemInfo())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            dispatch(successGetItemInfo(response))
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorGetItemInfo(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })

}

export default reducer