import awsInstance, { methods } from '../../core/axios.config'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'
import { Items } from '.'

export const BEGIN_VALIDATE_RESERVED_ITEM = 'API/ITEM/BEGIN_VALIDATE_RESERVED_ITEM'
export const SUCCESS_VALIDATE_RESERVED_ITEM = 'API/ITEM/SUCCESS_VALIDATE_RESERVED_ITEM'
export const ERROR_VALIDATE_RESERVED_ITEM = 'API/ITEM/ERROR_VALIDATE_RESERVED_ITEM'
export const RESET_VALIDATE_RESERVED_ITEM = 'API/ITEM/RESET_VALIDATE_RESERVED_ITEM'

export const validateReservedItemInitialState = {
    response: undefined,
    loading: false,
}

export const reducer = (newState: ValidateReservedItemState = validateReservedItemInitialState, action: ValidateReservedItemActions) => {
    switch (action.type) {
        case BEGIN_VALIDATE_RESERVED_ITEM:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_VALIDATE_RESERVED_ITEM:
            return Object.assign({}, newState, {
                response: action.data.result,
                loading: false,
                statusText: 'success',
                status: action.status
            })

        case ERROR_VALIDATE_RESERVED_ITEM:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status
            })
        case RESET_VALIDATE_RESERVED_ITEM:
            return validateReservedItemInitialState
        default:
            return newState
    }
}

export const beginValidateReservedItem = () => ({
    type: BEGIN_VALIDATE_RESERVED_ITEM,
})

export const successValidateReservedItem = (response: AxiosResponse) => ({
    type: SUCCESS_VALIDATE_RESERVED_ITEM,
    data: response.data.message,
    status: response.status
})


export const errorValidateReservedItem = (error: AxiosError) => ({
    type: ERROR_VALIDATE_RESERVED_ITEM,
    status: error.response?.status,
})

export interface ValidateReservedItemActions {
    type: string
    [item: string]: any
}

export interface ValidateReservedItemState {
    response: Items | undefined
    status?: number
    statusText?: string
    loading: boolean
    error?: any,
}

export const validateReservedItem = (dispatch: Dispatch) => {
    const config: AxiosRequestConfig = {
        method: methods.PUT,
        url: `/item/validate`,
    }
    dispatch(beginValidateReservedItem())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            dispatch(successValidateReservedItem(response))
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorValidateReservedItem(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })
}

export default reducer