import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { Title } from 'react-native-paper'
import { NavigationStackProp } from 'react-navigation-stack'
import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import FooterHOC from '../../hoc/Footer'
import HeaderContentHOC from '../../hoc/HeaderContent'
import SideNavContentHOC from '../../hoc/SidenavContent'
import { TabName } from '../../navigation/types'
import { ResponsiveView } from '../../ReusableComponents'
import { ReduxAppState } from '../../utils/types'
import TranHistory from '../TranHistory/TranHistory'
import WalletDetail from '../WalletDetail/WalletDetail'
import { walletStyles as styles } from './styles'

export interface ComponentProps {
  navigation: NavigationStackProp<any>
  isRefreshing: boolean
  route: any
}

export interface DispatchProps { }

export interface StateProps {
  isLoading: boolean
  isError: boolean
}

export interface WalletPageState {
  activeTab: string
}

export type WalletPageProps = ComponentProps & DispatchProps & StateProps

class WalletPage extends React.Component<WalletPageProps, WalletPageState> {
  constructor(props: WalletPageProps) {
    super(props)
    this.state = { activeTab: TabName.WalletTab }
  }

  onChangeActiveTab = (item: string) => {
    this.setState({
      activeTab: item,
    })
  }

  render() {
    return (
      <ScrollView style={styles.demoContainer}>
        {/* Overview */}
        <ResponsiveView width={'80%'} viewStyle={{ alignSelf: 'center' }}>
          <View style={{ flexDirection: 'row' }}>
            <TouchableOpacity
              onPress={() => this.onChangeActiveTab(TabName.WalletTab)}>
              <Title
                numberOfLines={1}
                style={
                  this.state.activeTab === TabName.WalletTab
                    ? styles.activeTitle
                    : styles.title
                }>
                <FormattedMessage id="Wallet.msg.title" />
              </Title>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => this.onChangeActiveTab(TabName.TranHistoryTab)}>
              <Title
                numberOfLines={1}
                style={
                  this.state.activeTab === TabName.TranHistoryTab
                    ? styles.activeTitle
                    : styles.title
                }>
                <FormattedMessage id="Wallet.msg.tranHistory" />
              </Title>
            </TouchableOpacity>
          </View>
          {this.state.activeTab === TabName.WalletTab ? <WalletDetail navigation={this.props.navigation} route={this.props.route} /> : <TranHistory />}
        </ResponsiveView>
      </ScrollView>
    )
  }
}

const mapStateToProps = (state: ReduxAppState) => {
  return {}
}

const mapDispatchToProps = (dispatch: Dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(compose(SideNavContentHOC, FooterHOC)(WalletPage as any)) as any
