import { Platform, StyleSheet } from 'react-native'
import cpebColors from '../../utils/colors'

export const dashboardStyles = StyleSheet.create({
    title: {
        textTransform: 'uppercase',
        color: '#808080',
        fontSize: 16,
        fontFamily: "'Montserrat', sans-serif",
        marginBottom: 10,
        marginHorizontal: 20
    },
    activeTitle: {
        textTransform: 'uppercase',
        color: '#F7931E',
        fontSize: 16,
        fontFamily: "'Montserrat', sans-serif",
        marginBottom: 10,
        marginHorizontal: 20
    },
    titleContainer: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    demoContainer: {
        marginHorizontal: 20
    },
    iconStyle: {
        height: 40,
        width: 40,
        tintColor: '#fff',
        marginBottom: 15
    }
})