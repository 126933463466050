import React, { useRef } from 'react'
import classnames from 'classnames'

const ArtTextArea = (props: any) => {
    return (
        <div className={classnames('art-input-container', 'd-flex', props?.position === 'left' ? 'flex-row' : 'flex-column', props?.formContainerClassName)}>
            {props?.label && <label className={props?.labelClassName}>{props.label}</label> }
            <textarea {...props} value={props.value} defaultValue={props.defaultValue} className={classnames('art-input', props.isError && 'border border-danger', props.className)} required={false}/>
        </div>
    )
}

export default ArtTextArea