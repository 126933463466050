import awsInstance, { methods } from '../../core/axios.config'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'
import { Items } from '.'

export const BEGIN_DELETE_ITEM = 'API/ITEM/BEGIN_DELETE_ITEM'
export const SUCCESS_DELETE_ITEM = 'API/ITEM/SUCCESS_DELETE_ITEM'
export const ERROR_DELETE_ITEM = 'API/ITEM/ERROR_DELETE_ITEM'
export const RESET_DELETE_ITEM = 'API/ITEM/RESET_DELETE_ITEM'

export const deleteItemInitialState = {
  response: undefined,
  loading: false,
}

export const reducer = (
  newState: DeleteItemState = deleteItemInitialState,
  action: DeleteItemActions,
) => {
  switch (action.type) {
    case BEGIN_DELETE_ITEM:
      return Object.assign({}, newState, { loading: true })

    case SUCCESS_DELETE_ITEM:
      return Object.assign({}, newState, {
        response: action.data,
        loading: false,
        statusText: 'success',
        status: action.status,
      })

    case ERROR_DELETE_ITEM:
      return Object.assign({}, newState, {
        statusText: 'error',
        loading: false,
        status: action.status,
      })
    case RESET_DELETE_ITEM:
      return deleteItemInitialState
    default:
      return newState
  }
}

export const beginDeleteItem = () => ({
  type: BEGIN_DELETE_ITEM,
})

export const successDeleteItem = (response: AxiosResponse) => ({
  type: SUCCESS_DELETE_ITEM,
  data: response.data.message,
  status: response.status,
})

export const errorDeleteItem = (error: AxiosError) => ({
  type: ERROR_DELETE_ITEM,
  status: error.response?.status,
})

export interface DeleteItemActions {
  type: string
  [item: string]: any
}

export interface DeleteItemState {
  response: Items | undefined
  status?: number
  statusText?: string
  loading: boolean
  error?: any
}

export const deleteItem = (dispatch: Dispatch, data: string) => {
  const config: AxiosRequestConfig = {
    method: methods.PATCH,
    url: `/item/${data}`,
    data,
  }
  dispatch(beginDeleteItem())
  return awsInstance(config)
    .then((response: AxiosResponse) => {
      dispatch(successDeleteItem(response))
      return response
    })
    .catch((error: AxiosError) => {
      dispatch(errorDeleteItem(error))
      return new Promise<AxiosResponse>((resolve, reject) => {
        reject(error)
      })
    })
}

export default reducer
