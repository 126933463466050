import updateNotificationReducer, { updateNotificationInitialState, UpdateNotificationState } from './updateNotification'
import getNotificationReducer, { notificationInitialState, NotificationState } from './getNotifications'
import getNotificationWithLimitReducer, { notificationWithLimitInitialState, NotificationWithLimitState } from './getNotificationWithLimit'
import { combineReducers } from 'redux'

export interface Notification {
    id: string
    tranId: string
    tranType: string
    tranDate: string
    amt: number
    balance: number
    email: string
    userId: string
    operation: string
    status: number,
    tranStatus: number,
    [p: string]: any
}

export const notifInitialState: NotificationInitialState = {
    getNotification: notificationInitialState,
    getNotificationWithLimit: notificationWithLimitInitialState,
    updateNotication: updateNotificationInitialState
}

export interface NotificationInitialState {
    getNotification: NotificationState,
    getNotificationWithLimit: NotificationWithLimitState,
    updateNotication: UpdateNotificationState
}

const notificationReducer = combineReducers({
    getNotification: getNotificationReducer,
    getNotificationWithLimit: getNotificationWithLimitReducer,
    updateNotication: updateNotificationReducer
})

export default notificationReducer

