import React from 'react'

import { View, ScrollView, Text, TouchableOpacity, Dimensions, Image, Platform, Linking } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'

import { itemStyles as styles } from './styles'
import { ActivityIndicator, Button, IconButton, Title } from 'react-native-paper'

import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { ReduxAppState } from '../../utils/types'
import SideNavContentHOC from '../../hoc/SidenavContent'
import HeaderContentHOC from '../../hoc/HeaderContent'
import { Alert, ArtText, Form, ResponsiveView, Screen } from '../../ReusableComponents'
import { TabName } from '../../navigation/types';
import { Items } from '../../reducers/Items'
import Buy from '../Buy/Buy'
import MyImageGallery from "./components/MyImageGallery";
import { useMediaQuery } from 'react-responsive'
import FooterHOC from '../../hoc/Footer'
import SellModal from '../Modal/SellModal'
import { getPortfolio } from '../../reducers/Portfolio/getPortfolio'
import { User } from '../../reducers/User'
import { Portfolio } from '../../reducers/Portfolio'

import * as _ from 'lodash'
import { sellItem } from '../../reducers/Items/sellItem'
import ArtworkSoldModal from '../Modal/ArtworkSoldModal'
import classNames from 'classnames'
import { MyButton } from '../../ReusableComponents/ui-components'
import { getItemById } from '../../reducers/Items/getItemById'
import { getPublicItemById } from '../../reducers/Items/public/getPublicItemById'

export interface ComponentProps {
    navigation: StackNavigationProp<any>
    isRefreshing: boolean
    route?: any
}

export interface DispatchProps {
    onGetPortfolio: (params: any) => any
    getItemInfo: (id: string, isSignedIn: boolean) => any
}

export interface StateProps {
    item: Items
    isLoading: boolean
    isError: boolean
    imageUrl: string,
    authenticationDetailsFileId: string,
    conditionReportId: string,
    factSheetFileId: string,
    otherImages: any
    user: User
    portfolio: Portfolio
    isSignedIn: boolean
}

export interface ItemDetailsPageState {
    activeTab: string
    isBuy: boolean
    isSell: boolean
    isSellSuccess: boolean
    isOpenSoldModal: boolean
}

export type ItemDetailsPageProps = ComponentProps & DispatchProps & StateProps

class ItemDetailsPage extends React.Component<ItemDetailsPageProps, ItemDetailsPageState> {
    constructor(props: ItemDetailsPageProps) {
        super(props)
        this.state = {
            activeTab: TabName.DetailsTab,
            isBuy: false,
            isSell: false,
            isSellSuccess: false,
            isOpenSoldModal: false
        }
    }

    componentDidMount(): void {
        // if (this.props.onGetPortfolio({  }))
        if (this.props.item?.isPhysicallySold) {
            this.onSetSoldModal(true)
        }
        const itemId = _.get(this.props, 'router.params.itemId', '0')
        this.props.getItemInfo(itemId, this.props.isSignedIn)
    }

    componentDidUpdate(prevProps: Readonly<ItemDetailsPageProps>, prevState: Readonly<ItemDetailsPageState>, snapshot?: any): void {
        if (!_.isEqual(prevProps.item, this.props.item)) {
            if (this.props.item?.isPhysicallySold) {
                this.onSetSoldModal(true)
            }
            // this.props.onGetPortfolio({ userId: this.props.user.id, itemId: this.props.item.id })
        }
    }

    onSetSoldModal = (isOpen: boolean) => this.setState({ isOpenSoldModal: isOpen })

    onChangeActiveTab = (item: string) => {
        this.setState({
            activeTab: item
        })
    }

    handleSuccess = (isSuccess: boolean) => this.setState({ isSellSuccess: isSuccess })

    openSellModal = () => this.setState({ isSell: true })

    closeSellModal = () => this.setState({ isSell: false })

    onBuyItem = () => {
        this.setState({ isBuy: true })
    }

    closeModal = () => {
        this.setState({ isBuy: false })
    }

    convertUTCToDate = (date: Date) => {
        const convertedDate = new Date(date)
        return (convertedDate.getMonth() + 1) + '/' + convertedDate.getDate() + '/' + convertedDate.getFullYear()
    }

    checkDisabledBuy = (isSoldOut: boolean, listingDate: Date) => {
        let isDisabled = true
        const comparedDate = new Date(listingDate) <= new Date()
        if (comparedDate) {
            isDisabled = false
            if (isSoldOut) {
                isDisabled = true
            }
        }
        return isDisabled
    }

    render() {
        const { isBuy } = this.state
        const { item, isLoading } = this.props
        return <div id='ItemDetails' className='itemDetails'>
            <div className='itemDetails__header'>
                <h3 onClick={() => this.onChangeActiveTab(TabName.DetailsTab)} className={classNames('itemDetails__header--tab', { ['itemDetails__header--active']: this.state.activeTab === TabName.DetailsTab })}>DETAILS</h3>
                <h3 onClick={() => this.onChangeActiveTab(TabName.PreviousOwnerTab)} className={classNames('itemDetails__header--tab', { ['itemDetails__header--active']: this.state.activeTab === TabName.PreviousOwnerTab })}>PREVIOUS OWNERS</h3>
                <div className='itemDetails__header--line' />
            </div>
            <div className='itemDetails__content'>
                <div className='itemDetails__content--imageContainer'>
                    <img width={689} height={712} src={item?.thumbnail} />
                </div>
                <div className='itemDetails__description'>
                    <h2 className='itemDetails__description--title'>{item?.title?.toUpperCase?.()}</h2>
                    {/* <p className='itemDetails__description--subTitle'>{item?.year}</p> */}
                    <div className='itemDetails__description--values'>
                        <div className='itemDetails__description--box'>
                            <span>VALUE</span>
                            <span>|</span>
                            <span><FormattedNumber format='USD' value={Number(item?.price)} /></span>
                        </div>
                        {/* <div className='itemDetails__description--box'>
                            <span>SALE DATE</span>
                            <span>|</span>
                            <span>0000</span>
                        </div>
                        <div className='itemDetails__description--box'>
                            <span>SHARE VALUE</span>
                            <span>|</span>
                            <span>0000</span>
                        </div> */}
                    </div>
                    <div className='itemDetails__description--specs'>
                        <div>
                            <h3>Art Work</h3>
                            <p>{item?.artist}, {item?.year}</p>
                        </div>
                        <div>
                            <h3>Dimensions, Material and Technique</h3>
                            <p>N/A</p>
                        </div>
                        <div>
                            <h3>Description</h3>
                            <p>N/A</p>
                        </div>
                        <div>
                            <h3>Display Location</h3>
                            <p>Original Artwork- Edition 28/50 Manila</p>
                        </div>
                    </div>
                    <div className='itemDetails__description--docs'>
                        <p>Fact Sheet</p>
                        <p>Condition Report</p>
                        <p>Authentication Details</p>
                    </div>
                    <div className='itemDetails__description--buttons'>
                        <MyButton disabled className='itemDetails--secondary' buttonType='secondary'>SELL</MyButton>
                        <MyButton onClick={this.onBuyItem}>BUY</MyButton>
                    </div>
                </div>
            </div>
            <Buy showModal={isBuy} closeModal={this.closeModal} getItemInfo={this.props.getItemInfo} navigation={this.props.navigation} isDetailsPage={false} />
        </div>
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    const isSignedIn = state.api?.user?.authUser?.isSignedIn
    const _getItemInfo = isSignedIn ? state.api?.items?.getItemById : state?.api?.items?.getPublicItemById
    return ({
        item: _getItemInfo.response?.data,
        imageUrl: _getItemInfo.imageUrl,
        otherImages: _getItemInfo.otherImages,
        authenticationDetailsFileId: _getItemInfo.authenticationDetailsFileId,
        conditionReportId: _getItemInfo.conditionReportId,
        factSheetFileId: _getItemInfo.factSheetFileId,
        isLoading: _getItemInfo.loading,
        isError: _getItemInfo.statusText === 'error',
        user: state.api?.user?.authUser?.response,
        portfolio: state.api?.portfolio?.getPortfolio?.response,
        isSignedIn
    })
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onGetPortfolio: (params: any) => getPortfolio(dispatch, { filter: `userId==${params.userId}&itemId==${params.itemId}` }),
    getItemInfo: ((id: string, isSignedIn: boolean) => isSignedIn ? getItemById(dispatch, id) : getPublicItemById(dispatch, id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(SideNavContentHOC, FooterHOC)(ItemDetailsPage as any))