export const responseCode: { [key: string]: string } = {
    SUCCESS: '000',                // Success
    SQS_SUCCESSFUL: '001',         // SQS - Successfully sent
    SES_SUCCESSFUL: '002',         // SES - Email Successfully sent
    OMISE_SUCCESSFUL: '003',       // Omise Webhook - Successfully sent
    UPDATED_SUCCESSFULLY: '004',   // Updated Successfully
    LOCKED_ACC: '005',             // Your account has been locked
    NO_DATA_FOUND: '006',          // No Data Found
    INCORRECT_PAYLOAD: '007',      // Missing Data/Incorrect Payload
    USER_FOUND: '008',             // User found
    USER_NOT_FOUND: '009',         // User not found
    OTP_SUCCESSFUL: '010',         // OTP - Successfully sent
    OTP_USED: '011',               // One-Time PIN has already been used. Please request again
    OTP_EXPIRED: '012',            // One-Time PIN has expired. Please request again.
    OTP_INVALID: '013',            // The code you entered is invalid. \nPlease ensure code is same as sent in your registered email. \nAccount will be locked after 3rd failed attempt
    OTP_RESEND_EXCEEDED: '014',    // You have exceeded the maximum number of tries to resend your OTP for the day. \nYou may try again tomorrow.
    OTP_MAX_ATTEMPT: '015',        // Maximum invalid attempt. \nYou may try again tomorrow
    OTP_VERIFY_FAIL: '016',        // Verification failed. Max failed attempt. \nYou may try again tomorrow
    WS_CONNECTED: '021',           // Connected (Websocket)
    WS_DEFAULT: '022',             // Default (Websocket)
    WS_DISCONNECTED: '023',        // Disconnected (Websocket)
    WS_MESSAGE_SENT: '024',        // Message sent (Websocket)
    WS_MESSAGE_FAIL: '025',        // Message could not be received (Websocket)
    ITEM_VALIDATE: '031',          // Item-Validated
    ITEM_BUY: '032',               // Item-Buy
    ITEM_EXPIRED: '033',           // Item-Expired
    UNSUPPORTED_OP: '097',         // Unsupported operation
    UNKNOWN_RESP: '098',           // Uknown Response
    GENERIC_ERROR: '099'           // Generic error encountered/Something went wrong
}

export const responseMsg: { [key: string]: string } = {
    '000': `Success`,
    '001': `SQS - Successfully sent`,
    '002': `SES - Email Successfully sent`,
    '003': `Omise Webhook - Successfully sent`,
    '004': `Updated Successfully`,
    '005': `Your account has been locked`,
    '006': `No Data Found`,
    '007': `Missing Data/Incorrect Payload`,
    '008': `User found`,
    '009': `User not found`,
    '010': `OTP - Successfully sent`,
    '011': `One-Time PIN has already been used. Please request again`,
    '012': `One-Time PIN has expired. Please request again.`,
    '013': `The code you entered is invalid. \nPlease ensure code is same as sent in your registered email. \nAccount will be locked after 3rd failed attempt`,
    '014': `You have exceeded the maximum number of tries to resend your OTP for the day. \nYou may try again tomorrow.`,
    '015': `Maximum invalid attempt. \nYou may try again tomorrow`,
    '016': `Verification failed. Max failed attempt. \nYou may try again tomorrow`,
    '021': `Connected (Websocket)`,
    '022': `Default (Websocket)`,
    '023': `Disconnected (Websocket)`,
    '024': `Message sent (Websocket)`,
    '025': `Message could not be received (Websocket)`,
    '031': `Item-Validated`,
    '032': `Item-Buy`,
    '033': `Item-Expired`,
    '097': `Unsupported operation`,
    '098': `Uknown Response`,
    '099': `Generic error encountered/Something went wrong`
}