import { Platform, StyleSheet } from 'react-native'
import artColors from '../utils/colors'

export const HeaderStyles = StyleSheet.create({
    mainHeaderContainer: {
        backgroundColor: '#062838',
        marginBottom: 20
    },
    headerParent: {
        flexDirection: 'column',
        position: 'relative',
        marginTop: 40,
        marginHorizontal: 40,
        alignContent: 'space-between',
        justifyContent: 'space-between'
    },
    headerImage: {
        width: 100,
        height: 40,
        alignSelf: 'flex-end'
    },
    rightSideParent: {
        flexDirection: 'row',
        position: 'absolute',
        right: 0,
        height: '10%'
    },
    welcome: {
        alignSelf: 'center',
        flexDirection: 'row',
        marginVertical: 10
    },
    profileIcon: {
        backgroundColor: '#F7931E',
        alignSelf: 'center',
        marginRight: 10,
    },
    notifIcon: {
        backgroundColor: '#FFFFFF',
        borderColor: '#F7931E',
        borderWidth: 1,
        borderRadius: 40,
        alignSelf: 'center',
        marginRight: 10,
        overflow: 'hidden'
    },
    greetingsText: {
        fontSize: 16,
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: '700',
        color: '#333333',
        lineHeight: 20
    },
    caretDownIcon: {
        marginLeft: 10,
        marginTop: 10,
        color: '#9D9D9D'
    },
    logoutBtn: {
        borderRadius: 10,
        backgroundColor: artColors.labelColor
    },
    titleText: {
        textAlign: 'center',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 'bold',
        fontSize: 28,
        textTransform: 'uppercase'
    },
    row: {
        width: '20%',
        flexDirection: 'row',
        padding: 10,
        height: 80,
        justifyContent: 'space-between'
    },
    rowTextContainer: {
        justifyContent: 'center',
        alignSelf: 'center',
        width: '90%'
    },
    normalText: {
        fontFamily: "'Montserrat', sans-serif",
    },
    boldText: {
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 'bold'
    },
    italicText: {
        fontFamily: "'Montserrat', sans-serif",
        fontStyle: 'italic'
    },
    table: {
        backgroundColor: '#FEF4E9',
        borderRadius: 10,
        flexDirection: 'row',
        marginBottom: 5,
        flexWrap: 'wrap',
        width: '100%'
    },
    basicLabel: {
        fontSize: 13,
        textAlign: 'center',
        fontFamily: "'Montserrat', sans-serif",
    },
    circle: {
        width: 20,
        height: 20,
        borderRadius: 18,
        backgroundColor: 'red',
        marginLeft: '-36%',
        marginTop: '15%'
    },
    hiddenCircle: {
        width: 20,
        height: 20,
        borderRadius: 18,
        backgroundColor: 'red',
        marginLeft: '-36%',
        marginTop: '15%',
        opacity: 0
    },
    count: {
        marginTop: 1,
        padding: 2,
        color: '#FFF',
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 'bold',
        fontFamily: "'Montserrat', sans-serif"
    }
})

export const FooterStyles = StyleSheet.create({
    container: {
        bottom: 0,
        alignSelf: 'center',
        padding: 5,
        marginLeft: -100,
    },
    imageContainer: {
        height: 40,
        width: 100,
        marginBottom: 10
    },
    FooterImage: {
        width: 100,
        height: 40,
        alignSelf: 'center'
    },
    greetingsText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: "#5C6E2D"
    },
    meterNoText: {
        color: "#F0F4F4",
    }
})

export const SideNavStyles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#FFFFFF',
        flexDirection: 'row',
        marginTop: 10
    },
    menu: {
        alignSelf: 'center',
        width: '100%',
        flexWrap: 'nowrap',
        alignItems: 'center',
        padding: 10
    },
    menuText: {
        alignSelf: 'center',
        fontSize: 12,
        fontFamily: "'Montserrat', sans-serif",
        color: '#9D9D9D',
        textTransform: 'uppercase'
    },
    activeMenuText: {
        fontSize: 12,
        fontFamily: "'Montserrat', sans-serif",
        color: '#F7931E',
        textTransform: 'uppercase'
    },
    welcome2: {
        width: '100%',
        flexDirection: 'row',
        position: 'relative',
        alignContent: 'space-between',
        justifyContent: 'space-between',
        backgroundColor: 'red'
    },
    welcome: {
        alignSelf: 'center',
        flexDirection: 'row',
        marginVertical: 10
    },
    profileIcon: {
        backgroundColor: '#9D9D9D',
        alignSelf: 'center',
        marginRight: 10,
    },
    greetingsText: {
        fontSize: 16,
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: '700',
        color: '#333333',
        lineHeight: 20
    },
    caretDownIcon: {
        marginLeft: 10,
        marginTop: 10,
        color: '#9D9D9D'
    },
})