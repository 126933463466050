import * as _ from 'lodash'
import { SystemProperties } from '../reducers/SystemProperty'

export const fieldsList = {
    Reserve: 'RESERVE',
    Buy: 'BUY',
    Sell: 'SELL',
    Registration: 'REGISTRATION',
    Login: 'SSO_LOGIN',
    OtpValidation: 'OTP_VALIDATION',
    OtpAttempts: 'OTP_ATTEMPTS',
    Deposit: 'DEPOSIT',
    DepositFee: 'DEPOSIT_FEE',
    Reset: 'OTP_RESET',
    Gcash: 'GCASH',
    Grabpay: 'GRABPAY',
    OnlineBanking: 'ONLINE_BANKING',
    OverTheCounter: 'OVER_THE_COUNTER',
    Card: 'CARD'

}


export const validateFields = (flow: string, fields: SystemProperties[]) => {
    const systemCode = _.get(fieldsList, flow)
    const field = fields.find((_field: SystemProperties) => _field.code === systemCode )
    return true
    // if (field?.fieldType === 'Dropdown') {
    //     return field?.value ?? false
    // }
    // return field
}