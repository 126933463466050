import getFundsReducer, { getFundsInitialState, GetFundsState } from './getFunds'
import createWalletReducer, { createWalletInitialState, CreateWalletState } from './createWallet'
import updateWalletReducer, { updateWalletInitialState, UpdateWalletState } from './updateWallet'
import getForexReducer, { getForexInitialState, GetForexState } from './getForex'
import generatePaymenyCodeReducer, { GeneratePaymentCodeState, generatePaymentCodeInitialState } from './generatePaymentCode'
import calculateFeeReducer, { CalculateFeeState, calculateFeeInitialState } from './calculateFee'
import fetchDetailsReducer, { fetchDetailsInitialState, FetchDetailsState } from './fetchDetails'
import { combineReducers } from 'redux'

export interface Wallet {
    id: string,
    balance: number,
    currency: string,
    email: string
    [p: string]: any
}

export const walletInitialState: WalletInitialState = {
    getFunds: getFundsInitialState,
    createWallet: createWalletInitialState,
    updateWallet: updateWalletInitialState,
    getForex: getForexInitialState,
    generatePaymentCode: generatePaymentCodeInitialState,
    calculateFee: calculateFeeInitialState,
    fetchDetails: fetchDetailsInitialState
}

export interface WalletInitialState {
    getFunds: GetFundsState
    createWallet: CreateWalletState
    updateWallet: UpdateWalletState
    getForex: GetForexState
    generatePaymentCode: GeneratePaymentCodeState
    calculateFee: CalculateFeeState
    fetchDetails: FetchDetailsState
}

const itemReducer = combineReducers({
    getFunds: getFundsReducer,
    createWallet: createWalletReducer,
    updateWallet: updateWalletReducer,
    getForex: getForexReducer,
    generatePaymentCode: generatePaymenyCodeReducer,
    calculateFee: calculateFeeReducer,
    fetchDetails: fetchDetailsReducer
})

export default itemReducer

