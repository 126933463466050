import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import awsInstance, { methods } from '../../core/axios.config'

export const BEGIN_VERIFY_OTP = 'API/USER/BEGIN_VERIFY_OTP'
export const SUCCESS_VERIFY_OTP = 'API/USER/SUCCESS_VERIFY_OTP'
export const ERROR_VERIFY_OTP = 'API/USER/ERROR_VERIFY_OTP'
export const CLEAR_VERIFY_OTP = 'API/USER/CLEAR_VERIFY_OTP'

export const verifyOTPInitialState = {
    response: [],
    loading: false
}

export interface VerifyOTPState {
    response: any
    status?: number
    statusText?: string
    loading: boolean
    error?: any
}

export interface VerifyOTPActions {
    type: string
    [item: string]: any
}

export const reducer = (newState: VerifyOTPState = verifyOTPInitialState, action: VerifyOTPActions) => {
    switch (action.type) {
        case BEGIN_VERIFY_OTP:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_VERIFY_OTP:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: 'success',
                status: action.status,
                error: ''
            })

        case ERROR_VERIFY_OTP:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status,
                error: action.message
            })
        case CLEAR_VERIFY_OTP:
            return verifyOTPInitialState
        default:
            return newState
    }
}

export const beginVerifyOTP = () => ({
    type: BEGIN_VERIFY_OTP,
})

export const successVerifyOTP = (response: any) => ({
    type: SUCCESS_VERIFY_OTP,
    data: response.data?.message,
    status: response.status
})

export const errorVerifyOTP = (error: any) => ({
    type: ERROR_VERIFY_OTP,
    message: error.code
})

export const clearVerifyOTP = () => ({
    type: CLEAR_VERIFY_OTP
})

export const verifyOTP = async (dispatch: Dispatch, data: any) => {
    const config: AxiosRequestConfig = {
        method: methods.POST,
        url: `/otp/verify-otp`,
        data
    }
    dispatch(beginVerifyOTP())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            dispatch(successVerifyOTP(response))
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorVerifyOTP(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })
}

export default reducer