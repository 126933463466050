import getArtShareListReducer, { getArtShareListInitialState, GetArtShareListState } from './getArtShareList'
import getItemListReducer, { getItemListInitialState, GetItemListState } from './getItems'
import getItemInfoReducer, { getItemInfoInitialState, GetItemInfoState } from './getItemInfo'
import addItemReducer, { addItemInitialState, AddItemState } from './addItem'
import sellItemReducer, { sellItemInitialState, SellItemState } from './sellItem'
import addItemToPoolReducer, { addItemToPoolInitialState, AddItemToPoolState } from './addItemToPool'
import buyItemReducer, { buyItemInitialState, BuyItemState } from './buyItem'
import deleteItemReducer, { deleteItemInitialState, DeleteItemState } from './deleteItem'
import validateReservedItemReducer, { validateReservedItemInitialState, ValidateReservedItemState } from './validateReservedItem'
import getReservedItemByUserReducer, { getReservedItemByUserInitialState, GetReservedItemByUserState } from './getReservedItemByUser'
import getItemByIdReducer, { getItemByIdInitialState, GetItemByIdState } from './getItemById'
import getPublicItemListReducer, { getPublicItemListInitialState, GetPublicItemListState } from './public/getPublicItems'
import getPublicItemLByIdReducer, { getPublicItemByIdInitialState, GetPublicItemByIdState} from './public/getPublicItemById'
import { combineReducers } from 'redux'
import { AxiosResponse } from 'axios'
import { getPresignedUrl } from '../PresignedUrl/getPresignedUrl'

export interface Items {
    id: string
    artist: string
    description: string
    displayLocation: string
    dmt: string
    listedValue: string
    listingDate: Date
    saleDate: Date
    shareOnSale: string
    title: string
    year: string
    numberOfShare: number
    valuePerShare: number
    createdAt: number
    [p: string]: any
}

export const itemInitialState: ItemInitialState = {
    getArtShareList: getArtShareListInitialState,
    getItemList: getItemListInitialState,
    getItemInfo: getItemInfoInitialState,
    addItem: addItemInitialState,
    addItemToPool: addItemToPoolInitialState,
    buyItem: buyItemInitialState,
    deleteItem: deleteItemInitialState,
    validateReservedItem: validateReservedItemInitialState,
    getReservedItemByUser: getReservedItemByUserInitialState,
    sellItem: sellItemInitialState,
    getItemById: getItemByIdInitialState,
    getPublicItemList: getPublicItemListInitialState,
    getPublicItemById: getPublicItemListInitialState
}

export interface ItemInitialState {
    getArtShareList: GetArtShareListState
    getItemList: GetItemListState
    getItemInfo: GetItemInfoState
    addItem: AddItemState
    buyItem: BuyItemState
    deleteItem: DeleteItemState
    addItemToPool: AddItemToPoolState
    validateReservedItem: ValidateReservedItemState
    getReservedItemByUser: GetReservedItemByUserState
    sellItem: SellItemState
    getItemById: GetItemByIdState
    getPublicItemList: GetPublicItemListState
    getPublicItemById: GetItemByIdState
}

const itemReducer = combineReducers({
    getArtShareList: getArtShareListReducer,
    getItemList: getItemListReducer,
    getItemInfo: getItemInfoReducer,
    addItem: addItemReducer,
    buyItem: buyItemReducer,
    deleteItem: deleteItemReducer,
    addItemToPool: addItemToPoolReducer,
    validateReservedItem: validateReservedItemReducer,
    getReservedItemByUser: getReservedItemByUserReducer,
    sellItem: sellItemReducer,
    getItemById: getItemByIdReducer,
    getPublicItemList: getPublicItemListReducer,
    getPublicItemById: getPublicItemLByIdReducer
})

export const getItemThumbnail = async (dispatch, response: AxiosResponse) => {
    let items = _.get(response, 'data.data', [])
    const isNotArray = !_.isArray(items)
    if (isNotArray) {
        items = [items]
    }
    const newItem: any = []
    let _response = response
    for (const item of items) {
        const imageUrl = _.get(item, 'itemFiles.otherImages[0]', '')
        const fileType = imageUrl?.split?.('/')?.[0] ?? ''
        if (imageUrl && fileType) {
            const resp = await getPresignedUrl(dispatch, { key: imageUrl, fileType })
            newItem.push({...item, thumbnail: resp?.data?.data?.presignedKey})
        }
    }
    if (!_.isEmpty(newItem)) {
        _response = {..._response, data: { ..._response.data, data: isNotArray ? _.first(newItem) : newItem }}
    }
    return _response
}

export default itemReducer

