import * as _ from 'lodash'
import React from 'react'
import classNames from 'classnames'
import { MyInput, MyLabel } from '../'
import { Dropdown } from 'react-bootstrap'
import { DropdownValue } from '../../../utils/types'

export interface MyDropdownProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    label?: string | JSX.Element
    containerClassName?: string | undefined
    control?: any
    isError?: boolean
    errorMessage?: string
    showError?: boolean
    itemClassName?: string | undefined
    toggleClassName?: string | undefined
    itemList: DropdownValue[]
    menuClassName?: string | undefined
    onSelect: (value: any) => any
    isRequired?: boolean
}

export interface MyDropdownState {
    itemList: DropdownValue[]
    search: string
}

class MyDropdown extends React.Component<MyDropdownProps, MyDropdownState> {
    constructor(props: MyDropdownProps) {
        super(props)
        const _itemList = props.itemList && _.isArray(props.itemList) ? props.itemList : []
        this.state = {
            itemList: _itemList,
            search: ''
        }
    }

    onSearch = (text: string) => {
        const _itemList = this.props.itemList && _.isArray(this.props.itemList) ? this.props.itemList : []
        const newItemList = _itemList.filter((item: DropdownValue) => item.value.toLowerCase().indexOf(text) !== -1 || item.label.toLowerCase().indexOf(text) !== -1)
        this.setState({ itemList: newItemList })
    }

    render() {
        let value: any = this.props.placeholder ?? 'Please Select'
        const _itemList = this.props.itemList && _.isArray(this.props.itemList) ? this.props.itemList : []
        if ((!!this.props.value || String(this.props.value) === '0') || (!!this.props.defaultValue || String(this.props.defaultValue) === '0')) {
            const _value = !!this.props.value ? this.props.value : this.props.defaultValue ?? ''
            const _item: any = _.find(_itemList, { value: _value })
            value = _item?.label
        }
        return (
            <Dropdown id='MyDropdownComponent' className={classNames('myDropdown', this.props.containerClassName, { ['myDropdown__required']: this.props.isRequired })}>
                {this.props?.label ? React.isValidElement(this.props.label) ? this.props.label : <MyLabel text={this.props.label} className='myDropdown__label' /> : null}
                <Dropdown.Toggle disabled={this.props.disabled} id='dropdownDDL' className={classNames('myDropdown__toggle', { ['myDropdown__toggle--danger']: this.props.isError, ['myDropdown__toggle--required']: this.props.isRequired }, this.props.toggleClassName)}>
                    <p>{value}</p>
                    <img src='assets/Icons/arrow-down.png' className='myDropdown__downArrow' />
                </Dropdown.Toggle>
                <Dropdown.Menu className={classNames('myDropdown__menu', this.props.menuClassName)}>
                    {_itemList.map((item: DropdownValue) => (
                        <Dropdown.Item onClick={() => this.props.onSelect(item.value)} className={classNames('myDropdown__item', this.props.itemClassName)}>{item.label}</Dropdown.Item>
                    ))}
                    {/* <MyInput onChange={(e) => this.onSearch(e.target?.value?.toLowerCase?.())} placeholder='search' className={classNames('myDropdown__item w-100', this.props.itemClassName)} /> */}
                </Dropdown.Menu>
                {this.props.isError && this.props.showError && <span className={classNames('text-danger myDropdown__error')}>{this.props.errorMessage}</span>}
            </Dropdown>
        )
    }
}

export default MyDropdown