import authUserReducer, { authUserInitialState, AuthUserInfoState } from './user'
import registerReducer, { registerUserInitialState, RegisterUserState } from './register'
import getPortfolioOfUserReducer, { getPortfolioOfUserInitialState, GetPortfolioOfUserState } from './getPortfolioOfUser'
import updateUserReducer, { updateUserInitialState, UpdateUserState } from './updateUser'

import { combineReducers } from 'redux'

export interface User {
    [a: string]: any
    id: string
    cognitoId: string,
    email: string
    name: string,
    country: string,
    ccy: string,
    dateOfBirth: string,
    gender: string,
    language: string,
    lastname: string,
    mobileNo: string,
    nationality: string,
    nickname: string,
    residence: string,
    profilePicId: string,
    profilePicUrl: string,
    profilePicThumbnail: string,
    timeZone: string,
    bio: string,
    userType: number,
    isPublic: boolean,
    isTwoFactorEnabled?: boolean
}

export const acctInfoInitialState: AccountInfoInitialState = {
    authUser: authUserInitialState,
    registerUser: registerUserInitialState,
    getPortfolioOfUser: getPortfolioOfUserInitialState,
    updateUser: updateUserInitialState
}

export interface AccountInfoInitialState {
    authUser: AuthUserInfoState
    registerUser: RegisterUserState
    getPortfolioOfUser: GetPortfolioOfUserState,
    updateUser: UpdateUserState
}

const userReducer = combineReducers({
    authUser: authUserReducer,
    registerUser: registerReducer,
    getPortfolioOfUser: getPortfolioOfUserReducer,
    updateUser: updateUserReducer
})

export default userReducer