import * as _ from 'lodash'
import React, { useRef, useState } from 'react'
import { ReduxAppState } from '../../utils/types'
import { Dispatch, compose } from 'redux'
import { connect } from 'react-redux'
import SideNavContentHOC from '../../hoc/SidenavContent'
import FooterHOC from '../../hoc/Footer'
import * as yup from 'yup'
import ArtForm from '../../ReusableComponents/Form/ArtForm'
import ArtField, { ArtFieldsType } from '../../ReusableComponents/Form/ArtFields'
import { MyAlert, MyButton, MyLabel } from '../../ReusableComponents/ui-components'
import classNames from 'classnames'
import { createPresignedUrl } from '../../reducers/PresignedUrl/createPresignedUrl'
import axios, { AxiosResponse } from 'axios'
import { PresignedUrl } from '../../reducers/PresignedUrl'
import { addItem } from '../../reducers/Items/addItem'

const FormSchema = yup.object({
    title: yup.string().required('Art Name is required'),
    artist: yup.string().required('Artist is required'),
    year: yup.string().required('Year is required'),
    material: yup.string().required('Material is required'),
    baseMaterial: yup.string().required('Base Material is required'),
    height: yup.string().required('Height is required'),
    width: yup.string().required('Width is required'),
    depth: yup.string().required('Depth is required'),
    price: yup.string().required('Price is required'),
    otherImages: yup.mixed().notOneOf(['', false, undefined, null], 'Photos is required'),
})

export interface OwnProps { }
export interface DispatchProps {
    onCreatePresignedUrl: (fileName: string, type: string) => Promise<AxiosResponse>
    addItem: (data: any) => Promise<AxiosResponse>
 }
export interface StateProps { }
export type AddListingProps = OwnProps & DispatchProps & StateProps

export interface AddListingState { }

const AddListing = (props: AddListingProps) => {
    const _artWorkCover: any = useRef()
    const _authDetails: any = useRef()
    const _conditionReport: any = useRef()
    const _factSheet: any = useRef()

    const [isLoading, setLoading] = useState(false)
    const [artworkCover, setArtWorkCover] = useState(undefined)
    const [authenticationDetails, setAuthenticationDetails] = useState(undefined)
    const [conditionReport, setConditionReport] = useState(undefined)
    const [factSheet, setFactSheet] = useState(undefined)
    const [errorMessage, setErrorMessage] = useState('')
    const _onSubmit = (status: string) => async (data: any) => {
        try {
            setLoading(true)
            setErrorMessage('')
            const getResponse = (response: AxiosResponse) => _.get(response, 'data.message', {})
            let _otherImages: any = []
            if (_.isArray(data?.otherImages) && data?.otherImages?.length > 0) {
                const result = data?.otherImages?.map(async (img: any) => {
                    const presignedLogo: PresignedUrl = getResponse(await props.onCreatePresignedUrl(img?.fileName, 'other_images'))
                    const _imageBlob = await fetch(img?.file)
                    const imageBlob = await _imageBlob.blob()
                    await axios.put(presignedLogo.presigned_key, imageBlob, { headers: {
                        'Content-Type': 'image/png'
                    } })
                    return presignedLogo
                })
                _otherImages = await Promise.all(result)
            }

            const payload = {
                title: _.get(data, 'title', undefined),
                artist: _.get(data, 'artist', undefined),
                year: _.get(data, 'year', undefined),
                material: _.get(data, 'material', undefined),
                baseMaterial: _.get(data, 'baseMaterial', undefined),
                height: _.get(data, 'height', undefined),
                width: _.get(data, 'width', undefined),
                depth: _.get(data, 'depth', undefined),
                price: _.get(data, 'price', undefined),
                isForSale: _.get(data, 'isForSale', true),
                visibility: _.get(data, 'visibility', 'PUBLIC'),
                status,
                itemFiles: {
                    otherImages: _otherImages?.map?.(image => image.file_name)
                }
            }

            console.log('payload: ', payload)
            props.addItem(payload)
                .then(() => {
                    setErrorMessage('')
                    setLoading(false)
                })
                .catch((err) => {
                    console.error('onSubmit: ', err)
                    setErrorMessage(_.get(err, 'message', 'Something went wrong'))
                    setLoading(false)
                })
        } catch (error) {
            console.error('onSubmit: ', error)
            setLoading(false)
        }
    }
    const { Form, onSubmit, control, handleSubmit } = ArtForm({ formSchema: FormSchema, onSubmit: _onSubmit('ACTIVE'), otherOptions: { mode: 'onSubmit', criteriaMode: 'all' } })


    const handleFile = (file: any, setFile: any, key: string) => {
        const reader = new FileReader()
        reader.onload = (e: any) => {
            if (key === 'artWorkCover') {
                setArtWorkCover(e.target.result)
            }
            if (key === 'authenticationDetails') {
                setAuthenticationDetails(e.target.result)
            }
            if (key === 'conditionReport') {
                setConditionReport(e.target.result)
            }
            if (key === 'factSheet') {
                setFactSheet(e.target.result)
            }
            setFile({
                fileName: file.name,
                decodedFile: e?.target?.result?.split(',')[1],
                type: file.type
            })
        }
        reader.readAsDataURL(file)
    }

    const getBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = (error) => {
                reject(error)
                console.log('Error: ', error)
            }
        })
    }

    const multipleFileSelectedHandler = async (e: any, onChange: any) => {
        if (e.target.files) {
            const uploadedFiles = Array.from(e.target.files).map(async (image: any) => {
                // getBase64(image, (result: any) => {
                const result: any = await getBase64(image)
                return {
                    fileName: image?.name,
                    file: result,
                    decodedFile: result?.split?.(',')?.[1],
                    type: image?.type,
                    objectUrl: URL.createObjectURL(image)
                }
            })
            onChange(await Promise.all(uploadedFiles))
        }
    }

    return (
        <div id='AddListingComponent' className='addListing'>
            <Form id='AddListingForm' className='addListing__form' formSchema={FormSchema}>
                <MyAlert containerClassName='mb-3' variant='danger' onClose={() => setErrorMessage('')} isVisible={!!errorMessage}>{errorMessage ?? 'Something went wrong'}</MyAlert>
                <h3>Add Listing</h3>
                <div className='addListing__form--fields'>
                    <div className='addListing__form--column'>
                        <ArtField showError className='addListing__form--input' control={control} fieldstype={ArtFieldsType.ArtInput} id='title' name='title' placeholder={'Art Name'} />
                        <ArtField showError className='addListing__form--input' control={control} fieldstype={ArtFieldsType.ArtInput} id='artist' name='artist' placeholder={'Artist'} />
                        <ArtField showError className='addListing__form--input' control={control} fieldstype={ArtFieldsType.ArtInput} id='year' name='year' placeholder={'Year'} />
                        <ArtField showError className='addListing__form--input' control={control} fieldstype={ArtFieldsType.ArtInput} id='material' name='material' placeholder={'Material'} />
                        <ArtField showError className='addListing__form--input' control={control} fieldstype={ArtFieldsType.ArtInput} id='baseMaterial' name='baseMaterial' placeholder={'Base Material'} />
                    </div>
                    <div className='addListing__form--column'>
                        <ArtField showError className='addListing__form--input' type='number' control={control} fieldstype={ArtFieldsType.ArtInput} id='height' name='height' placeholder={'Height (CM)'} />
                        <ArtField showError className='addListing__form--input' type='number' control={control} fieldstype={ArtFieldsType.ArtInput} id='width' name='width' placeholder={'Width (CM)'} />
                        <ArtField showError className='addListing__form--input' type='number' control={control} fieldstype={ArtFieldsType.ArtInput} id='depth' name='depth' placeholder={'Depth (CM)'} />
                        <ArtField showError className='addListing__form--input' type='number' control={control} fieldstype={ArtFieldsType.ArtInput} id='price' name='price' placeholder={'Price'} />
                    </div>
                </div>
                <h3>Attachments</h3>
                <div className='addListing__form--fields'>
                    <ArtField control={control} id='otherImages' name='otherImages' fieldstype={ArtFieldsType.CustomField} renderField={(onChange: any, value: any, error?: any) => (
                        <div className='d-flex flex-column w-50'>
                            <MyLabel text={'Upload photos'} />
                            <div className={classNames('addListing__myUpload', !!error && 'border border-error')}>
                                <button type='button' className='addListing__myUpload--button' onClick={() => _artWorkCover?.current?.click()}>Upload <img loading='lazy' src='/Icons/clip-icon.png' /></button>
                                {artworkCover && <span className='border border-success ml-2 p-1 rounded-circle'><i className='fa fa-check text-success' /></span>}
                                <input type='file' multiple className='d-none' ref={_artWorkCover} onChange={(e) => multipleFileSelectedHandler(e, onChange)} />
                            </div>
                        </div>
                    )} />
                    <ArtField control={control} id='authenticationDetails' name='authenticationDetails' fieldstype={ArtFieldsType.CustomField} renderField={(onChange: any, value: any, error?: any) => (
                        <div className='d-flex flex-column w-50'>
                            <MyLabel text={'Authentication Details'} />
                            <div className={classNames('addListing__myUpload', !!error && 'border border-error')}>
                                <button disabled type='button' className='addListing__myUpload--button' onClick={() => _authDetails?.current?.click()}>Upload <img loading='lazy' src='/Icons/clip-icon.png' /></button>
                                {authenticationDetails && <span className='border border-success ml-2 p-1 rounded-circle'><i className='fa fa-check text-success' /></span>}
                                <input type='file' className='d-none' ref={_authDetails} onChange={(e) => handleFile(e?.target?.files![0], onChange, 'setCompanyLogo')} />
                            </div>
                        </div>
                    )} />
                </div>
                <div className='addListing__form--fields'>
                    <ArtField control={control} id='conditionReportId' name='conditionReportId' fieldstype={ArtFieldsType.CustomField} renderField={(onChange: any, value: any, error?: any) => (
                        <div className='d-flex flex-column w-50'>
                            <MyLabel text={'Condition Report'} />
                            <div className={classNames('addListing__myUpload', !!error && 'border border-error')}>
                                <button disabled type='button' className='addListing__myUpload--button' onClick={() => _conditionReport?.current?.click()}>Upload <img loading='lazy' src='/Icons/clip-icon.png' /></button>
                                {conditionReport && <span className='border border-success ml-2 p-1 rounded-circle'><i className='fa fa-check text-success' /></span>}
                                <input type='file' className='d-none' ref={_conditionReport} onChange={(e) => handleFile(e?.target?.files![0], onChange, 'setCompanyLogo')} />
                            </div>
                        </div>
                    )} />
                    <ArtField control={control} id='factSheetFileId' name='factSheetFileId' fieldstype={ArtFieldsType.CustomField} renderField={(onChange: any, value: any, error?: any) => (
                        <div className='d-flex flex-column w-50'>
                            <MyLabel text={'Fact Sheet'} />
                            <div className={classNames('addListing__myUpload', !!error && 'border border-error')}>
                                <button disabled type='button' className='addListing__myUpload--button' onClick={() => _factSheet?.current?.click()}>Upload <img loading='lazy' src='/Icons/clip-icon.png' /></button>
                                {factSheet && <span className='border border-success ml-2 p-1 rounded-circle'><i className='fa fa-check text-success' /></span>}
                                <input type='file' className='d-none' ref={_factSheet} onChange={(e) => handleFile(e?.target?.files![0], onChange, 'setCompanyLogo')} />
                            </div>
                        </div>
                    )} />
                </div>
                <div className="d-flex flex-row justify-content-end addListing__form--buttons">
                    {/* <button disabled={isLoading} onClick={handleSubmit(onSaveDraft)} type='submit' className='am-upload__button am-upload__button--link'>{isLoading ? <Spinner animation='border' size='sm' /> : <FormattedMessage id='AddArtListing.button.saveAsDraft' />}</button> */}
                    <MyButton disabled={isLoading} isLoading={isLoading} buttonType='secondary' type='button' onClick={() => props.router?.navigate('/listing')} className=''>Cancel</MyButton>
                    <MyButton disabled={isLoading} isLoading={isLoading} onClick={handleSubmit(_onSubmit('DRAFT'))} type='submit' className=''>Draft</MyButton>
                    <MyButton disabled={isLoading} isLoading={isLoading} onClick={onSubmit} type='submit' className=''>Publish</MyButton>
                </div>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: ReduxAppState) => {
    const _getItemInfo = state.api?.items?.getItemById
    return {}
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onCreatePresignedUrl: (fileName: string, type: string) => createPresignedUrl(dispatch, { filename: fileName, type }),
    addItem: (data: any) => addItem(dispatch, data)
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(SideNavContentHOC, FooterHOC)(AddListing as any)) as any
