import activeMenuReducer, { activeMenuInitialState, ActiveMenuState } from './changeMenu'

import { combineReducers } from 'redux'

export interface Menu {
    activeItem: string
}

export const menuInitialState: MenuInitialState = {
    activeMenuItem: activeMenuInitialState
}

export interface MenuInitialState {
    activeMenuItem: ActiveMenuState
}

const menuReducer = combineReducers({
    activeMenuItem: activeMenuReducer
})

export default menuReducer