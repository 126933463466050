import getAuditLogsReducer, { getAuditLogInitialState, GetAuditLogState } from './getAuditLogs'
import { combineReducers } from 'redux'

export interface Audit {
    id: string
    tranId: string
    tranType: string
    tranDate: string
    amt: number
    balance: number
    email: string
    userId: string
    operation: string
    status: number,
    tranStatus: number,
    [p: string]: any
}

export const auditInitialState: AuditInitialState = {
    getAuditLogs: getAuditLogInitialState
}

export interface AuditInitialState {
    getAuditLogs: GetAuditLogState
}

const auditReducer = combineReducers({
    getAuditLogs: getAuditLogsReducer
})

export default auditReducer

