export default {
    apiGateway: {
        REGION: 'ap-southeast-1',
        URL: 'https://y21oewpsg3.execute-api.ap-southeast-1.amazonaws.com/dev'
    },
    // cognito: {
    //     REGION: 'ap-southeast-1',
    //     USER_POOL_ID: 'ap-southeast-1_17xrE3mv1',
    //     APP_CLIENT_ID: '5i4gkl7piejqk9l419q60s592n',
    //     IDENTITY_POOL_ID: 'ap-southeast-1:62d5fbcf-a9ab-4c6a-a38d-28b1a359b54c'
    // },
    cognito: {
        REGION: 'ap-southeast-1',
        USER_POOL_ID: 'ap-southeast-1_kf6m7RNkC',
        APP_CLIENT_ID: '18ktib6vk1tpjjtsu2eog4dfgd',
        IDENTITY_POOL_ID: 'ap-southeast-1:bf97b6d4-62ad-4b09-8c95-dd604860c1de'
    },
    oauth: {
        DOMAIN: 'artmutualdev.auth.ap-southeast-1.amazoncognito.com',
        REDIRECT_SIGNIN: 'https://app.artshare.gallery/dashboard',
        REDIRECT_SIGNOUT: 'https://app.artshare.gallery',
        RESPONSE_TYPE: 'code',
        SCOPE: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin']
    }
}
