import React from 'react'
import classNames from 'classnames'
import { Spinner } from 'react-bootstrap'

export interface MyButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    label?: string | JSX.Element
    containerClassName?: string | undefined
    buttonType?: 'primary' | 'secondary' | 'transparent' | 'link'
    isLoading?: boolean
}

class MyButton extends React.Component<MyButtonProps, {}> {

    render() {
        let buttonType = this.props.buttonType ?? 'primary'
        if (this.props.disabled) {
            buttonType = 'secondary'
        }
        return (
            <div id='MyButtonComponent' className={classNames('myButton', this.props.containerClassName)}>
                <button {...this.props} type={this.props.type} defaultValue={this.props.defaultValue} className={classNames('myButton__btn', `myButton__btn--${buttonType}`, this.props.className)} onClick={this.props.onClick} >{this.props.isLoading ? <Spinner animation='border' size='sm' /> : this.props.children} </button>
            </div>
        )
    }
}

export default MyButton