import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import awsInstance, { methods } from '../../core/axios.config'

export const BEGIN_FORGOT_PWD_OTP_REQ = 'API/USER/BEGIN_FORGOT_PWD_OTP_REQ'
export const SUCCESS_FORGOT_PWD_OTP_REQ = 'API/USER/SUCCESS_FORGOT_PWD_OTP_REQ'
export const ERROR_FORGOT_PWD_OTP_REQ = 'API/USER/ERROR_FORGOT_PWD_OTP_REQ'
export const CLEAR_FORGOT_PWD_OTP_REQ = 'API/USER/CLEAR_FORGOT_PWD_OTP_REQ'

export const forgotPwdOtpReqInitialState = {
    response: [],
    loading: false
}

export interface ForgotPwdOTPReqState {
    response: any
    status?: number
    statusText?: string
    loading: boolean
    error?: any
}

export interface ForgotPwdOTPReqActions {
    type: string
    [item: string]: any
}

export const reducer = (newState: ForgotPwdOTPReqState = forgotPwdOtpReqInitialState, action: ForgotPwdOTPReqActions) => {
    switch (action.type) {
        case BEGIN_FORGOT_PWD_OTP_REQ:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_FORGOT_PWD_OTP_REQ:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: action.otpResp,
                status: action.status,
                error: ''
            })

        case ERROR_FORGOT_PWD_OTP_REQ:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status,
                error: action.message
            })
        case CLEAR_FORGOT_PWD_OTP_REQ:
            return forgotPwdOtpReqInitialState
        default:
            return newState
    }
}

export const beginForgotPwdOtpReq = () => ({
    type: BEGIN_FORGOT_PWD_OTP_REQ,
})

export const successForgotPwdOtpReq = (response: any) => ({
    type: SUCCESS_FORGOT_PWD_OTP_REQ,
    otpResp: response.data?.message?.response,
    data: response.data?.message?.otpResult,
    status: response.status
})

export const errorForgotPwdOtpReq = (error: any) => ({
    type: ERROR_FORGOT_PWD_OTP_REQ,
    message: error.code
})

export const clearForgotPwdOtpReq = () => ({
    type: CLEAR_FORGOT_PWD_OTP_REQ
})

export const forgotPwdOtpReq = async (dispatch: Dispatch, data: any) => {
    const config: AxiosRequestConfig = {
        method: methods.POST,
        url: `/otp`,
        data
    }
    dispatch(beginForgotPwdOtpReq())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            dispatch(successForgotPwdOtpReq(response))
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorForgotPwdOtpReq(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })
}

export default reducer