import React from 'react'
import { StyleProp }  from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { SafeAreaView } from 'react-native-safe-area-context'
import MediaQuery from 'react-responsive'
import { MediaQueryAllQueryable, MediaQueryMatchers } from 'react-responsive/src/types'


// We are using react-responsive Media query by default
export interface MediaQueryProps extends MediaQueryAllQueryable {
    component?: string | React.FC<any> | React.ClassType<any, any, any> | React.ComponentClass<any>
    query?: string
    style?: any
    className?: string
    device?: MediaQueryMatchers
    values?: Partial<MediaQueryMatchers>
    onBeforeChange?: (_matches: boolean) => void
    onChange?: (_matches: boolean) => void
    children: JSX.Element | JSX.Element[]
    isScroll?: boolean
    [a: string]: any
}

const ScreenComponent = (props: MediaQueryProps) => {
    const ScreenView = props.isScroll ? ScrollView : SafeAreaView
    return (
        <MediaQuery {...props}>
            <ScreenView  {...props} style={props.style}>
                {props.children}
            </ScreenView>
        </MediaQuery>
    )
}

export default ScreenComponent