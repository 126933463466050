import awsInstance, { methods } from '../../core/axios.config'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'
import { Wallet } from '.'

export const BEGIN_CREATE_WALLET = 'API/ITEM/BEGIN_CREATE_WALLET'
export const SUCCESS_CREATE_WALLET = 'API/ITEM/SUCCESS_CREATE_WALLET'
export const ERROR_CREATE_WALLET = 'API/ITEM/ERROR_CREATE_WALLET'

export const createWalletInitialState = {
    response: undefined,
    loading: false
}

export interface CreateWalletState {
    response: Wallet | undefined
    status?: number
    statusText?: string
    loading: boolean
    error?: any
}

export interface CreateWalletAction {
    type: string
    [item: string]: any
}

export const reducer = (newState: CreateWalletState = createWalletInitialState, action: CreateWalletAction) => {
    switch (action.type) {
        case BEGIN_CREATE_WALLET:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_CREATE_WALLET:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: 'success',
                status: action.status
            })

        case ERROR_CREATE_WALLET:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status
            })
        default:
            return newState
    }
}

export const beginCreateWallet = () => ({
    type: BEGIN_CREATE_WALLET,
})

export const successCreateWallet = (response: AxiosResponse) => ({
    type: SUCCESS_CREATE_WALLET,
    data: response.data.payload,
    status: response.status
})

export const errorCreateWallet = (error: AxiosError) => ({
    type: ERROR_CREATE_WALLET,
    status: error.response?.status,
})

export const createWallet = (dispatch: Dispatch, data: any) => {
    const config: AxiosRequestConfig = {
        method: methods.POST,
        url: `/wallet`,
        data
    }
    dispatch(beginCreateWallet())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            dispatch(successCreateWallet(response))
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorCreateWallet(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })

}

export default reducer