import awsInstance, { methods } from '../../core/axios.config'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'
import { Notification } from '.'

export const BEGIN_GET_NOTIF_WITH_LIMIT = 'API/ITEM/BEGIN_GET_NOTIF_WITH_LIMIT'
export const SUCCESS_GET_NOTIF_WITH_LIMIT = 'API/ITEM/SUCCESS_GET_NOTIF_WITH_LIMIT'
export const ERROR_GET_NOTIF_WITH_LIMIT = 'API/ITEM/ERROR_GET_NOTIF_WITH_LIMIT'
export const SET_NOTIFICATION_COUNT = 'API/NOTIFICATION/SET_NOTIFICATION_COUNT'
export const CLEAR_NOTIFICATION_COUNT = 'API/NOTIFICATION/CLEAR_NOTIFICATION_COUNT'

export const notificationWithLimitInitialState = {
    response: [],
    count: 0,
    loading: false
}

export interface NotificationWithLimitState {
    response: Notification[]
    count: number
    status?: number
    statusText?: string
    loading: boolean
    error?: any
}

export interface GetNotifWithLimitActions {
    type: string
    [item: string]: any
}

export const reducer = (newState: NotificationWithLimitState = notificationWithLimitInitialState, action: GetNotifWithLimitActions) => {
    switch (action.type) {
        case BEGIN_GET_NOTIF_WITH_LIMIT:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_GET_NOTIF_WITH_LIMIT:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: 'success',
                status: action.status
            })
        case ERROR_GET_NOTIF_WITH_LIMIT:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status
            })
        case SET_NOTIFICATION_COUNT:
            return Object.assign({}, newState, {
                loading: false,
                count: action.count
            })
        case CLEAR_NOTIFICATION_COUNT:
            return Object.assign({}, newState, {
                loading: false,
                count: 0
            })
        default:
            return newState
    }
}

export const setNotificationCount = (count: number) => ({
    type: SET_NOTIFICATION_COUNT,
    count
})

export const beginGetNotificationLimit = () => ({
    type: BEGIN_GET_NOTIF_WITH_LIMIT,
})

export const successGetNotificationLimit = (response: AxiosResponse) => ({
    type: SUCCESS_GET_NOTIF_WITH_LIMIT,
    status: response.status,
    data: response.data.message,
})

export const errorGetNotificationLimit = (error: AxiosError) => ({
    type: ERROR_GET_NOTIF_WITH_LIMIT,
    status: error.response?.status,
})

export const getNotificationWithLimit = async (dispatch: Dispatch, userId: string) => {
    const url = `/audit?field=userId&value=${userId}&limit=10&isNotify=true`
    const config: AxiosRequestConfig = {
        method: methods.GET,
        url
    }
    dispatch(beginGetNotificationLimit())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            if (response.status === 200) {
                dispatch(successGetNotificationLimit(response))
            }
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorGetNotificationLimit(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })
}

export default reducer