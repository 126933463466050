import { combineReducers } from 'redux'
import createPresignedUrlReducer, { createPresignedUrlInitialState, CreatePresignedUrlState } from './createPresignedUrl'
import getPresignedUrlReducer, { getPresignedUrlInitialState, GetPresignedUrlState } from './getPresignedUrl'


export interface PresignedUrl {
    presigned_key: string
    file_name: string
    [a: string]: any
}

export interface PresignedUrlState {
    createPresignUrl: CreatePresignedUrlState
    getPresignedUrl: GetPresignedUrlState
}

export const presignedUrlInitialState: PresignedUrlState = {
    createPresignUrl: createPresignedUrlInitialState,
    getPresignedUrl: getPresignedUrlInitialState
}

const presignedUrlReducer = combineReducers({
    createPresignedUrl: createPresignedUrlReducer,
    getPresignedUrl: getPresignedUrlReducer
})

export default presignedUrlReducer
