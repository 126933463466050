import React from 'react'
import Screen from './Screen'
import { useMediaQuery } from 'react-responsive'

export interface TabletProps {
    children: JSX.Element | JSX.Element[]
    style?: any
}

export const isTablet = () => useMediaQuery({ query: '(min-width: 768px) and (max-width: 991px)' })

const TabletComponent = (props: TabletProps) => {
    return (
        <Screen minWidth={768} maxWidth={991} style={props.style}>
            {props.children}
        </Screen>
    )
}


export default TabletComponent
