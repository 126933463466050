import getAllSysParamsReducer, { getAllSystemPropertiesInitialState, GetAllSystemPropertiesState } from './getAllSysParam'
import updateSystemParamReducer, { updateSystemParamInitialState, UpdateSystemParamState } from './updateSystemParam'

import { combineReducers } from 'redux'

export interface SystemProperties {
    value: any
    code: string
    status: number
    id: string
    [k: string]: any
}

export const systemPropertiesInitialState: SystemPropertiesIntialState = {
    getAllSysParams: getAllSystemPropertiesInitialState,
    updateSystemParam: updateSystemParamInitialState
}

export interface SystemPropertiesIntialState {
    getAllSysParams: GetAllSystemPropertiesState
    updateSystemParam: UpdateSystemParamState
}

const systemParamReducer = combineReducers({
    getAllSysParams: getAllSysParamsReducer,
    updateSystemParam: updateSystemParamReducer
})

export default systemParamReducer