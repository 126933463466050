import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const withRouter = (WrappedComponent: any) => {
    const Wrapper = (props: any) => {
        const location = useLocation()
        const navigate = useNavigate()
        const params = useParams()

        return (
            <WrappedComponent
                {...props}
                router={{ location, navigate, params }}
            />
        )
    }

    return Wrapper
}

