import resetPasswordReducer, { resetPwdInitialState, ResetPwdInitialState } from './ResetPassword'
import authUserReducer, { acctInfoInitialState, AccountInfoInitialState } from './User'
import itemReducer, { itemInitialState, ItemInitialState } from './Items'
import menuReducer, { menuInitialState, MenuInitialState } from './Menu'
import walletReducer, { walletInitialState, WalletInitialState } from './Wallet'
import auditReducer, { auditInitialState, AuditInitialState } from './Audit'
import modalReducer, { modalInitialState, ModalInitialState } from './Modal'
import notificationReducer, { notifInitialState, NotificationInitialState } from './Notification'
import forgotPasswordReducer, { forgotPwdInitialState, ForgotPwdInitialState } from './ForgotPassword'
import portfolioReducer, { PortfolioInitialState, portfolioInitialState } from './Portfolio'
import systemParamReducer, { SystemPropertiesIntialState, systemPropertiesInitialState } from './SystemProperty'
import presignedUrlReducer, { presignedUrlInitialState, PresignedUrlState } from './PresignedUrl'

import { combineReducers } from 'redux'

export const apiInitialState: ApiInitialState = {
    user: acctInfoInitialState,
    resetPwd: resetPwdInitialState,
    menu: menuInitialState,
    items: itemInitialState,
    wallet: walletInitialState,
    audit: auditInitialState,
    modal: modalInitialState,
    notification: notifInitialState,
    forgotPwd: forgotPwdInitialState,
    portfolio: portfolioInitialState,
    systemParam: systemPropertiesInitialState,
    presignedUrl: presignedUrlInitialState,
}

export interface ApiInitialState {
    user: AccountInfoInitialState,
    resetPwd: ResetPwdInitialState,
    menu: MenuInitialState,
    items: ItemInitialState,
    wallet: WalletInitialState,
    audit: AuditInitialState,
    modal: ModalInitialState,
    notification: NotificationInitialState,
    forgotPwd: ForgotPwdInitialState,
    portfolio: PortfolioInitialState,
    systemParam: SystemPropertiesIntialState,
    presignedUrl: PresignedUrlState
}

const reducer = combineReducers({
    user: authUserReducer,
    resetPwd: resetPasswordReducer,
    menu: menuReducer,
    items: itemReducer,
    wallet: walletReducer,
    audit: auditReducer,
    modal: modalReducer,
    notification: notificationReducer,
    forgotPwd: forgotPasswordReducer,
    portfolio: portfolioReducer,
    systemParam: systemParamReducer,
    presignedUrl: presignedUrlReducer,
})

export default reducer