import React, { Component } from 'react'
import { View } from 'react-native'


export interface DragAndDropProps {
  handleDrop: (file: any) => any
  style?: any
}

export interface DragAndDropState {
  drag: boolean
}


class DragAndDrop extends Component<DragAndDropProps, DragAndDropState> {
  dragCounter: number
  dropRef: any
  constructor(props: DragAndDropProps) {
    super(props)
    this.state = {
      drag: false
    }
    this.dragCounter = 0
    this.dropRef = React.createRef()
  }

  handleDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }
  handleDragIn = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true })
    }
  }
  handleDragOut = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.dragCounter === 0) {
      this.setState({ drag: false })
    }
  }
  handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ drag: false })
    if (e.dataTransfer.files && e.dataTransfer.files.length === 1) {
      this.props.handleDrop(e.dataTransfer.files[0])
      e.dataTransfer.clearData()
      this.dragCounter = 0
    }
  }
  componentDidMount() {
    const view: any = this.dropRef.current
    view.addEventListener('dragenter', this.handleDragIn)
    view.addEventListener('dragleave', this.handleDragOut)
    view.addEventListener('dragover', this.handleDrag)
    view.addEventListener('drop', this.handleDrop)
  }
  componentWillUnmount() {
    const view: any = this.dropRef.current
    view.removeEventListener('dragenter', this.handleDragIn)
    view.removeEventListener('dragleave', this.handleDragOut)
    view.removeEventListener('dragover', this.handleDrag)
    view.removeEventListener('drop', this.handleDrop)
  }
  render() {
    return (
      <View
        style={[{ position: 'relative' }, this.props.style]}
        ref={this.dropRef}
      >
        {this.state.drag &&
          <View
            style={{
              borderColor: 'grey',
              borderWidth: 7,
              borderStyle: 'dashed',
              backgroundColor: 'rgba(255,255,255,.8)',
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 9999
            }}
          >
            <View
              style={{
                position: 'absolute',
                top: '10%',
                right: 0,
                left: 0
              }}
            />
          </View>
        }
        {this.props.children}
      </View>
    )
  }
}
export default DragAndDrop
