import React, { useEffect, useRef, useState } from 'react'
import { splitFormProps, useField } from 'react-form'
import { useController } from 'react-hook-form'
import ArtInput from '../Input/ArtInput'
import ArtTextArea from '../Input/ArtTextArea'

import * as _ from 'lodash'
import { MyInput } from '../ui-components'

export enum ArtFieldsType {
    ArtInput,
    ArtTextArea,
    CustomField
}

export interface ArtFieldsProps {
    type?: string
    id: string
    fieldstype: any
    name: string
    control: any
    label?: string | JSX.Element
    onChange?: (val: any) => any
    showError?: boolean
    [k: string]: any
}

export const ArtField = (fieldProps: ArtFieldsProps) => {
    // const [field, fieldOptions, rest] = splitFormProps(fieldProps)
    const _input = useRef()
    // const {
    //     meta: { error, isTouched, isValidating },
    //     efa
    // } = useField(field, fieldOptions)

    const {
        control,
        defaultValue,
        name,
        required,
    } = fieldProps

    const [_defaultValue, setDefaultValue] = useState(defaultValue ?? '')

    useEffect(() => {
        if (defaultValue !== _defaultValue) {
            field.onChange(defaultValue ?? '')
        }
    }, [defaultValue])


    const { field, formState: { errors }, fieldState: { isTouched } } = useController({
        control,
        defaultValue: _defaultValue ?? '',
        name,
        rules: {
            required
        },
    })

    // console.log(useController({
    //     control,
    //     defaultValue,
    //     name,
    //     rules: {
    //         required
    //     },
    // }))

    let Field: any
    switch (fieldProps.fieldstype) {
        case ArtFieldsType.ArtInput:
            Field = MyInput
            break
        case ArtFieldsType.ArtTextArea:
            Field = ArtTextArea
            break
        case ArtFieldsType.CustomField:
            return fieldProps.renderField(field.onChange, field.value, errors[name]?.message)
        default:
            return <div />
    }

    const onChange = (e: any) => {
        if (fieldProps?.onChange) {
            fieldProps.onChange(e.target.value)
        }
        field.onChange(e.target.value)
    }

    return (
        <>
            <Field key={fieldProps.id} {...fieldProps} value={field.value} defaultValue={defaultValue ?? ''} onChange={onChange} isError={!!errors[name]?.message} errorMessage={errors[name]?.message} />
            {/* {!_.isEmpty(errors) ? (
                <em>{errors[name]?.message}</em>
            ) : null} */}
        </>
    )
}

export default ArtField