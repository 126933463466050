import React from 'react'

import { View, ScrollView, Text, TouchableOpacity } from 'react-native'
import { NavigationStackProp } from 'react-navigation-stack';
import { listingStyles as styles } from './style'
import { IconButton, Title } from 'react-native-paper'

import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import { FormattedMessage } from 'react-intl'
import { ReduxAppState } from '../../utils/types'
import SideNavContentHOC from '../../hoc/SidenavContent'
import HeaderContentHOC from '../../hoc/HeaderContent'
import FooterHOC from '../../hoc/Footer'
import { ResponsiveView, Alert } from '../../ReusableComponents'
import { RouteName, TabName } from '../../navigation/types'
import ActiveList from '../ActiveList/ActiveList'
import UpcomingList from '../UpcomingList/UpcomingList'
import { RESET_ADD_ITEM } from '../../reducers/Items/addItem'
import { DeleteItemState } from '../../reducers/Items/deleteItem'
import * as _ from 'lodash'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames';
export interface ComponentProps {
    navigation: NavigationStackProp<any>
    isRefreshing: boolean
}

export interface DispatchProps {
    resetAddItem: () => void
}

export interface StateProps {
    isLoading: boolean
    isError: boolean
    isAddItemSuccess: boolean
    deleteItem: DeleteItemState
    userType: string | number
    isSignedIn: boolean
}

export interface ListPageState {
    activeTab: string
    showSuccessDelete: boolean
}

export type ListPageProps = ComponentProps & DispatchProps & StateProps

const ListPageView = (props: any) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 987px)' })
    return (
        <View>
            {props.children}
        </View>
    )
}

class ListPage extends React.Component<ListPageProps, ListPageState> {
    constructor(props: ListPageProps) {
        super(props)
        this.state = {
            activeTab: TabName.ActiveListTab,
            showSuccessDelete: false
        }
    }


    onChangeActiveTab = (item: string) => {
        this.setState({
            activeTab: item
        })
    }

    componentDidUpdate(prevProps: ListPageProps) {
        if (!_.isEqual(this.props.deleteItem?.response, prevProps.deleteItem?.response) && this.props.deleteItem?.statusText === 'success') {
            this.setState({ showSuccessDelete: true })
        }
    }

    onAlertClose = () => {
        this.setState({ showSuccessDelete: false })
    }

    render() {
        return (
            <div id='ListComponent' className='list'>
                <div className='list__header'>
                    <h3 onClick={() => this.onChangeActiveTab(TabName.ActiveListTab)} className={classNames('list__header--tab', { ['list__header--active']: this.state.activeTab === TabName.ActiveListTab })}>ACTIVE LIST</h3>
                    <h3 onClick={() => this.onChangeActiveTab(TabName.UpcomingListTab)} className={classNames('list__header--tab', { ['list__header--active']: this.state.activeTab === TabName.UpcomingListTab })}>UPCOMING LIST</h3>
                    <div className='list__header--line' />
                </div>
                {this.state.activeTab === TabName.ActiveListTab ? (
                        <ActiveList isSignedIn={this.props.isSignedIn} navigation={this.props.navigation} />
                    ) : <UpcomingList navigation={this.props.navigation} />}
            </div>
        )
    }
}



const mapStateToProps = (state: ReduxAppState) => {
    return ({
        isAddItemSuccess: state.api?.items?.addItem?.statusText === 'success',
        deleteItem: state.api?.items?.deleteItem,
        userType: state.api?.user?.authUser?.response?.hasOwnProperty('custom:user_type') ? state.api?.user?.authUser?.response['custom:user_type'] : '1',
        isSignedIn: state.api?.user?.authUser?.isSignedIn,
    })
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetAddItem: () => dispatch({ type: RESET_ADD_ITEM })

})

export default connect(mapStateToProps, mapDispatchToProps)(compose(SideNavContentHOC, FooterHOC)(ListPage as any)) as any