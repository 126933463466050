import React from 'react'
import * as _ from 'lodash'
import NotificationDetail from './components/NotificationDetail'
import { View, Image, ScrollView, Text, TouchableOpacity } from 'react-native'
import { Avatar, Button, Title, Menu, Divider, IconButton, ActivityIndicator } from 'react-native-paper'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { FormattedMessage } from 'react-intl'
import { Screen } from '../ReusableComponents'
import { ReduxAppState } from '../utils/types'
import { connect } from 'react-redux'
import { User } from '../reducers/User'
import { RouteName } from '../navigation/types'
import { updateMenu } from '../reducers/Menu/changeMenu'
import { Dispatch } from 'redux'
import { retrieveUser } from '../reducers/User/user'
import Socket from '../websocket'
import { Notification } from '../reducers/Notification'
import { generateRandomId } from '../utils'
import { updateNotification } from '../reducers/Notification/updateNotification'
import { getNotifications } from '../reducers/Notification/getNotifications'
import { CLEAR_NOTIFICATION_COUNT, setNotificationCount, getNotificationWithLimit } from '../reducers/Notification/getNotificationWithLimit'
import { Route } from '@react-navigation/native'
import { HeaderStyles as styles } from './styles'


export interface HeaderContentProps {
    navigation: DrawerNavigationProp<any>
    route: Route<RouteName>
}

export interface DispatchProps {
    updateMenu: (menu: string) => any
    setNotifCount: (count: number) => any
    readNotification: (auditId: string) => any
    onClearNotifications: () => any
    getUserInfo: () => any
    getNotificationWithLimit: (userId: string) => any,
    getAllNotification: (userId: string) => any,
    onSetRoute: (route: Route<RouteName>) => void
}

export interface StateProps {
    currentUser?: User,
    notificationWithLimit?: Notification[],
    notificationCount: number,
    isLoadingNotif: boolean
}

export interface HeaderContentState {
    isVisible: boolean
    imageError: boolean
}

export type HeaderContentDetailsProps = HeaderContentProps & DispatchProps & StateProps

const ProfileDropdown = (props: any) => {
    return (
        <React.Fragment>
            <TouchableOpacity style={{ flexDirection: 'row' }} onPress={props.navigateToProfile}>
                {!props.profilePicThumbnail ? (
                    <Avatar.Text size={40} label={props.initials} style={styles.profileIcon} color={'#FFFFFF'} />
                ) : (
                    <Image
                        style={{ width: 40, height: 40, borderRadius: 80, borderColor: '#F5BF32', borderWidth: 1, marginRight: 10 }}
                        source={{ uri: !props.imageError ? props.profilePicThumbnail : props.profilePicUrl }}
                        onError={() => props.onImageError()}
                        resizeMode='contain' />
                )}

                <View style={{ alignSelf: 'center' }}>
                    <Title style={styles.greetingsText}>{props.name}</Title>
                </View>
                {/* <Icon name="caret-down" size={18} style={styles.caretDownIcon} /> */}
            </TouchableOpacity>
        </React.Fragment>
    )
}

const Badge = (props: any) => (
    <View style={[props.count > 0 ? styles.circle : styles.hiddenCircle]}>
        <Text style={styles.count}>{props.count >= 10 ? '9+' : props.count}</Text>
    </View>
)

const HeaderContentHOC = (WrappedComponent: any) => {
    class HeaderContent extends React.Component<HeaderContentDetailsProps, HeaderContentState> {
        constructor(props: HeaderContentDetailsProps) {
            super(props)
            this.state = {
                isVisible: false,
                imageError: false
            }
        }
        render() {
           return <>
            <div className='headerContent' id='HeaderContent'>
                <div className='d-flex flex-row gap-3'>
                    <div className='headerContent__column--line align-self-center' />
                    <img src='Logo/artshare-logo.png' />
                </div>
            </div>
            <WrappedComponent {...this.props} />
           </>
        }
    }

    const mapStateToProps = (state: ReduxAppState) => {
        const _authUserInfo = state.api?.user?.authUser
        const _notification = state.api?.notification?.getNotificationWithLimit
        return ({
            currentUser: _authUserInfo.response,
            notificationWithLimit: _notification.response,
            isLoadingNotif: _notification.loading,
            notificationCount: _notification.count,
        })
    }

    const mapDispatchToProps = (dispatch: Dispatch) => ({
        updateMenu: (activeItem: string) => dispatch(updateMenu(activeItem)),
        setNotifCount: (count: number) => dispatch(setNotificationCount(count)),
        readNotification: (auditId: string) => updateNotification(dispatch, auditId),
        onClearNotifications: () => dispatch({ type: CLEAR_NOTIFICATION_COUNT }),
        getUserInfo: () => retrieveUser(dispatch),
        getNotificationWithLimit: (userId: string) => getNotificationWithLimit(dispatch, userId),
        getAllNotification: (userId: string) => getNotifications(dispatch, userId),
        onSetRoute: (route: Route<RouteName>) => dispatch({ type: 'custom.route', value: route })
    })

    return connect(mapStateToProps, mapDispatchToProps)(HeaderContent)
}

export default HeaderContentHOC
