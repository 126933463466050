import React, { ReactNode } from 'react'
import classNames from 'classnames'
import { FormattedMessage, FormattedNumber } from 'react-intl'

export interface MyLabelProps extends React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
    text?: string | JSX.Element | JSX.Element[] | ReactNode
    containerClassName?: string | undefined
}

class MyLabel extends React.Component<MyLabelProps, {}> {

    isValidElement = () => React.isValidElement(this.props.text) && (this.props.text?.type !== FormattedMessage && this.props.text?.type !== FormattedNumber)

    render() {
        return (
            <div id='MyLabelComponent' className={classNames('myLabel', this.props.containerClassName)}>
                {this.props?.text ? this.isValidElement() ? this.props.text : <label {...this.props} className={classNames('myLabel__label', this.props.className)} >{this.props.text}</label> : null}
            </div>
        )
    }
}

export default MyLabel