import { StyleSheet } from "react-native"

export default StyleSheet.create({
    container: {
        padding: 10,
        alignSelf: 'center',
        width: '50%',
        marginBottom: 20
    },
    success: {
        backgroundColor: '#55AA55',
    },
    error: {
        backgroundColor: '#C75569',
    },
    warning: {
        backgroundColor: '#FEE270',
    },
    text: {
        color: '#ffff',
        textAlign: 'center',
        fontSize: 25,
        fontFamily: "'Montserrat', sans-serif"
    },
})