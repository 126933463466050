import React, { ReactChild, ReactChildren, ReactElement, ReactNode, useEffect, useRef } from 'react'
import { useForm, useFormState } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup"
import { View, TextInput, Platform, StyleProp, ViewStyle } from 'react-native'
import Field from './Field'
import * as _ from 'lodash'
import { Button, Text } from 'react-native'
import Submit from './Submit'
import styles from './styles'
import DropdownComponent from './Dropdown'
import Upload from './Upload';
import { SafeAreaView } from 'react-native-safe-area-context'
import CustomField from './CustomField'

export interface FormProps {
    children: any,
    formStyle?: StyleProp<ViewStyle> | undefined,
    formSchema: any,
    resetData?: any,
    mode?: 'onChange' | 'onBlur' | 'onSubmit' | 'onTouched' | 'all'
    onSubmit?: (data: any) => any
}

export const RequiredIcon = () => <Text style={styles.requiredIcon}>*</Text>

export const Form = (props: FormProps) => {
    const { formSchema, resetData } = props
    const formElement = useRef(null)
    const { control, handleSubmit, reset, setValue, getValues } = useForm<any>({ resolver: yupResolver(formSchema), mode: props.mode ?? 'all', criteriaMode: 'all' })

    useEffect(() => {
        if (!_.isEmpty(resetData)) {
            _.forIn(resetData, (value: any, key: string) => {
                setValue(key, value)
            })
        }
    }, [reset, resetData])

    const submit = (onPress: any, optionalFunc?: any) => {
        if (optionalFunc) {
            optionalFunc()
        }

        handleSubmit(onPress)
    }

    useEffect(() => {
        const view: any = formElement.current
        const listener = (event: any) => {
            if ((event.code === "Enter" || event.code === "NumpadEnter") && props.onSubmit) {
                handleSubmit(props.onSubmit)()
            }
        };
        view.addEventListener("keydown", listener);
        return () => {
            view.removeEventListener("keydown", listener);
        };
    }, []);

    const cloneChild = (child: any, childProps: any) => {
        if (!child) {
            return
        }
        if (typeof child === 'string') {
            return <Text>{child}</Text>
        }
        if (child?.type) {
            if (child.type === Submit || childProps?.type?.toLowerCase() === 'submit') {
                if (childProps.onPress) {
                    childProps.onPress = handleSubmit(childProps.onPress)
                }
                if (childProps.onClick) {
                    childProps.onClick = handleSubmit(childProps.onClick)
                }
            }
            if (childProps.type?.toLowerCase() === 'reset') {
                const oldOnClick = childProps.onClick
                childProps.onClick = () => {
                    oldOnClick()
                    // console.log(childProps)
                    reset()
                }
            }
            // if (child.type === Field || child.type === DropdownComponent || child.type === Upload || child.type === CustomField) {
            //     childProps.control = control
            //     childProps.defaultValue = childProps.defaultValue ?? ''
            // }
        }

        return React.cloneElement(child, {
            ...childProps,
            control,
            defaultValue: childProps?.defaultValue ?? ''
        })
    }

    const renderChildren: any = (children: ReactElement) => {
        if (Array.isArray(children)) {
            return React.Children.map(children, child => {
                if (child === undefined || !child?.props) {
                    return child
                }
                const childProps = { ...child.props }
                if (Boolean(child.props?.children) || child.type === View) {
                    childProps.children = renderChildren(child.props.children)
                }
                return cloneChild(child, childProps)
            })
        } else {
            return cloneChild(children, children?.props)
        }
    }

    const renderForm = () => {
        return <View style={props.formStyle} ref={formElement}>
            {renderChildren(props.children)}
        </View>
    }

    return renderForm()
}

export default Form