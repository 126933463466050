import React from 'react'
import { upcomingList as styles, modalCustomStyles } from './styles'
import { View, Text, Image, TouchableOpacity } from 'react-native'
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl'
import { RouteName } from '../../navigation/types'
import { StackNavigationProp } from '@react-navigation/stack'
import { ActivityIndicator, Button, Headline, Paragraph, Title } from 'react-native-paper'
import { ReduxAppState } from '../../utils/types'
import { compose, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { getItemList, GetItemListState } from '../../reducers/Items/getItems'
import { getItemInfo } from '../../reducers/Items/getItemInfo'
import { Items } from '../../reducers/Items'
import { AxiosPromise, AxiosResponse } from 'axios'
import { generateRandomId } from '../../utils'
import { addItem, RESET_ADD_ITEM } from '../../reducers/Items/addItem'
import { useMediaQuery } from 'react-responsive'
import { ResponsiveView, Screen } from '../../ReusableComponents'
import { deleteItem, DeleteItemState } from '../../reducers/Items/deleteItem'
import _ from 'lodash'
import Modal from 'react-modal'

export interface ComponentProps {
    navigation: StackNavigationProp<any>
}

export interface DispatchProps {
    getItemList: (isPosted: number) => any
    getItemInfo: (id: string) => AxiosPromise
    deleteItem: (id: string) => AxiosPromise
}

export interface StateProps {
    isAddItemSuccess: boolean
    userType: string | number
    upcomingList: GetItemListState
    deleteItemResponse: DeleteItemState
    validateLoading: boolean
}

export interface UpcomingListPageState {
    items: any
    isLoading: boolean
    selectedItemId: string
}

export type UpcomingListPageProps = ComponentProps & DispatchProps & StateProps

const TableView = (props: any) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 987px)' })
    return (
        <ResponsiveView width={isDesktop ? '75%' : '100%'}>
            {props.children}
        </ResponsiveView>
    )
}

class UpcomingListComponent extends React.Component<UpcomingListPageProps, UpcomingListPageState>{
    constructor(props: UpcomingListPageProps) {
        super(props)
        this.state = {
            items: [],
            isLoading: false,
            selectedItemId: '',
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true })
        this.props.getItemList(0)
    }

    componentDidUpdate(prevProps: UpcomingListPageProps) {
        if ((this.props.isAddItemSuccess !== prevProps.isAddItemSuccess && this.props.isAddItemSuccess) ||
            (!_.isEqual(this.props.deleteItemResponse?.response, prevProps.deleteItemResponse?.response))) {
            this.props.getItemList(0)
        }
    }

    navigateScreen = (isSoldOut: boolean) => {
        this.props.navigation.navigate(RouteName.ItemDetailPage, { isSoldOut })
    }

    getItemInfo = (item: Items, isSoldOut: boolean) => {
        this.props.getItemInfo(item.id)
        this.navigateScreen(isSoldOut)
    }

    getInitial = (name: string) => {
        if (!name) {
            return
        }
        const nameSplit = name.split(' ')
        return nameSplit.map(a => a[0]).reduce((prev, curr) => prev += curr).toUpperCase()
    }

    convertUTCToDate = (date: Date) => {
        const convertedDate = new Date(date)
        return (convertedDate.getMonth() + 1) + '/' + convertedDate.getDate() + '/' + convertedDate.getFullYear()
    }

    onConfirmDeleteItem = (item: string) => {
        this.setState({ selectedItemId: item })
    }

    onDeleteItem = () => {
        this.props.deleteItem(this.state.selectedItemId)
            .then(() => {
                this.setState({ selectedItemId: '' })
            })
    }

    render() {
        const { upcomingList } = this.props
        return (
            <React.Fragment>
                <Modal
                    isOpen={!!this.state.selectedItemId}
                    onRequestClose={() => this.setState({ selectedItemId: '' })}
                    style={modalCustomStyles}
                >
                    <Title><FormattedMessage id='ActiveList.msg.alert.delete.item' /></Title>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 20 }}>
                        <Button disabled={this.props.deleteItemResponse?.loading} loading={this.props.deleteItemResponse?.loading} onPress={() => this.onDeleteItem()} mode='contained' color='#F7931E' style={{ width: '120px' }} labelStyle={{ fontSize: 10, color: 'white' }} ><FormattedMessage id='ActiveList.msg.alert.yes' /></Button>
                        <Button onPress={() => this.setState({ selectedItemId: '' })} mode='outlined' color='black' labelStyle={{ fontSize: 10 }} style={{ width: '120px' }} ><FormattedMessage id='ActiveList.msg.alert.no' /></Button>
                    </View>
                </Modal>
                {upcomingList?.statusText === 'error' && <Text><FormattedMessage id='ActiveList.msg.somethingWentWrong' /></Text>}
                <Screen minWidth={769} style={{ width: '100%' }}>
                    <TableView>
                        <View key={generateRandomId()} style={styles.tableHeader}>
                            <View style={[styles.rowHeader, { width: '35%', maxWidth: 400 }]}>
                                <View style={[styles.rowTextContainer]}>
                                    <Headline style={[styles.normalText, styles.boldText, styles.upperCaseText, { fontSize: 16 }]}><FormattedMessage id='UpcomingList.msg.artwork' /></Headline>
                                </View>
                            </View>
                            <View style={styles.rowHeader}>
                                <View style={[styles.rowTextContainer]}>
                                    <Headline style={[styles.normalText, styles.boldText, styles.upperCaseText, { textAlign: 'center', fontSize: 16 }]}><FormattedMessage id='UpcomingList.msg.listValue' /></Headline>
                                </View>
                            </View>
                            <View style={styles.rowHeader}>
                                <View style={[styles.rowTextContainer]}>
                                    <Headline style={[styles.normalText, styles.boldText, styles.upperCaseText, { textAlign: 'center', fontSize: 16 }]}><FormattedMessage id='UpcomingList.msg.listingDate' /></Headline>
                                </View>
                            </View>
                            <View style={{ width: '25%' }}>

                            </View>
                        </View>
                    </TableView>

                    <TableView>
                        {upcomingList?.loading ? (
                            <ActivityIndicator animating={true} color='#AFCD37' size='large' />
                        ) : upcomingList?.response?.map((result: any) => {
                            const item = result?.data
                            return (
                                <TouchableOpacity onPress={() => this.getItemInfo(item, result?.isSoldOut)} key={generateRandomId()} style={styles.table}>
                                    <View style={[styles.row, { width: '35%', maxWidth: 400, height: 140 }]}>
                                        <View style={[styles.row, { width: '100%', height: '100%', position: 'relative' }]}>
                                            <View style={{ flexDirection: 'row' }}>
                                                <View style={{ height: 80, width: 80 }}>
                                                    <Image
                                                        source={{ uri: result?.imageUrl }}
                                                        style={{ height: '100%', width: '100%' }}
                                                    />
                                                    <Text style={[styles.normalText, styles.boldText, { textAlign: 'center', fontSize: 16, marginTop: 5 }]}>{this.getInitial(item?.artist ?? '')}</Text>
                                                </View>
                                                <View style={{ marginLeft: 8, width: 160 }}>
                                                    <Title numberOfLines={2} style={[styles.normalText, styles.boldText, { fontSize: 16, flexShrink: 1, lineHeight: 25 }]}>{item?.title}</Title>
                                                    <Text numberOfLines={2} style={[styles.normalText, styles.italicText, { fontSize: 14, flexShrink: 1 }]}>{`${item?.artist}, ${item?.year}`}</Text>
                                                </View>
                                            </View>
                                            {result?.isSoldOut && <View style={{ height: 100, width: 150, justifyContent: 'center', alignItems: 'center', position: 'absolute', right: 0 }}>
                                                <Image
                                                    source={require('../../assets/soldout.png')}
                                                    style={{ height: '100%', width: '100%', alignSelf: 'center' }}
                                                    resizeMode='stretch'
                                                />
                                            </View>}
                                        </View>
                                        <View style={{ borderRightWidth: 1, borderColor: '#9D9D9D' }} />
                                    </View>
                                    <View style={styles.row}>
                                        <View style={styles.rowTextContainer}>
                                            <Paragraph style={[styles.normalText, styles.boldText, { textAlign: 'center', fontSize: 14 }]}><FormattedNumber format='USD' value={Number(item?.listedValue)} /></Paragraph>
                                        </View>
                                        <View style={{ borderRightWidth: 1, borderColor: '#9D9D9D' }} />
                                    </View>
                                    <View style={styles.row}>
                                        <View style={styles.rowTextContainer}>
                                            <Paragraph style={[styles.normalText, styles.boldText, { textAlign: 'center', fontSize: 14 }]}>{this.convertUTCToDate(item?.listingDate)}</Paragraph>
                                        </View>
                                        <View style={{ borderRightWidth: 1, borderColor: '#9D9D9D' }} />
                                    </View>
                                    <View style={[styles.row, { width: '25%' }]}>
                                        <View style={{ alignSelf: 'center', flexDirection: 'row', flexWrap: 'wrap', width: '100%', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                            <View style={{ width: 120 }}>
                                                <Button onPress={() => this.getItemInfo(item, result?.isSoldOut)} mode='outlined' labelStyle={{ fontSize: 10, fontFamily: "'Montserrat', sans-serif" }} color='black' >DETAILS</Button>
                                            </View>
                                            {this.props.userType === '1' ? null : (
                                                <View style={{ width: 120 }}>
                                                    <Button onPress={() => this.onConfirmDeleteItem(item?.id)} mode='contained' color='#F7931E' labelStyle={{ color: 'white', fontSize: 10, fontFamily: "'Montserrat', sans-serif" }}>DELETE</Button>
                                                </View>
                                            )}
                                        </View>
                                    </View>
                                </TouchableOpacity>
                            )
                        })}
                    </TableView>
                </Screen>

                <Screen maxWidth={768}>
                    <ResponsiveView width={'90%'}>
                        {upcomingList?.loading ? (
                            <ActivityIndicator animating={true} color='#AFCD37' size='large' />
                        ) : upcomingList?.response?.map((result: any) => {
                            const item = result?.data
                            return (
                                <TouchableOpacity key={generateRandomId()} onPress={() => this.getItemInfo(item?.id, false)}>
                                    <View style={[styles.row, { width: '100%', justifyContent: 'space-between', flexWrap: 'wrap', borderWidth: 1, marginBottom: 10, borderColor: '#9D9D9D' }]}>
                                        <View style={{ flexDirection: 'row' }}>
                                            <View style={{ height: 80, width: 80 }}>
                                                <View style={{ height: '90%', width: '90%' }}>
                                                    <Image
                                                        source={{ uri: result?.imageUrl }}
                                                        style={{ height: '100%', width: '100%' }}
                                                    />
                                                </View>
                                                <View><Text style={[styles.normalText, styles.boldText, { textAlign: 'center', fontSize: 16, marginTop: 5 }]}>{this.getInitial(item?.artist ?? '')}</Text></View>
                                            </View>
                                            <View style={{ marginLeft: 8, width: 150 }}>
                                                <Title numberOfLines={2} style={[styles.boldText, { fontSize: 16, flexShrink: 1, lineHeight: 25 }]}>{item?.title}</Title>
                                                <Text numberOfLines={2} style={[styles.italicText, { fontSize: 16, flexShrink: 1, lineHeight: 20 }]}>{`${item?.artist}, ${item?.year}`}</Text>
                                            </View>
                                            {result?.isSoldOut && <View style={{ height: 100, width: 120, justifyContent: 'center', alignItems: 'center', position: 'absolute', right: 0 }}>
                                                <Image
                                                    source={require('../../assets/soldout.png')}
                                                    style={{ height: '100%', width: '100%', alignSelf: 'center' }}
                                                    resizeMode='stretch'
                                                />
                                            </View>}
                                        </View>
                                        <Screen minWidth={375}>
                                            <Text style={{ fontWeight: 'bold' }}><FormattedMessage id='ActiveList.msg.listValue' /></Text>
                                            <Text><FormattedNumber format={item?.currency ?? 'USD'} value={item?.listedValue} /></Text>
                                            <Text style={{ fontWeight: 'bold' }}><FormattedMessage id='ActiveList.msg.saleDate' /></Text>
                                            <Text>{this.convertUTCToDate(item?.listingDate)}</Text>
                                        </Screen>
                                    </View>
                                </TouchableOpacity>
                            )
                        })}
                    </ResponsiveView>
                </Screen>
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state: ReduxAppState) => {
    return ({
        upcomingList: state.api?.items?.getItemList,
        isAddItemSuccess: state.api?.items?.addItem?.statusText === 'success',
        deleteItemResponse: state.api?.items?.deleteItem,
        userType: state.api?.user?.authUser?.response?.hasOwnProperty('custom:user_type') ? state.api?.user?.authUser?.response['custom:user_type'] : '1'
    })
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getItemList: ((isPosted: number) => getItemList(dispatch, { isPosted, type: 'Active' })),
    getItemInfo: ((id: string) => getItemInfo(dispatch, id)),
    deleteItem: ((id: string) => deleteItem(dispatch, id))
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(injectIntl(UpcomingListComponent as any)))
