import Auth from '@aws-amplify/auth';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { Platform } from 'react-native';
import CONFIG from 'react-native-config'
import sessionStorage from 'redux-persist/es/storage/session';
import { setGuestToken, setRefreshToken, setToken } from '../reducers/User/user';

export const awsInstance = axios.create({
    baseURL: CONFIG.AWS_API_ENDPOINT ?? process.env.AWS_API_ENDPOINT
})

const unAuthenticatedRoutes = ['verifyemail', 'verifyotp', 'forgotpwd', 'login', 'signup']

awsInstance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
        let token = Platform.OS === 'web' ? await sessionStorage.getItem("token") : await AsyncStorage.getItem("token")
        if (!token && unAuthenticatedRoutes.includes(window.location.pathname.split('/')[1])) {
            token = Platform.OS === 'web' ? await sessionStorage.getItem("guest_token") : await AsyncStorage.getItem("guest_token")
        }

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    })

awsInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        return response
    },
    async (error: any) => {
        const originalRequest = error.config
        if (error?.response?.status === 401) {
            originalRequest._retry = true
            if (unAuthenticatedRoutes.includes(window.location.pathname.split('/')[1])) {
                const config: AxiosRequestConfig = {
                    method: methods.GET,
                    url: `openid/token`
                }
                return await awsInstance(config)
                    .then((res: any) => {
                        setGuestToken(res?.data.message)
                        originalRequest.headers['Authorization'] = `Bearer ${res?.data.message}`
                        return axios(originalRequest)
                    })
            } else {
                return await Auth.currentSession()
                    .then((session: any) => {
                        setToken(session.getIdToken().getJwtToken())
                        setRefreshToken(session.getRefreshToken().getToken())
                        originalRequest.headers['Authorization'] = `Bearer ${session.getIdToken().getJwtToken()}`
                        return axios(originalRequest)
                    })
            }
        }
        return Promise.reject(error)
    }
)



export enum methods {
    GET = 'get',
    POST = 'post',
    DELETE = 'delete',
    PUT = 'put',
    PATCH = 'patch'
}

export default awsInstance