import awsInstance, { methods } from '../../core/axios.config'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'
import { SystemProperties } from '.'
import * as _ from 'lodash'
const configData  = require('../../../static/default.json')

export const BEGIN_GET_ALL_SYSTEM_PROPERTIES = 'API/USER/BEGIN_GET_SYSTEM_PROPERTIES'
export const SUCCESS_GET_ALL_SYSTEM_PROPERTIES = 'API/USER/SUCCESS_GET_SYSTEM_PROPERTIES'
export const ERROR_GET_ALL_SYSTEM_PROPERTIES = 'API/USER/ERROR_GET_ALL_SYSTEM_PROPERTIES'

export const getAllSystemPropertiesInitialState = {
    response: [],
    loading: false,
}

export interface GetAllSystemPropertiesState {
    response: SystemProperties[]
    status?: number
    statusText?: string
    loading: boolean
    error?: any
}

export interface GetAllSystemProperties {
    type: string
    [item: string]: any
}

export const reducer = (newState: GetAllSystemPropertiesState = getAllSystemPropertiesInitialState, action: GetAllSystemProperties) => {
    switch (action.type) {
        case BEGIN_GET_ALL_SYSTEM_PROPERTIES:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_GET_ALL_SYSTEM_PROPERTIES:
            const systemFields = _.get(configData, 'systemFields')
            const _data = systemFields.map((field: any) => {
                const tempData = action.data.find((item: SystemProperties) => item.code === field.displayField)
                return {...field, ...tempData}
            })
            return Object.assign({}, newState, {
                response: _data,
                loading: false,
                statusText: 'success',
                status: action.status
            })

        case ERROR_GET_ALL_SYSTEM_PROPERTIES:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status
            })
        default:
            return newState
    }
}

export const beginGetAllSystemProperties = () => ({
    type: BEGIN_GET_ALL_SYSTEM_PROPERTIES,
})

export const successGetAllSystemProperties = (response: AxiosResponse) => ({
    type: SUCCESS_GET_ALL_SYSTEM_PROPERTIES,
    data: response.data.message,
    status: response.status
})

export const errorGetAllSystemProperties = (error: AxiosError) => ({
    type: ERROR_GET_ALL_SYSTEM_PROPERTIES,
    status: error.response?.status,
})

export const getAllSystemParams = (dispatch: Dispatch, params?: any) => {
    const config: AxiosRequestConfig = {
        method: methods.GET,
        url: `/sysparam`,
        params
    }
    dispatch(beginGetAllSystemProperties())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            dispatch(successGetAllSystemProperties(response))
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorGetAllSystemProperties(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })

}

export default reducer