import { RouteName } from "../../navigation/types"

export const UPDATE_ACTIVE_MENU = 'API/ACTIVE_MENU/UPDATE_ACTIVE_MENU'

export const activeMenuInitialState = {
    activeItem: RouteName.DashboardPage,
    loading: false
}

export interface ActiveMenuState {
    activeItem: string,
}

export interface ActiveMenuActions {
    type: string
    [item: string]: any
}

export const reducer = (newState: ActiveMenuState = activeMenuInitialState, action: ActiveMenuActions) => {
    switch (action.type) {
        case UPDATE_ACTIVE_MENU:
            return Object.assign({}, newState, {
                loading: false,
                activeItem: action.activeItem
            })
        default:
            return newState
    }
}

export const updateMenu = (activeItem: string) => ({
    type: UPDATE_ACTIVE_MENU,
    activeItem
})

export default reducer