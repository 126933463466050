import React from 'react'
import Screen from './Screen'
import { useMediaQuery } from 'react-responsive'

export interface MobileProps {
    children: JSX.Element | JSX.Element[]
    style?: any
}

export const isMobile = () => useMediaQuery({ query: '(max-width: 767)' })

const MobileComponent = (props: MobileProps) => {
    return (
        <Screen maxWidth={767} style={props.style}>
            {props.children}
        </Screen>
    )
}


export default MobileComponent
