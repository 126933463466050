import React from 'react'
import { Outlet, Navigate } from 'react-router'
import { compose } from 'redux'
import { withRouter } from './withRouter'
import { config } from 'node-config-ts'

export interface RoutesProps {
    router: any
    isAuth: boolean
    isPublic: boolean
}

export interface StateProps {
}

export interface ValidateRoutesState {
}

export type ValidateRoutesProps = RoutesProps & StateProps

class ValidateRoutes extends React.Component<ValidateRoutesProps, ValidateRoutesState> {
    constructor(props: ValidateRoutesProps) {
        super(props)
    }

    renderPublicRoutes = () => {
        return !this.props.isAuth ? <Outlet /> : <Navigate replace to={`/listing`} state={{ from: this.props.router.location }} />
    }

    renderPrivateRoutes = () => {
        return this.props.isAuth ? <Outlet /> : <Navigate replace to={`/login`} state={{ from: this.props.router.location }} />
    }

    render() {
        return this.props.isPublic ? this.renderPublicRoutes() : this.renderPrivateRoutes()
    }

}

export default withRouter(compose(ValidateRoutes as any))