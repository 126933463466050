import React, { useState } from 'react'
import { View, StyleSheet, Text, TextInput as Input, Pressable, Image, TouchableOpacity, Platform, ScrollView } from 'react-native'
import { ActivityIndicator, HelperText, IconButton, Paragraph, TextInput, Title } from 'react-native-paper'
import { modalCustomStyles, termStyles as styles } from './styles'
import Modal from 'react-modal'
import { Form, ResponsiveView, Screen } from '../../ReusableComponents'
import { ReduxAppState } from '../../utils/types'
import { compose, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { NavigationStackProp } from 'react-navigation-stack'
import * as _ from 'lodash'
import { FormattedMessage } from 'react-intl'

export interface ComponentProps {
  navigation: NavigationStackProp<any>
  showModal: boolean
  closeModal: () => any
}

export interface StateProps {
}

export interface DispatchProps {
}

export interface TermsModalState {
}

export type TermsModalProps = ComponentProps & StateProps & DispatchProps

Modal.setAppElement('#react-root');

class DislaimerModalComponent extends React.Component<TermsModalProps, TermsModalState> {
  constructor(props: TermsModalProps) {
    super(props)
  }

  onClose = () => {
    this.props.closeModal()
  }

  renderDisclaimer = () => {
    return (
      <ScrollView style={{ marginBottom: 10 }}>
        <View style={styles.paragraphContainer}>
          <Title
            style={{
              textAlign: 'center',
              fontSize: 24,
              fontWeight: 'bold',
              margin: 10,
              fontFamily: "'Montserrat', sans-serif",
              textTransform: 'uppercase'
            }}>
            <FormattedMessage id="Disclaimer.msg.term.title" />
          </Title>
          <Paragraph style={styles.paragraphText}>
            <FormattedMessage id="Disclaimer.msg.term.prgrph1" />
          </Paragraph>
        </View>
        <View style={styles.paragraphContainer}>
          <Title style={styles.paragraphTitle}>
            <FormattedMessage id="Disclaimer.msg.termI.title" />
          </Title>
          <Paragraph style={styles.text}>
            <FormattedMessage id="Disclaimer.msg.termI.prgrph1" />
          </Paragraph>
          <Paragraph style={styles.text}>
            <FormattedMessage id="Disclaimer.msg.termI.prgrph2" />
          </Paragraph>
        </View>
        <View style={styles.paragraphContainer}>
          <Title style={styles.paragraphTitle}>
            <FormattedMessage id="Disclaimer.msg.termII.title" />
          </Title>
          <Paragraph style={styles.text}>
            <FormattedMessage id="Disclaimer.msg.termII.prgrph1" />
          </Paragraph>
          <Paragraph style={styles.text}>
            <FormattedMessage id="Disclaimer.msg.termII.prgrph2" />
          </Paragraph>
        </View>
        <View style={styles.paragraphContainer}>
          <Title style={styles.paragraphTitle}>
            <FormattedMessage id="Disclaimer.msg.termIII.title" />
          </Title>
          <Paragraph style={styles.text}>
            <FormattedMessage id="Disclaimer.msg.termIII.prgrph1" />
          </Paragraph>
          <Paragraph style={styles.text}>
            <FormattedMessage id="Disclaimer.msg.termIII.prgrph2" />
          </Paragraph>
        </View>
        <View style={styles.paragraphContainer}>
          <Title style={styles.paragraphTitle}>
            <FormattedMessage id="Disclaimer.msg.termIV.title" />
          </Title>
          <Paragraph style={styles.text}>
            <FormattedMessage id="Disclaimer.msg.termIV.prgrph1" />
          </Paragraph>
          <Paragraph style={styles.text}>
            <FormattedMessage id="Disclaimer.msg.termIV.prgrph2" />
          </Paragraph>
        </View>
        <View style={styles.paragraphContainer}>
          <Title style={styles.paragraphTitle}>
            <FormattedMessage id="Disclaimer.msg.termV.title" />
          </Title>
          <Paragraph style={styles.text}>
            <FormattedMessage id="Disclaimer.msg.termV.prgrph1" />
          </Paragraph>
          <Paragraph style={styles.text}>
            <FormattedMessage id="Disclaimer.msg.termV.prgrph2" />
          </Paragraph>
        </View>
        <View style={styles.paragraphContainer}>
          <Title style={styles.paragraphTitle}>
            <FormattedMessage id="Disclaimer.msg.termVI.title" />
          </Title>
          <Paragraph style={styles.text}>
            <FormattedMessage id="Disclaimer.msg.termVI.prgrph1" />
          </Paragraph>
          <Paragraph style={styles.text}>
            <FormattedMessage id="Disclaimer.msg.termVI.prgrph2" />
          </Paragraph>
        </View>
        <View style={styles.paragraphContainer}>
          <Title style={styles.paragraphTitle}>
            <FormattedMessage id="Disclaimer.msg.termVII.title" />
          </Title>
          <Paragraph style={styles.text}>
            <FormattedMessage id="Disclaimer.msg.termVII.prgrph1" />
          </Paragraph>
          <Paragraph style={styles.text}>
            <FormattedMessage id="Disclaimer.msg.termVII.prgrph2" />
          </Paragraph>
        </View>
        <View style={styles.paragraphContainer}>
          <Title style={styles.paragraphTitle}>
            <FormattedMessage id="Disclaimer.msg.termVIII.title" />
          </Title>
          <Paragraph style={styles.text}>
            <FormattedMessage id="Disclaimer.msg.termVIII.prgrph1" />
          </Paragraph>
          <Paragraph style={styles.text}>
            <FormattedMessage id="Disclaimer.msg.termVIII.prgrph2" />
          </Paragraph>
        </View>
        <View style={styles.paragraphContainer}>
          <Title style={styles.paragraphTitle}>
            <FormattedMessage id="Disclaimer.msg.termIX.title" />
          </Title>
          <Paragraph style={styles.text}>
            <FormattedMessage id="Disclaimer.msg.termIX.prgrph1" />
          </Paragraph>
          <Paragraph style={styles.text}>
            <FormattedMessage id="Disclaimer.msg.termIX.prgrph2" />
          </Paragraph>
        </View>
        <View style={styles.paragraphContainer}>
          <Title style={styles.paragraphTitle}>
            <FormattedMessage id="Disclaimer.msg.termX.title" />
          </Title>
          <Paragraph style={styles.text}>
            <FormattedMessage id="Disclaimer.msg.termX.prgrph1" />
          </Paragraph>
          <Paragraph style={styles.text}>
            <FormattedMessage id="Disclaimer.msg.termX.prgrph2" />
          </Paragraph>
        </View>
        <View style={styles.paragraphContainer}>
          <Paragraph style={[styles.text, { marginLeft: 0 }]}><FormattedMessage id='Disclaimer.msg.termXI.contactInfo' /></Paragraph>
        </View>
      </ScrollView>
    )
  }

  render() {
    const { showModal } = this.props
    return (
      <Modal isOpen={showModal} onRequestClose={this.onClose} style={modalCustomStyles}>
        <View style={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}>
          <IconButton icon="close" size={25} onPress={this.onClose} />
        </View>
        {/* Terms of Use */}
        <Screen minWidth={450}>
          <ResponsiveView height={'90%'} width={'55%'}>
            <View
              style={{
                flexDirection: 'row',
                alignSelf: 'center',
                marginTop: 10
              }}>
              <Image
                style={{ width: 200, height: 100, marginHorizontal: 10 }}
                source={require('../../assets/artshare-new-logo-v2.png')}
                resizeMode="contain"
              />
            </View>
            {this.renderDisclaimer()}
          </ResponsiveView>
        </Screen>
        <Screen maxWidth={449}>
          <ResponsiveView height={'95%'} width={'95%'}>
            <View
              style={{
                flexDirection: 'row',
                alignSelf: 'center',
                marginVertical: 20
              }}>
              <Image
                style={{ width: 200, height: 100, marginHorizontal: 10 }}
                source={require('../../assets/artshare-new-logo-v2.png')}
                resizeMode="contain"
              />
            </View>
            {this.renderDisclaimer()}
          </ResponsiveView>
        </Screen>
      </Modal>
    )
  }
}

export default compose(DislaimerModalComponent as any)