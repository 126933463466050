import React from 'react'
import * as _ from 'lodash'
import { View, Image, ScrollView, ToastAndroid, TouchableOpacity, StyleSheet, Text, Dimensions } from 'react-native'

import { Avatar, Button, IconButton, Title } from 'react-native-paper';

import { SideNavStyles as styles } from './styles'
import { DrawerNavigationProp } from '@react-navigation/drawer';
import Icon from 'react-native-vector-icons/Ionicons';

import artColors from '../utils/colors'
import { FormattedMessage } from 'react-intl';
import { updateMenu } from '../reducers/Menu/changeMenu'
import { logOutUser } from '../reducers/User/user'
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ReduxAppState } from '../utils/types';
import { RouteName } from '../navigation/types';
import MenuItem from './components/MenuItem'
import { Screen } from '../ReusableComponents';
import classNames from 'classnames';
import { withRouter } from '../navigation/withRouter';
import { MyModal } from '../ReusableComponents/ui-components';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

export interface SideNavContentProps {
    navigation: DrawerNavigationProp<any>,
    activeItem: string,
    router: any,
    isSignedIn: boolean
}

export interface DispatchProps {
    updateMenu: (menu: string) => any,
    logOutUser: () => any
    setRoute: (value: string) => void
    setGlobalLoginMessage: (value: any) => void
}

export interface SideNavContentState {
    dimensions: Dimension
    route: string
}

export interface Dimension {
    window: any
    screen: any
}

export enum SideRoutes {
    PORTFOLIO = 'portfolio',
    LISTING = 'listing',
    WALLET = 'wallet',
    SETTINGS = 'settings'
}

export type SideNavContentDetailsProps = SideNavContentProps & DispatchProps

const SideNavContentHOC = (WrappedComponent: any) => {
    class SideNavContent extends React.Component<SideNavContentDetailsProps, SideNavContentState> {
        dimensionsSubscription: any;
        constructor(props: SideNavContentDetailsProps) {
            super(props);
            this.state = {
                dimensions: {
                    window,
                    screen
                },
                route: 'portfolio'
            };
        }

        componentDidMount(): void {
            if (!this.props.activeItem) {
                const path = _.last(_.get(window, 'location.pathname', '/').split('/')) ?? ''
                this.props.setRoute(path)
            }
        }

        onChangeRoute = (route: string) => {
            if (this.props.isSignedIn) {
                this.props.setRoute(route)
                this.props.router?.navigate(`/${route}`)
            } else {
                this.props.setGlobalLoginMessage(true)
            }
        }

        render() {
            const { navigation, activeItem } = this.props
            return <div id='SideNavContent' className='sidenavContent'>
                {/* <div> */}
                    {/* <div className='sidenavContent__content opacity-0' /> */}
                    <div className='sidenavContent__content'>
                        <div className='sidenavContent__content--logo mt-5'><img src='/Logo/artshare-logo-white.png' /></div>
                        <ul className='sidenavContent__content--menu mt-5'>
                            <li onClick={() => this.onChangeRoute(SideRoutes.PORTFOLIO)} className={classNames('my-cursor', {['sidenavContent__content--active']: this.props.activeItem === SideRoutes.PORTFOLIO })}><img src='/Icons/portfolio.png' /><span>Portfolio</span></li>
                            <li onClick={() => this.onChangeRoute(SideRoutes.LISTING)} className={classNames('my-cursor', {['sidenavContent__content--active']: this.props.activeItem === SideRoutes.LISTING })}><img src='/Icons/listing.png' /><span>Listing</span></li>
                            <li onClick={() => this.onChangeRoute(SideRoutes.WALLET)} className={classNames('my-cursor', {['sidenavContent__content--active']: this.props.activeItem === SideRoutes.WALLET })}><img src='/Icons/wallet.png' /><span>Wallet</span></li>
                            <li onClick={() => this.onChangeRoute(SideRoutes.SETTINGS)} className={classNames('my-cursor', {['sidenavContent__content--active']: this.props.activeItem === SideRoutes.SETTINGS })}><img src='/Icons/settings.png' /><span>Settings</span></li>
                        </ul>
                    </div>
                {/* </div> */}
                <div className='sidenavContent__page'>
                    {/* <MyModal isVisible>
                        <h1>Test</h1>
                    </MyModal> */}
                    <WrappedComponent {...this.props} />
                </div>
            </div>
        }
    }

    const mapStateToProps = (state: ReduxAppState) => {
        const _menu = state.api?.menu?.activeMenuItem
        return ({
            activeItem: _menu.activeItem,
            isSignedIn: state.api?.user?.authUser?.isSignedIn,
        })
    }

    const mapDispatchToProps = (dispatch: Dispatch) => ({
        updateMenu: (activeItem: string) => dispatch(updateMenu(activeItem)),
        logOutUser: () => logOutUser(dispatch),
        setRoute: (value: string) => dispatch(updateMenu(value)),
        setGlobalLoginMessage: (value: boolean) => dispatch({ type: 'custom.isOpenLoginMessage', value }),
    })

    return withRouter(connect(mapStateToProps, mapDispatchToProps)(SideNavContent))

}

export default SideNavContentHOC
